import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from "./locales/en.json" // 英语资源
import cn from "./locales/cn.json" // 中文资源
import { DSLanguageCode, DSLanguageNames } from "./constants"

const i18nPromise = i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        cn: { translation: cn },
    },
    lng: DSLanguageNames[DSLanguageCode.English], // 默认语言
    fallbackLng: DSLanguageNames[DSLanguageCode.English],
    interpolation: {
        escapeValue: false,
    },
})

export { i18nPromise }
export default i18n
