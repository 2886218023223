import React, { useState, useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useUser } from "../shared/user-context"
import i18n from "../shared/i18n"
import { useTranslation } from "react-i18next"
import { getAPIUrl } from "../shared/function"
import PopupMenu, { MenuItem } from "../components/menu/popup-menu"
import { Tabs, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { ProductTabList, ProductTab } from "../components/product-tab"
import ProductCard, { ProductPermissionDetail } from "../components/product-card"
import { sprintf } from "sprintf-js"
import { DSProduct, DSPermission } from "../shared/business"
import DSPageFooter from "../components/ds-page-footer"
import { hostProvider } from "../shared/config"
import { DSCountryCode, DSLanguageCode, DSLanguageNames, DiscordChannelInvitations } from "../shared/constants"

interface BuyPageProps {}

const BuyPage: React.FC<BuyPageProps> = () => {
    const { userID, userAvatar, userToken, language, fetchWithChecks, logout } = useUser()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [tabIndex, setTabIndex] = useState<number>(0)
    const [products, setProducts] = useState<DSProduct[]>([])

    const [popupMenuVisible, setPopupMenuVisible] = useState<boolean>(false)
    const menuItems: MenuItem[] = [
        { type: "quotas" },
        { type: "divider" },
        {
            type: "item",
            icon: "faCircleQuestion",
            text: t("optionMenuHelp"),
            onClick: () => {
                if (hostProvider === "aliyun") {
                    window.open(DiscordChannelInvitations.HelpChinese, "_blank")
                } else {
                    window.open(DiscordChannelInvitations.HelpEnglish, "_blank")
                }
            },
        },
        {
            type: "item",
            icon: "faMask",
            text: t("optionMenuJoinDiscord"),
            onClick: () => {
                if (hostProvider === "aliyun") {
                    window.open(DiscordChannelInvitations.ChatChinese, "_blank")
                } else {
                    window.open(DiscordChannelInvitations.ChatEnglish, "_blank")
                }
            },
        },
        { type: "divider" },
        {
            type: "item",
            icon: "faReply",
            text: t("optionMenuBack"),
            onClick: () => {
                navigate("/app")
            },
        },
        { type: "item", icon: "faRightFromBracket", text: t("optionMenuLogout"), onClick: logout },
    ]

    // 计算 ProductCard 的布局结构
    const getProductsRows = (category: string) => {
        const filteredProducts = products.filter((product) => product.category === category)
        const productCount = filteredProducts.length

        let rowCounts: number[]
        if (productCount <= 4) {
            rowCounts = [productCount]
        } else if (productCount === 5) {
            rowCounts = [3, 2]
        } else if (productCount === 6) {
            rowCounts = [3, 3]
        } else if (productCount === 7) {
            rowCounts = [4, 3]
        } else {
            rowCounts = [4, 4]
        }

        let productIndex = 0
        const productRows: DSProduct[][] = []
        for (const rowCount of rowCounts) {
            const productRow: DSProduct[] = []
            for (let i = 0; i < rowCount; i++) {
                productRow.push(filteredProducts[productIndex])
                productIndex++
            }
            productRows.push(productRow)
        }

        return productRows
    }

    const getCurrencySymbol = (currencyCode: string) => {
        switch (currencyCode) {
            case "USD":
                return "$"
            case "CNY":
                return "￥"
            default:
                return "$"
        }
    }

    const getPermissionDetails = (permission: DSPermission): ProductPermissionDetail => {
        switch (permission.type) {
            case "upload_page":
                if (permission.is_permanent) {
                    return {
                        content: sprintf(t("permanentUploadPagePermissionSummary"), permission.limit_amount),
                        description: t("permanentUploadPagePermissionDescription"),
                    }
                } else {
                    if (permission.is_recurring) {
                        return {
                            content: sprintf(
                                t("recurringUploadPagePermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("recurringUploadPagePermissionDescription"),
                                permission.valid_duration,
                                permission.valid_duration
                            ),
                        }
                    } else {
                        return {
                            content: sprintf(
                                t("norecurringUploadPagePermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("norecurringUploadPagePermissionDescription"),
                                permission.valid_duration
                            ),
                        }
                    }
                }
            case "question":
                if (permission.is_permanent) {
                    return {
                        content: sprintf(t("permanentQuestionPermissionSummary"), permission.limit_amount),
                        description: t("permanentQuestionPermissionDescription"),
                    }
                } else {
                    if (permission.is_recurring) {
                        return {
                            content: sprintf(
                                t("recurringQuestionPermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("recurringQuestionPermissionDescription"),
                                permission.valid_duration,
                                permission.valid_duration
                            ),
                        }
                    } else {
                        return {
                            content: sprintf(
                                t("norecurringQuestionPermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("norecurringQuestionPermissionDescription"),
                                permission.valid_duration
                            ),
                        }
                    }
                }
            case "full_use_page":
                if (permission.is_permanent) {
                    return {
                        content: sprintf(t("permanentFullUsePagePermissionSummary"), permission.limit_amount),
                        description: t("permanentFullUsePagePermissionDescription"),
                    }
                } else {
                    if (permission.is_recurring) {
                        return {
                            content: sprintf(
                                t("recurringFullUsePagePermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("recurringFullUsePagePermissionDescription"),
                                permission.valid_duration,
                                permission.valid_duration
                            ),
                        }
                    } else {
                        return {
                            content: sprintf(
                                t("norecurringFullUsePagePermissionSummary"),
                                permission.limit_amount,
                                permission.valid_duration
                            ),
                            description: sprintf(
                                t("norecurringFullUsePagePermissionDescription"),
                                permission.valid_duration
                            ),
                        }
                    }
                }
            default:
                return {
                    content: "",
                    description: "",
                }
        }
    }

    // 调起支付动作
    const doBuyAction = async (productID: number) => {
        const url = getAPIUrl(`/api/business/order`)
        const headers: HeadersInit = userToken ? { Authorization: userToken, "Content-Type": "application/json" } : {}

        let countryCode = DSCountryCode.USA
        let paymentGateway = "stripe"
        if (hostProvider === "aliyun") {
            countryCode = DSCountryCode.China
            paymentGateway = "alipay"
        }

        try {
            const response = await fetchWithChecks(url, true, false, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    product_id: productID,
                    country: countryCode,
                    payment: paymentGateway,
                }),
            })

            if (!response.ok) {
                throw new Error("Failed to create order.")
            }

            const responseData = await response.json()

            // 跳转到支付页面
            window.location.href = responseData.pay_url
        } catch (error) {
            console.log(error)
        }
    }

    const handleTabChange = (index: number) => {
        setTabIndex(index)
    }

    const handleMenuClick = () => {
        setPopupMenuVisible(!popupMenuVisible)
    }

    useEffect(() => {
        switch (language) {
            case DSLanguageNames[DSLanguageCode.SimplifiedChinese]:
                i18n.changeLanguage("cn")
                break
            default:
                i18n.changeLanguage("en")
                break
        }
    }, [language])

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                let countryCode = DSCountryCode.USA
                if (hostProvider === "aliyun") {
                    countryCode = DSCountryCode.China
                }
                const url = getAPIUrl(`/api/business/product?country=${countryCode}`)
                const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                const response = await fetchWithChecks(url, true, false, { method: "GET", headers: headers })
                const data = await response.json()
                if (data && Array.isArray(data)) {
                    setProducts(data)
                }
            } catch (error) {
                // console.log("Heartbeat failed.")
            }
        }

        fetchProducts()
    }, [userToken, language, fetchWithChecks])

    return userID ? (
        <BuyPageContainer>
            <BuyPageHeader>
                <BuyPageHeaderLogo>
                    <img src={"/logo-landscape-dark.png"} alt="DocuSpeed" />
                </BuyPageHeaderLogo>
                <div>
                    <BuyPageHeaderUserAvatar
                        src={userAvatar || "/user.png"}
                        alt="User Avatar"
                        onClick={handleMenuClick}
                    />
                    <PopupMenu
                        menuItems={menuItems}
                        direction="down"
                        position={{ horizontal: "right", vertical: "top" }}
                        width={300}
                        visible={popupMenuVisible}
                        onClose={() => setPopupMenuVisible(false)}
                    />
                </div>
            </BuyPageHeader>
            <BuyPageBody>
                <h1>{t("buyPageTitle")}</h1>
                <Tabs selectedIndex={tabIndex} onSelect={handleTabChange}>
                    <ProductTabList>
                        <ProductTab animationIndex={0}>{t("subscriptions")}</ProductTab>
                        <ProductTab animationIndex={1}>{t("extraPackages")}</ProductTab>
                    </ProductTabList>
                    <TabPanel>
                        {getProductsRows("subscription").map((productRow, rowIndex) => (
                            <ProductCardsWrapper key={rowIndex}>
                                {productRow.map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        title={product.name}
                                        price={`${getCurrencySymbol(product.currency)}${product.discount_price}`}
                                        originalPrice={
                                            product.original_price > 0
                                                ? `${getCurrencySymbol(product.currency)}${product.original_price}`
                                                : ""
                                        }
                                        details={product.permissions.map(getPermissionDetails)}
                                        isCurrentPlan={false}
                                        onBuyNow={() => doBuyAction(product.id)}
                                    />
                                ))}
                            </ProductCardsWrapper>
                        ))}
                    </TabPanel>
                    <TabPanel>
                        {getProductsRows("extra_package").map((productRow, rowIndex) => (
                            <ProductCardsWrapper key={rowIndex}>
                                {productRow.map((product) => (
                                    <ProductCard
                                        key={product.id}
                                        title={product.name}
                                        price={`${getCurrencySymbol(product.currency)}${product.discount_price}`}
                                        originalPrice={`${getCurrencySymbol(product.currency)}${
                                            product.original_price
                                        }`}
                                        details={product.permissions.map(getPermissionDetails)}
                                        isCurrentPlan={false}
                                        onBuyNow={() => doBuyAction(product.id)}
                                    />
                                ))}
                            </ProductCardsWrapper>
                        ))}
                    </TabPanel>
                </Tabs>
            </BuyPageBody>
            <DSPageFooter showVersion={false} />
        </BuyPageContainer>
    ) : (
        <Navigate to="/login" replace={true} />
    )
}

export default BuyPage

const BuyPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`
const BuyPageHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 16px 30px;
    box-sizing: border-box;
    background-color: rgb(33, 33, 33);
    color: white;
`

const BuyPageHeaderLogo = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.2em;

    & img {
        height: 40px;
        margin-right: 20px;
        border-radius: 15px;
    }
`

const BuyPageHeaderUserAvatar = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
`

const BuyPageBody = styled.main`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: auto;

    & h1 {
        margin: 40px 0 20px;
    }
`

const ProductCardsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
`
