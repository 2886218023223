import React, { useState, useEffect, useCallback, useRef } from "react"
import { useNavigate } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import ReactMarkdown from "react-markdown"
import { useTranslation } from "react-i18next"
import remarkGfm from "remark-gfm"
import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import "katex/dist/katex.min.css"
import CodeBlock from "./code-block"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ActionButton from "./action-button"
import { useUser } from "../../shared/user-context"
import { sprintf } from "sprintf-js"
import {
    DSMessage,
    DSMessageSender,
    DSMessageCategory,
    DSNextActionCode,
    DSNextActionLabelKeys,
    DSNextActionLocation,
    DSRelatedPageContext,
    QuotaCheckResponse,
    NextActionStateType,
    NextActionState,
} from "../../shared/message"
import { faEllipsis } from "@fortawesome/free-solid-svg-icons"
import PopupMenu, { MenuItem } from "../menu/popup-menu"
import PromptModal, { ModalButtonType } from "../modals/prompt-modal"
import { dsToastUpdateProgress, getAPIUrl } from "../../shared/function"
import ChangeCategoryModal from "../modals/change-category-modal"
import { DSFileCategory } from "../../shared/file"
import { InternalMessageID } from "./chat-area"
import { PermissionType } from "../../shared/business"
import { dsToastInfo, dsToastDismiss } from "../../shared/function"

interface MessageCellProps {
    message: DSMessage
    streamActive: boolean
    unfinishedMessage: string
    fileCategory: DSFileCategory
    onRelatedPageClicked: (pageContext: DSRelatedPageContext) => void
    onActionCompleted: () => void
    onQuestionStarted: (questionText: string, replyOnly: boolean, conversationID: number | null) => void
    onFileCategoryChanged: (category: DSFileCategory) => void
    onMessageChanged: () => void
}

export const MessageCell: React.FC<MessageCellProps> = ({
    message,
    streamActive,
    unfinishedMessage,
    fileCategory,
    onRelatedPageClicked,
    onActionCompleted,
    onQuestionStarted,
    onFileCategoryChanged,
    onMessageChanged,
}) => {
    // 从 UserContext 中获取用户信息
    const { userToken, userAvatar, fetchWithChecks } = useUser()
    const navigate = useNavigate()
    const { t } = useTranslation()

    // 消息关联动作状态
    const [actionState, setActionState] = useState<NextActionStateType>(NextActionState.Default)
    // 后端关联动作执行状态轮询 URL
    const [actionStatePollUrl, setActionStatePollUrl] = useState<string | null>(null)
    // 弹出菜单标记
    const [popupMenuVisible, setPopupMenuVisible] = useState<boolean>(false)
    // 配额确认对话框显示/隐藏开关
    const [isPromptModalOpen, setPromptModalOpen] = useState<boolean>(false)
    // 删除确认对话框显示/隐藏开关
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
    // 修改分类对话框显示/隐藏开关
    const [isChangeCategoryModalOpen, setChangeCategoryModalOpen] = useState<boolean>(false)
    // 配额数据
    const [quotaData, setQuotaData] = useState<QuotaCheckResponse | null>(null)
    // 配额提示文本
    const [confirmQuotaPrompt, setConfirmQuotaPrompt] = useState<string>("")
    // 是否需要购买配额
    const [needBuyMore, setNeedBuyMore] = useState<boolean>(false)
    // 关联动作执行进度
    const [progress, setProgress] = useState<number>(0)
    // 模拟进度更新的定时器
    const progressIntervalRef = useRef<NodeJS.Timer | null>(null)
    // 重新总结动作执行提示框的 ID
    const actionProcessingToastID = useRef<ToastID | null>(null)

    // 构建菜单项
    function buildMenuItems() {
        let items: MenuItem[] = [
            {
                type: "item",
                icon: "faLayerGroup",
                text: t("changeDocCategory"),
                onClick: onMenuChangeCategoryClicked,
            },
            { type: "divider" },
            { type: "item", icon: "faTrashCan", text: t("delete"), onClick: onMenuDeleteMessageClicked },
        ]

        if (message.next_actions) {
            message.next_actions.forEach((action) => {
                if (action.location === DSNextActionLocation.Menu) {
                    const actionMenuItem: MenuItem = {
                        type: "item",
                        icon: "faArrowRotateRight",
                        text: t(DSNextActionLabelKeys[action.id]),
                        onClick: () => {
                            if (actionState === NextActionState.Default) {
                                switch (action.id) {
                                    case DSNextActionCode.ResummarizeDocument:
                                        startResummarizeAction()
                                        break
                                    case DSNextActionCode.Regenerate:
                                        onQuestionStarted(message.content, true, message.conversation_id)
                                        break
                                    default:
                                        break
                                }
                            }
                        },
                    }

                    // 每次都在分割线前（倒数第三的位置）插入新菜单项
                    items.splice(items.length - 2, 0, actionMenuItem)
                }
            })
        }

        return items
    }

    // 预处理普通文本内容
    function preprocessNormalContent(): string {
        // 处理美元符号，防止被误判为数学公式。
        return message.content.replace(/\$(\d+)/g, "&#36;$1")
    }

    // 预处理问题列表类型的文本内容
    function preprocessQuestionsContent(): JSX.Element {
        let processedContent = preprocessNormalContent()
        let questionIndex = 0
        const emojis = ["1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣"]

        const questionsComponents: JSX.Element[] = []

        if (message.category === DSMessageCategory.Questions) {
            processedContent.split(/\[DS-QUESTION-START\]/).forEach((questionText, idx) => {
                if (questionText.trim().length > 0) {
                    questionsComponents.push(
                        <p key={idx} style={{ lineHeight: "1.2" }}>
                            {emojis[questionIndex % emojis.length]}&nbsp;&nbsp;{questionText.trim()}
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleQuestionClick(questionText.trim())}
                            >
                                &nbsp;&nbsp;👉
                            </span>
                        </p>
                    )
                    questionIndex++
                }
            })
        }

        return <div style={{ marginTop: "0.5em" }}>{questionsComponents}</div>
    }

    // 点击推荐问题发起问答
    function handleQuestionClick(questionText: string) {
        onQuestionStarted(questionText, false, null)
    }

    // 格式化时间
    function formatDate(date: Date) {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, "0")
        const day = String(date.getDate()).padStart(2, "0")
        const hours = String(date.getHours()).padStart(2, "0")
        const minutes = String(date.getMinutes()).padStart(2, "0")
        const seconds = String(date.getSeconds()).padStart(2, "0")

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }

    // 点击更多菜单按钮
    const onMoreMenuClicked = () => {
        setPopupMenuVisible(!popupMenuVisible)
    }

    // 用户点击删除消息菜单项
    function onMenuDeleteMessageClicked() {
        setDeleteModalOpen(true)
    }

    // 用户确认删除消息
    async function handleConfirmDeleteMessage() {
        // 调用 API 删除文件
        const urlDeleteFile = getAPIUrl(`/api/messages/${message.id}`)
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        try {
            const response = await fetchWithChecks(urlDeleteFile, true, false, {
                method: "DELETE",
                headers: headers,
            })
            if (response.ok) {
                // 上层回调
                onMessageChanged()
            }
            setDeleteModalOpen(false)
        } catch (err) {
            console.error("Failed to delete message:", err)
        }
    }

    // 处理更换文件分类
    function onMenuChangeCategoryClicked() {
        setChangeCategoryModalOpen(true)
    }

    // 处理变更文件分类
    function handleChangeCategory(category: DSFileCategory) {
        setChangeCategoryModalOpen(false)
        onFileCategoryChanged(category)
        // onMessageChanged()
    }

    // 找到重新总结动作对应的 Action。
    const resummarizeAction =
        message.next_actions &&
        message.next_actions.find((action) => action.id === DSNextActionCode.ResummarizeDocument)

    // 启动重新总结动作的准备工作
    const startResummarizeAction = async () => {
        // 调用 API 获取指定权限配额等相关信息
        const urlQuotaCheck = getAPIUrl(
            `/api/messages/action/quota?file_id=${message.file_id}&message_id=${message.id}&action_id=${DSNextActionCode.ResummarizeDocument}`
        )
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        try {
            const response = await fetchWithChecks(urlQuotaCheck, true, false, {
                method: "GET",
                headers: headers,
            })
            if (response.ok) {
                const result: QuotaCheckResponse = await response.json()
                setQuotaData(result)
                setPromptModalOpen(true)
            }
        } catch (err) {
            console.error("Failed to check user's quota:", err)
        }
    }

    // 取消重新总结动作
    const cancelResummarizeAction = () => {
        setQuotaData(null)
        setPromptModalOpen(false)
    }

    // 确认执行重新总结动作
    function onResummarizeActionConfirmed() {
        setQuotaData(null)
        setPromptModalOpen(false)

        if (resummarizeAction) {
            const apiParams = new URLSearchParams()
            apiParams.append("file_id", message.file_id.toString())
            apiParams.append("message_id", message.id.toString())
            apiParams.append("action_id", resummarizeAction.id.toString())
            apiParams.append("need_toc", resummarizeAction.need_toc.toString())
            doResummarizeAction(apiParams)
        }
    }

    // 执行重新总结动作
    const doResummarizeAction = async (params: URLSearchParams) => {
        setActionState(NextActionState.Processing)
        actionProcessingToastID.current = dsToastInfo(t("toastResummarizeProcessing"))

        // 设置一个定时器来模拟进度更新
        setProgress(0)
        progressIntervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                let newProgress = prevProgress + 0.03
                if (newProgress > 0.95) {
                    newProgress = 0.95
                }
                return newProgress
            })
        }, 1000)

        // 调用 API 启动后端动作执行
        const urlDoNextAction = getAPIUrl(`/api/messages/action`)
        const headers: HeadersInit = userToken
            ? { Authorization: userToken, "Content-Type": "application/x-www-form-urlencoded" }
            : {}
        try {
            const response = await fetchWithChecks(urlDoNextAction, true, false, {
                method: "POST",
                headers: headers,
                body: params.toString(),
            })
            if (response.ok) {
                const result = await response.json()
                setActionStatePollUrl(result.poll_url)
            }
        } catch (err) {
            console.error("Failed to execute the action:", err)
            setActionState(NextActionState.Default)
            if (actionProcessingToastID.current) {
                dsToastDismiss(actionProcessingToastID.current)
                actionProcessingToastID.current = null
            }
        }
    }

    const getPermissionTypeName = useCallback(
        (permissionType: string): string => {
            switch (permissionType) {
                case PermissionType.UploadPage:
                    return t("uploadPage")
                case PermissionType.Question:
                    return t("question")
                case PermissionType.FullUsePage:
                    return t("fullUsePage")
                default:
                    break
            }
            return t("unknown")
        },
        [t]
    )

    // 构建提示信息
    const buildConfirmQuotaPrompt = useCallback(() => {
        let prompt = t("notReady")

        if (resummarizeAction && quotaData) {
            let estimatedQuotaConsumption = quotaData.page_count
            if (resummarizeAction.need_toc) {
                if (quotaData.has_toc && quotaData.estimated_quota > 0) {
                    estimatedQuotaConsumption = quotaData.estimated_quota
                }
            } else {
                estimatedQuotaConsumption = quotaData.estimated_quota
            }

            let validQuotaCount =
                quotaData.subscription_quota.amount +
                quotaData.extra_quota.amount -
                quotaData.subscription_quota.usage -
                quotaData.extra_quota.usage
            if (validQuotaCount < 0) {
                validQuotaCount = 0
            }

            if (validQuotaCount >= estimatedQuotaConsumption) {
                prompt = sprintf(
                    t("confirmQuotaConsumptionPrompt"),
                    getPermissionTypeName(resummarizeAction.required_permission),
                    estimatedQuotaConsumption,
                    validQuotaCount
                )
                setNeedBuyMore(false)
            } else {
                prompt = sprintf(
                    t("buyMoreQuotaPrompt"),
                    getPermissionTypeName(resummarizeAction.required_permission),
                    estimatedQuotaConsumption,
                    validQuotaCount
                )
                setNeedBuyMore(true)
            }
        }

        setConfirmQuotaPrompt(prompt)
    }, [resummarizeAction, getPermissionTypeName, t, quotaData])

    useEffect(() => {
        if (quotaData) {
            buildConfirmQuotaPrompt()
        }
    }, [quotaData, buildConfirmQuotaPrompt])

    useEffect(() => {
        if (actionState === NextActionState.Processing && actionStatePollUrl) {
            const poll = async () => {
                const urlPoll = getAPIUrl(actionStatePollUrl)
                const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                const statusResponse = await fetch(urlPoll, {
                    method: "GET",
                    headers: headers,
                })
                const statusData = await statusResponse.json()
                if (statusData.status === "completed") {
                    // 动作完成
                    // setProgress(statusData.progress)

                    // 如果存在，清除定时器
                    if (progressIntervalRef.current) {
                        clearInterval(progressIntervalRef.current)
                        progressIntervalRef.current = null
                    }

                    if (actionProcessingToastID.current) {
                        dsToastDismiss(actionProcessingToastID.current)
                        actionProcessingToastID.current = null
                    }

                    setTimeout(() => {
                        setActionState(NextActionState.Default)
                        setActionStatePollUrl(null)

                        onActionCompleted()
                    }, 500)
                } else {
                    // 继续轮询
                    setTimeout(poll, 3000)
                }
            }

            poll()
        }
    }, [actionState, actionStatePollUrl, userToken, onActionCompleted])

    useEffect(() => {
        if (actionProcessingToastID.current) {
            dsToastUpdateProgress(actionProcessingToastID.current, progress)
        }
    }, [progress])

    return !streamActive ? (
        <MessageContainer className={message.sender === DSMessageSender.User ? "user" : "assistant"}>
            <Avatar>
                <img
                    src={message.sender === DSMessageSender.User ? userAvatar || "/user.png" : "/assistant.png"}
                    alt="Avatar"
                />
            </Avatar>
            <MessageBody>
                <MessageBar>
                    <MessageTime>{formatDate(message.time)}</MessageTime>
                    <FontAwesomeIcon icon={faEllipsis} onClick={onMoreMenuClicked} />
                    <PopupMenu
                        menuItems={buildMenuItems()}
                        direction="down"
                        position={{ horizontal: "right", vertical: "top" }}
                        width={200}
                        verticalOffset={30}
                        visible={popupMenuVisible}
                        onClose={() => setPopupMenuVisible(false)}
                    />
                    <PromptModal
                        isOpen={isDeleteModalOpen}
                        title={t("deleteMessage")}
                        prompt={t("promptForDeleteMessage")}
                        buttons={[
                            {
                                type: ModalButtonType.Cancel,
                                label: t("cancel"),
                                onButtonClicked: () => {
                                    setDeleteModalOpen(false)
                                },
                            },
                            {
                                type: ModalButtonType.Danger,
                                label: t("delete"),
                                onButtonClicked: handleConfirmDeleteMessage,
                            },
                        ]}
                        onRequestClose={() => setDeleteModalOpen(false)}
                    />
                    {resummarizeAction && (
                        <PromptModal
                            isOpen={isPromptModalOpen}
                            title={t(DSNextActionLabelKeys[resummarizeAction.id])}
                            prompt={confirmQuotaPrompt}
                            buttons={[
                                {
                                    type: ModalButtonType.Cancel,
                                    label: t("cancel"),
                                    onButtonClicked: () => {
                                        cancelResummarizeAction()
                                    },
                                },
                                needBuyMore
                                    ? {
                                          type: ModalButtonType.Yes,
                                          label: t("buy"),
                                          onButtonClicked: () => {
                                              navigate("/buy")
                                          },
                                      }
                                    : {
                                          type: ModalButtonType.Yes,
                                          label: t("confirm"),
                                          onButtonClicked: () => {
                                              onResummarizeActionConfirmed()
                                          },
                                      },
                            ]}
                            onRequestClose={cancelResummarizeAction}
                        />
                    )}
                    <ChangeCategoryModal
                        isOpen={isChangeCategoryModalOpen}
                        fileID={message.file_id}
                        category={fileCategory}
                        onCategoryChanged={handleChangeCategory}
                        onRequestClose={() => setChangeCategoryModalOpen(false)}
                    />
                </MessageBar>
                <MessageContent data-id={message.id}>
                    {message.id === InternalMessageID.Assistant ? (
                        <div>
                            <ReactMarkdown
                                children={preprocessNormalContent()}
                                components={{
                                    code: CodeBlock,
                                }}
                                remarkPlugins={[remarkGfm, remarkMath]}
                                rehypePlugins={[rehypeKatex]}
                            />
                            <span className="cursor">█</span>
                        </div>
                    ) : message.category === DSMessageCategory.Questions ? (
                        preprocessQuestionsContent()
                    ) : (
                        <ReactMarkdown
                            children={preprocessNormalContent()}
                            components={{
                                code: CodeBlock,
                            }}
                            remarkPlugins={[remarkGfm, remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                        />
                    )}
                </MessageContent>
                {(message.next_actions?.length > 0 || message.related_pages?.length > 0) && (
                    <MessageActionBar>
                        {(message.next_actions || []).length > 0 && (
                            <NextActions>
                                {message.next_actions.map(
                                    (action) =>
                                        action.location === DSNextActionLocation.Button && (
                                            <ActionButton
                                                key={action.id}
                                                message={message}
                                                action={action}
                                                onActionCompleted={onActionCompleted}
                                            />
                                        )
                                )}
                            </NextActions>
                        )}
                        {(message.related_pages || []).length > 0 && (
                            <RelatedPages>
                                {message.related_pages.map((pageContext) => (
                                    <PageNum
                                        key={pageContext.pageNum}
                                        onClick={() => onRelatedPageClicked(pageContext)}
                                    >
                                        {pageContext.pageNum}
                                    </PageNum>
                                ))}
                            </RelatedPages>
                        )}
                    </MessageActionBar>
                )}
            </MessageBody>
        </MessageContainer>
    ) : (
        <MessageContainer className="assistant">
            <Avatar>
                <img src="/assistant.png" alt="Avatar" />
            </Avatar>
            <MessageBody>
                <MessageBar>
                    <MessageTime>{message && formatDate(message.time)}</MessageTime>
                </MessageBar>
                <MessageContent data-id={InternalMessageID.Assistant}>
                    <div>
                        <span>{unfinishedMessage}</span>
                        <span className="cursor">█</span>
                    </div>
                </MessageContent>
            </MessageBody>
        </MessageContainer>
    )
}

export default MessageCell

// Styled components here
const MessageContainer = styled.div`
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;

    &.user {
        color: white;

        & + & {
            border-top: 1px solid hsla(0, 0%, 100%, 0.2);
        }
    }

    &.assistant {
        color: white;
        background-color: rgba(255, 255, 255, 0.1);

        & + & {
            border-top: 1px solid hsla(0, 0%, 100%, 0.2);
        }
    }
`

const Avatar = styled.div`
    margin-left: 20px;
    margin-right: 20px;

    & img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`

// 定义闪烁动画
const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const MessageBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
`

const MessageBar = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5em;
    width: 100%;

    & svg {
        color: darkgray;
        margin-right: 20px;
        cursor: pointer;
    }
`

const MessageTime = styled.div`
    font-size: 0.875rem;
    color: darkgray;
`

const MessageContent = styled.div`
    display: block;
    line-height: 1.5;
    word-wrap: break-word;
    text-align: left;
    padding-right: 30px;
    font-family: "Lato", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;

    & > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    & h1 {
        font-size: 1em;
        font-weight: 700;
        margin: 0 0 0.5em 0;
    }

    & h2 {
        font-size: 1em;
        font-weight: 600;
        margin: 0 0 0.5em 0;
    }

    & h3 {
        font-size: 1em;
        font-weight: 500;
        margin: 0 0 0.5em 0;
    }

    & h4,
    & h5 {
        font-size: 1em;
        font-weight: 400;
        margin: 0 0 0.5em 0;
    }

    & div {
        margin-top: 0;
    }

    & p {
        margin-top: 0;
        margin-bottom: 0.8em;
        line-height: 1.6;
    }

    & pre {
        width: 100%;
        overflow: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .content code {
        word-break: break-word !important;
    }

    & a {
        color: deepskyblue;
    }

    & ol,
    & ul {
        margin-block-start: 0;
        margin-block-end: 0.8em;
        padding-bottom: 0;
        padding-inline-start: 20px;
    }

    & li {
        line-height: 1.6;
    }

    & table {
        width: 100%;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 1em;
        border-collapse: collapse;
    }

    & table th,
    & table td {
        padding: 2px 5px;
        border: 1px solid #999;
    }

    & table thead tr {
        background-color: rgba(236, 236, 241, 0.2);
    }

    & table tbody tr:nth-child(even) {
        background-color: rgba(225, 225, 255, 0.1);
    }

    & .code-block {
        border-radius: 5px;
        min-width: 300px;
    }

    & .code-block-title {
        font-size: 0.875em;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        background-color: #343641;
    }

    & .code-block-body {
        overflow-x: auto;
        max-width: 100%;
    }

    & .inline-code {
        display: inline-block;
        color: white;
        font-size: 0.875em;
        font-weight: 600;
    }

    &[data-id="-2"] span.cursor {
        animation: ${blink} 0.5s infinite;
    }
`

const MessageActionBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    padding-right: 20px;
    box-sizing: border-box;
`

const RelatedPages = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 15px;
`

const PageNum = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-family: "Roboto Condensed", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
    font-weight: 300;
    color: white;
    background: cadetblue;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    padding: 2px;
    cursor: pointer;
`

const NextActions = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 15px;
`
