import React, { useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import { useUser } from "../../shared/user-context"
import { getAPIUrl } from "../../shared/function"
import { sprintf } from "sprintf-js"
import {
    DSNextActionCode,
    DSNextActionLabelKeys,
    DSMessage,
    DSNextAction,
    QuotaCheckResponse,
    NextActionStateType,
    NextActionState,
} from "../../shared/message"
import PromptModal, { ModalButtonType } from "../modals/prompt-modal"
import SummarizeChaptersModal from "../modals/summarize-chapters-modal"
import { PermissionType } from "../../shared/business"
import ExtractToCModal from "../modals/extract-toc-modal"

interface ActionButtonProps {
    message: DSMessage
    action: DSNextAction
    onActionCompleted: () => void
}

const ActionButton: React.FC<ActionButtonProps> = ({ message, action, onActionCompleted }) => {
    const { userToken, fetchWithChecks } = useUser()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [actionState, setActionState] = useState<NextActionStateType>(NextActionState.Default)
    const [actionStatePollUrl, setActionStatePollUrl] = useState<string | null>(null)
    const [quotaData, setQuotaData] = useState<QuotaCheckResponse | null>(null)
    const [progress, setProgress] = useState<number>(0)

    // 提示对话框显示/隐藏开关
    const [isPromptModalOpen, setPromptModalOpen] = useState<boolean>(false)
    // 提取章节目录对话框显示/隐藏开关
    const [isExtractToCModalOpen, setExtractToCModalOpen] = useState<boolean>(false)
    // 分章节总结对话框显示/隐藏开关
    const [isSummarizeChaptersModalOpen, setSummarizeChaptersModalOpen] = useState<boolean>(false)

    const [confirmQuotaPrompt, setConfirmQuotaPrompt] = useState<string>("")
    const [needBuyMore, setNeedBuyMore] = useState<boolean>(false)

    const getActionLabel = useCallback(() => {
        let labelKey = DSNextActionLabelKeys[action.id]
        if (!labelKey) labelKey = DSNextActionLabelKeys[DSNextActionCode.UnsupportAction]
        return t(labelKey)
    }, [action, t])

    const getPermissionTypeName = useCallback(
        (permissionType: string): string => {
            switch (permissionType) {
                case PermissionType.UploadPage:
                    return t("uploadPage")
                case PermissionType.Question:
                    return t("question")
                case PermissionType.FullUsePage:
                    return t("fullUsePage")
                default:
                    break
            }
            return t("unknown")
        },
        [t]
    )

    // 计算有效剩余配额。
    function calcRemainingQuota(type: PermissionType): number {
        let validQuotaCount = 0
        if (quotaData) {
            validQuotaCount =
                quotaData.subscription_quota.amount +
                quotaData.extra_quota.amount -
                quotaData.subscription_quota.usage -
                quotaData.extra_quota.usage
            if (validQuotaCount < 0) {
                validQuotaCount = 0
            }
        }
        return validQuotaCount
    }

    // 构建提示信息
    const buildConfirmQuotaPrompt = useCallback(() => {
        let prompt = t("notReady")

        if (quotaData) {
            let estimatedQuotaConsumption = quotaData.page_count
            if (action.need_toc) {
                if (quotaData.has_toc && quotaData.estimated_quota > 0) {
                    estimatedQuotaConsumption = quotaData.estimated_quota
                }
            } else {
                estimatedQuotaConsumption = quotaData.estimated_quota
            }

            let validQuotaCount =
                quotaData.subscription_quota.amount +
                quotaData.extra_quota.amount -
                quotaData.subscription_quota.usage -
                quotaData.extra_quota.usage
            if (validQuotaCount < 0) {
                validQuotaCount = 0
            }

            if (validQuotaCount >= estimatedQuotaConsumption) {
                prompt = sprintf(
                    t("confirmQuotaConsumptionPrompt"),
                    getPermissionTypeName(action.required_permission),
                    estimatedQuotaConsumption,
                    validQuotaCount
                )
                setNeedBuyMore(false)
            } else {
                prompt = sprintf(
                    t("buyMoreQuotaPrompt"),
                    getPermissionTypeName(action.required_permission),
                    estimatedQuotaConsumption,
                    validQuotaCount
                )
                setNeedBuyMore(true)
            }
        }

        setConfirmQuotaPrompt(prompt)
    }, [action, getPermissionTypeName, t, quotaData])

    // 动作按钮点击事件
    const onActionButtonClicked = () => {
        if (actionState === NextActionState.Default) {
            if (action.id === DSNextActionCode.GenerateExampleQuestions) {
                // 对于生成示例问题，不需要弹出配额提示，直接执行。
                const apiParams = new URLSearchParams()
                apiParams.append("file_id", message.file_id.toString())
                apiParams.append("message_id", message.id.toString())
                apiParams.append("action_id", action.id.toString())
                apiParams.append("need_toc", action.need_toc.toString())
                doAction(apiParams)
            } else {
                startAction()
            }            
        }
    }

    // 启动消息关联动作的准备工作
    const startAction = async () => {
        // 调用 API 获取指定权限配额等相关信息
        const urlQuotaCheck = getAPIUrl(
            `/api/messages/action/quota?file_id=${message.file_id}&message_id=${message.id}&action_id=${action.id}`
        )
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        try {
            const response = await fetchWithChecks(urlQuotaCheck, true, false, {
                method: "GET",
                headers: headers,
            })
            if (response.ok) {
                const result: QuotaCheckResponse = await response.json()
                setQuotaData(result)
                switch (action.id) {
                    case DSNextActionCode.PaperMethod:
                    case DSNextActionCode.PaperConclusion:
                        setPromptModalOpen(true)
                        break
                    case DSNextActionCode.BookExtractToC:
                        setExtractToCModalOpen(true)
                        break
                    case DSNextActionCode.BookSummarizeChapters:
                        setSummarizeChaptersModalOpen(true)
                        break
                    default:
                        break
                }
            }
        } catch (err) {
            console.error("Failed to check user's quota:", err)
        }
    }

    // 取消学术论文类动作
    const cancelPaperAction = () => {
        setQuotaData(null)
        setPromptModalOpen(false)
    }

    // 取消提取章节目录动作
    const cancelExtractToCAction = () => {
        setExtractToCModalOpen(false)
    }

    // 取消分章节总结动作
    const cancelSummarizeChapterAction = () => {
        setSummarizeChaptersModalOpen(false)
    }

    // 执行关联动作
    const doAction = async (params: URLSearchParams) => {
        setActionState(NextActionState.Processing)
        setProgress(0)

        // 调用 API 启动后端动作执行
        const urlDoNextAction = getAPIUrl(`/api/messages/action`)
        const headers: HeadersInit = userToken
            ? { Authorization: userToken, "Content-Type": "application/x-www-form-urlencoded" }
            : {}
        try {
            const response = await fetchWithChecks(urlDoNextAction, true, false, {
                method: "POST",
                headers: headers,
                body: params.toString(),
            })
            if (response.ok) {
                const result = await response.json()
                setActionStatePollUrl(result.poll_url)
            }
        } catch (err) {
            console.error("Failed to execute the action:", err)
            setActionState(NextActionState.Default)
        }
    }

    useEffect(() => {
        if (quotaData) {
            buildConfirmQuotaPrompt()
        }
    }, [quotaData, buildConfirmQuotaPrompt])

    useEffect(() => {
        if (actionState === NextActionState.Processing && actionStatePollUrl) {
            const poll = async () => {
                const urlPoll = getAPIUrl(actionStatePollUrl)
                const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                const statusResponse = await fetch(urlPoll, {
                    method: "GET",
                    headers: headers,
                })
                const statusData = await statusResponse.json()
                if (statusData.status === "completed") {
                    // 动作完成
                    setProgress(statusData.progress)

                    setTimeout(() => {
                        setActionState(NextActionState.Default)
                        setActionStatePollUrl(null)

                        onActionCompleted()
                    }, 500)
                } else {
                    // 更新进度
                    setProgress(statusData.progress)
                    // 继续轮询
                    setTimeout(poll, 3000)
                }
            }

            poll()
        }
    }, [actionState, actionStatePollUrl, userToken, onActionCompleted])

    // 确认执行学术论文相关动作
    function onPaperActionConfirmed() {
        setQuotaData(null)
        setPromptModalOpen(false)

        const apiParams = new URLSearchParams()
        apiParams.append("file_id", message.file_id.toString())
        apiParams.append("message_id", message.id.toString())
        apiParams.append("action_id", action.id.toString())
        apiParams.append("need_toc", action.need_toc.toString())
        doAction(apiParams)
    }

    // 确认执行提取章节目录的动作
    function onExtractToCActionConfirmed(startPage: number, endPage: number, correction: number) {
        setSummarizeChaptersModalOpen(false)

        const apiParams = new URLSearchParams()
        apiParams.append("file_id", message.file_id.toString())
        apiParams.append("message_id", message.id.toString())
        apiParams.append("action_id", action.id.toString())
        apiParams.append("need_toc", action.need_toc.toString())
        apiParams.append("start_page", startPage.toString())
        apiParams.append("end_page", endPage.toString())
        apiParams.append("correction", correction.toString())
        doAction(apiParams)
    }

    // 确认执行分章节总结的动作
    function onSummarizeChaptersActionConfirmed(oID: number, pageCount: number) {
        setSummarizeChaptersModalOpen(false)

        const apiParams = new URLSearchParams()
        apiParams.append("file_id", message.file_id.toString())
        apiParams.append("message_id", message.id.toString())
        apiParams.append("action_id", action.id.toString())
        apiParams.append("need_toc", action.need_toc.toString())
        apiParams.append("outline_id", oID.toString())
        apiParams.append("page_count", pageCount.toString())
        doAction(apiParams)
    }

    return (
        <ActionButtonContainer $state={actionState} onClick={onActionButtonClicked}>
            {actionState === "processing" ? (
                <ActionButtonLabel $state={actionState}>
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <ProcessingText>{`${t("analyzing")} ${progress}%`}</ProcessingText>
                </ActionButtonLabel>
            ) : (
                <ActionButtonLabel $state={actionState}>
                    <span>{getActionLabel()}</span>
                </ActionButtonLabel>
            )}
            <PromptModal
                isOpen={isPromptModalOpen}
                title={action && getActionLabel()}
                prompt={confirmQuotaPrompt}
                buttons={[
                    {
                        type: ModalButtonType.Cancel,
                        label: t("cancel"),
                        onButtonClicked: () => {
                            cancelPaperAction()
                        },
                    },
                    needBuyMore
                        ? {
                              type: ModalButtonType.Yes,
                              label: t("buy"),
                              onButtonClicked: () => {
                                  navigate("/buy")
                              },
                          }
                        : {
                              type: ModalButtonType.Yes,
                              label: t("confirm"),
                              onButtonClicked: () => {
                                  onPaperActionConfirmed()
                              },
                          },
                ]}
                onRequestClose={cancelPaperAction}
            />
            <SummarizeChaptersModal
                isOpen={isSummarizeChaptersModalOpen}
                fileID={message.file_id}
                remainingQuota={calcRemainingQuota(PermissionType.FullUsePage)}
                onActionConfirmed={onSummarizeChaptersActionConfirmed}
                onRequestClose={cancelSummarizeChapterAction}
            />
            <ExtractToCModal
                isOpen={isExtractToCModalOpen}
                remainingQuota={calcRemainingQuota(PermissionType.FullUsePage)}
                onActionConfirmed={onExtractToCActionConfirmed}
                onRequestClose={cancelExtractToCAction}
            />
        </ActionButtonContainer>
    )
}

export default ActionButton

const ActionButtonContainer = styled.div<{ $state: NextActionStateType }>`
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: rgb(68, 70, 84);
    border: 1px solid hsla(0, 0%, 100%, 0.2);
    border-radius: 5px;
    cursor: ${(props) => (props.$state === "default" ? "pointer" : "not-allowed")};

    &:hover {
        background-color: rgba(64, 65, 79, 0.1);
    }
`
const ActionButtonLabel = styled.label<{ $state: NextActionStateType }>`
    text-align: center;
    font-size: 0.875rem;
    color: rgb(217, 217, 227);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${(props) => (props.$state === "default" ? "pointer" : "not-allowed")};
`

const ProcessingText = styled.span`
    margin-left: 10px;
`
