import { QuotaDetailResponse } from "./business"

export type DSMessageSenderType = "user" | "assistant" | "starter" | "system"
export const DSMessageSender = {
    User: "user" as DSMessageSenderType,
    Assistant: "assistant" as DSMessageSenderType,
    Starter: "starter" as DSMessageSenderType,
    System: "system" as DSMessageSenderType,
}

export type DSMessageCategoryType = "normal" | "questions" | "translation" | "chapter"
export const DSMessageCategory = {
    Normal: "normal" as DSMessageCategoryType,
    Questions: "questions" as DSMessageCategoryType,
    Translation: "translation" as DSMessageCategoryType,
    Chapter: "chapter" as DSMessageCategoryType,
}

export type DSActionLocationType = "button" | "menu"
export const DSNextActionLocation = {
    Button: "button" as DSActionLocationType,
    Menu: "menu" as DSActionLocationType,
}

export type NextActionStateType = "default" | "processing"
export const NextActionState = {
    Default: "default" as NextActionStateType,
    Processing: "processing" as NextActionStateType,
}

// 消息关联动作的 Code
export enum DSNextActionCode {
    UnsupportAction = 0,
    ResummarizeDocument = 10,
    GenerateExampleQuestions = 11,
    Regenerate = 12,
    PaperMethod = 101,
    PaperConclusion = 102,
    BookExtractToC = 1001,
    BookSummarizeChapters = 1002,
}

// 消息关联动作的标签 Key 映射表
export const DSNextActionLabelKeys = {
    [DSNextActionCode.UnsupportAction]: "unsupportAction",
    [DSNextActionCode.ResummarizeDocument]: "resummarizeDocument",
    [DSNextActionCode.GenerateExampleQuestions]: "generateExampleQuestions",
    [DSNextActionCode.Regenerate]: "regenerate",
    [DSNextActionCode.PaperMethod]: "paperMethod",
    [DSNextActionCode.PaperConclusion]: "paperConclusion",
    [DSNextActionCode.BookExtractToC]: "bookExtractToC",
    [DSNextActionCode.BookSummarizeChapters]: "bookSummarizeChapters",
}

export interface DSNextAction {
    id: DSNextActionCode
    name: string
    required_permission: string
    need_toc: boolean
    location: DSActionLocationType
}

export interface DSRelatedPageContext {
    pageNum: number
    segmentIds: number[]
}

export interface DSMessage {
    id: number
    user_id: number
    file_id: number
    sender: DSMessageSenderType
    category: DSMessageCategoryType
    content: string
    time: Date
    conversation_id: number | null
    related_pages: DSRelatedPageContext[]
    next_actions: DSNextAction[]
}

export interface MessageResponse {
    id: number
    user_id: number
    file_id: number
    sender: DSMessageSenderType
    category: DSMessageCategoryType
    content: string
    time: Date
    conversation_id: number | null
    related_pages: string[]
    next_actions: DSNextAction[]
}

export interface QuotaCheckResponse {
    page_count: number
    has_toc: boolean
    estimated_quota: number
    subscription_quota: QuotaDetailResponse
    extra_quota: QuotaDetailResponse
}
