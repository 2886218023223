import React, { useState, useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import styled from "styled-components"
import i18n from "../shared/i18n"
import { useTranslation } from "react-i18next"
import { dsToastSuccess, getAPIUrl } from "../shared/function"
import DSPageFooter from "../components/ds-page-footer"
import { hostProvider } from "../shared/config"
import { sprintf } from "sprintf-js"
import { DSCountryCode, DSLanguageCode, DSLanguageNames } from "../shared/constants"

enum StepCode {
    InputEmail = 1,
    VerifyEmail = 2,
    ResetPassword = 3,
}

interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    // 重置密码步骤
    const [step, setStep] = useState<StepCode>(StepCode.InputEmail)
    // 浏览器默认语言，需要采用标准定义
    const [browserLanguage, setBrowserLanuage] = useState(DSLanguageNames[DSLanguageCode.English])

    const location = useLocation()
    const initialEmail = location.state?.email || ""
    const [email, setEmail] = useState<string>(initialEmail)

    const [emailError, setEmailError] = useState<boolean>(false)
    const [code, setCode] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [isButtonDisabled, setIsButtonDisabled] = useState(initialEmail === "")

    // 根据浏览器语言选项设置语言参数
    useEffect(() => {
        const language = navigator.language
        if (language.startsWith("zh")) {
            setBrowserLanuage(DSLanguageNames[DSLanguageCode.SimplifiedChinese])
        }
    }, [])

    useEffect(() => {
        switch (browserLanguage) {
            case DSLanguageNames[DSLanguageCode.SimplifiedChinese]:
                i18n.changeLanguage("cn")
                break
            default:
                i18n.changeLanguage("en")
                break
        }
    }, [browserLanguage])

    // 处理提交按钮事件
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (step === StepCode.InputEmail) {
            // 第一步，根据用户输入的邮件地址发送验证码邮件。
            let countryCode = DSCountryCode.USA
            if (hostProvider === "aliyun") {
                countryCode = DSCountryCode.China
            }

            const url = getAPIUrl(`/api/auth/mail-verifications`)
            const apiParams = new URLSearchParams()
            apiParams.append("email", email)
            apiParams.append("country", countryCode)
            apiParams.append("language", browserLanguage)
            apiParams.append("mode", "reset")

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: apiParams.toString(),
                })

                if (response.ok) {
                    setStep(StepCode.VerifyEmail)
                    setIsButtonDisabled(true)
                    setErrorMessage(null)
                } else {
                    const errorData = await response.json()
                    setErrorMessage(errorData.error)
                }
            } catch (error) {
                console.error(error)
            }
        } else if (step === StepCode.VerifyEmail) {
            // 第二步，拿到用户输入的验证码，验证邮箱的有效性。
            const url = getAPIUrl(`/api/auth/mail-verifications/verify`)
            const apiParams = new URLSearchParams()
            apiParams.append("email", email)
            apiParams.append("code", code)

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: apiParams.toString(),
                })

                if (response.ok) {
                    setStep(StepCode.ResetPassword)
                    setIsButtonDisabled(true)
                    setErrorMessage(null)
                } else {
                    const errorData = await response.json()
                    setErrorMessage(errorData.error)
                }
            } catch (error) {
                console.error(error)
            }
        } else {
            // 第三步，用户需要输入两遍相同的符合要求的密码，则可创建帐户。
            if (password !== confirmPassword) {
                setErrorMessage(t("passwordMismatch"))
            } else if (password.length < 8) {
                setErrorMessage(t("passwordTooShort"))
            } else {
                try {
                    const url = getAPIUrl(`/api/auth/password`)
                    const response = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ email, password, code }),
                    })

                    if (response.ok) {
                        setErrorMessage(null)
                        dsToastSuccess(t("successResetPassword"))
                        
                        setTimeout(() => {
                            navigate("/app")
                        }, 2000)
                    } else {
                        const errorData = await response.json()
                        setErrorMessage(errorData.error)
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }
    }

    if (step === StepCode.InputEmail) {
        return (
            <ResetPasswordPageConainer>
                <ResetPasswordPageWrapper>
                    <ResetPasswordPageCard>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <ResetPasswordPageInput
                                className={`${emailError ? "input-error" : ""}`}
                                type="email"
                                placeholder={t("email", "Email")!}
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmailError(false)
                                    setErrorMessage(null)
                                    setEmail(e.target.value)
                                    setIsButtonDisabled(e.target.value === "")
                                }}
                            />
                            <ResetPasswordPageMessageWrapper>
                                {errorMessage && (
                                    <ResetPasswordPageErrorMessage>{errorMessage}</ResetPasswordPageErrorMessage>
                                )}
                            </ResetPasswordPageMessageWrapper>
                            {isButtonDisabled ? (
                                <ResetPasswordPageButtonDisabled type="submit" disabled>
                                    {t("beginReset")}
                                </ResetPasswordPageButtonDisabled>
                            ) : (
                                <ResetPasswordPageButton type="submit">{t("beginReset")}</ResetPasswordPageButton>
                            )}
                        </form>
                        <ResetPasswordPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </ResetPasswordPageOtherAction>
                    </ResetPasswordPageCard>
                </ResetPasswordPageWrapper>
                <DSPageFooter showVersion={true} />
            </ResetPasswordPageConainer>
        )
    } else if (step === StepCode.VerifyEmail) {
        return (
            <ResetPasswordPageConainer>
                <ResetPasswordPageWrapper>
                    <ResetPasswordPageCard $language={browserLanguage}>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <p>{sprintf(t("verificationCodePrompt"), email)}</p>
                            <ResetPasswordPageInput
                                type="text"
                                placeholder={t("verificationCode", "Verification Code")!}
                                value={code}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setErrorMessage(null)
                                    setCode(e.target.value)
                                    setIsButtonDisabled(e.target.value === "")
                                }}
                            />
                            <ResetPasswordPageMessageWrapper>
                                {errorMessage && (
                                    <ResetPasswordPageErrorMessage>{errorMessage}</ResetPasswordPageErrorMessage>
                                )}
                            </ResetPasswordPageMessageWrapper>
                            {isButtonDisabled ? (
                                <ResetPasswordPageButtonDisabled type="submit" disabled>
                                    {t("continue")}
                                </ResetPasswordPageButtonDisabled>
                            ) : (
                                <ResetPasswordPageButton type="submit">{t("continue")}</ResetPasswordPageButton>
                            )}
                        </form>
                        <ResetPasswordPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </ResetPasswordPageOtherAction>
                    </ResetPasswordPageCard>
                </ResetPasswordPageWrapper>
                <DSPageFooter showVersion={true} />
            </ResetPasswordPageConainer>
        )
    } else {
        return (
            <ResetPasswordPageConainer>
                <ResetPasswordPageWrapper>
                    <ResetPasswordPageCard>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <ResetPasswordPageInput
                                className={`${passwordError ? "input-error" : ""}`}
                                type="password"
                                placeholder={t("signupPassword", "Enter password")!}
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPasswordError(false)
                                    setErrorMessage(null)
                                    setPassword(e.target.value)
                                    setIsButtonDisabled(e.target.value === "" || confirmPassword === "")
                                }}
                            />
                            <ResetPasswordPageInput
                                className={`${passwordError ? "input-error" : ""}`}
                                type="password"
                                placeholder={t("signupConfirmPassword", "Confirm your password")!}
                                value={confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPasswordError(false)
                                    setErrorMessage(null)
                                    setConfirmPassword(e.target.value)
                                    setIsButtonDisabled(e.target.value === "" || password === "")
                                }}
                            />
                            <ResetPasswordPageMessageWrapper>
                                {errorMessage && (
                                    <ResetPasswordPageErrorMessage>{errorMessage}</ResetPasswordPageErrorMessage>
                                )}
                            </ResetPasswordPageMessageWrapper>
                            {isButtonDisabled ? (
                                <ResetPasswordPageButtonDisabled type="submit" disabled>
                                    {t("resetPassword")}
                                </ResetPasswordPageButtonDisabled>
                            ) : (
                                <ResetPasswordPageButton type="submit">{t("resetPassword")}</ResetPasswordPageButton>
                            )}
                        </form>
                        <ResetPasswordPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </ResetPasswordPageOtherAction>
                    </ResetPasswordPageCard>
                </ResetPasswordPageWrapper>
                <DSPageFooter showVersion={true} />
            </ResetPasswordPageConainer>
        )
    }
}

export default ResetPasswordPage

const ResetPasswordPageConainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const ResetPasswordPageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 500px;
    max-width: 100%;
`

const ResetPasswordPageMessageWrapper = styled.div<{ $height?: number }>`
    width: 100%;
    height: ${(props) => (props.$height ? `${props.$height}px` : "30px")};
`

const ResetPasswordPageErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ResetPasswordPageInput = styled.input`
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    border: 1px solid gray;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 6px 8px;

    &:focus {
        border-color: blue;
    }

    & .input-error {
        border-color: red;
    }

    &:last-of-type {
        margin-bottom: 5px;
    }
`

const ResetPasswordPageCard = styled.div<{ $language?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & img {
        max-width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    & form {
        width: 70%;
    }

    & form p {
        font-family: ${(props) =>
            props.$language === DSLanguageNames[DSLanguageCode.English] ? "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif" : "inherit"};
    }

    @media (max-height: 500px) {
        & img {
            margin-bottom: 10px;
        }
    }
`

const ResetPasswordPageButton = styled.button`
    width: 100%;
    height: 48px;
    color: white;
    background-color: #0052cc;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #0052cc;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
`

const ResetPasswordPageButtonDisabled = styled(ResetPasswordPageButton)`
    background-color: lightslategray;
    border-color: lightslategray;
    cursor: not-allowed;
`

const ResetPasswordPageOtherAction = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.6;
    margin: 10px 0;

    & a:first-child {
        margin-right: 15px;
    }

    & a:visited {
        color: blue;
    }    
`
