import React, { useEffect, useRef, useState, useCallback } from "react"
import { useUser } from "../../shared/user-context"
import styled from "styled-components"
import i18n from "../../shared/i18n"
import { useTranslation } from "react-i18next"
import { getAPIUrl, getWebSocketUrl } from "../../shared/function"
import MessageCell from "./message-cell"
import {
    DSRelatedPageContext,
    DSMessage,
    MessageResponse,
    DSMessageSender,
    DSMessageCategory,
} from "../../shared/message"
import { DSFileCategory } from "../../shared/file"

// 临时消息 ID
export enum InternalMessageID {
    User = -1,
    Assistant = -2,
}

interface ChatAreaProps {
    fileID: number
    fileCategory: DSFileCategory
    onRelatedPageClicked: (pageContext: DSRelatedPageContext) => void
}

const ChatArea: React.FC<ChatAreaProps> = ({ fileID, fileCategory, onRelatedPageClicked }) => {
    // 从 UserContext 中获取用户信息
    const { userID, userToken, fetchWithChecks } = useUser()
    const { t } = useTranslation()

    // 当前文件分类
    const [curFileCategory, setCurFileCategory] = useState<DSFileCategory>(fileCategory)

    // 创建 WebSocket 任务队列
    const [wsTasks, setWsTasks] = useState<string[]>([])
    // 管理 WebSocket 连接
    const wsConnectionsRef = useRef<Map<number, WebSocket>>(new Map())
    // 本地缓存未完结消息，主要用于多 stream 流的情况
    const [unfinishedMessages, setUnfinishedMessages] = useState<Map<number, string>>(new Map([[fileID, ""]]))
    // 连续会话 ID
    const [curConversationID, setCurConversationID] = useState<number | null>(null)

    const messagesEndRef = useRef<HTMLDivElement | null>(null)

    const [placeholder, setPlaceholder] = useState<string>("")
    const [inputValue, setInputValue] = useState<string>("")
    const [messages, setMessages] = useState<DSMessage[]>([])
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false)

    function parseRelatedPages(rawPages: string[]): DSRelatedPageContext[] {
        return rawPages.map((rawPage) => {
            const [pageNumStr, rawSegmentIds] = rawPage.split(":")
            const pageNum = parseInt(pageNumStr)
            const segmentIds = rawSegmentIds ? rawSegmentIds.split(";").map(Number) : []
            return { pageNum, segmentIds }
        })
    }

    // 从后端获取消息列表并刷新
    const fetchMessages = useCallback(async () => {
        if (fileID > 0) {
            try {
                const url = getAPIUrl(`/api/messages/file/${fileID}`)
                const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                const response = await fetchWithChecks(url, true, false, {
                    method: "GET",
                    headers: headers,
                })
                const data = await response.json()
                // 检查 data 是否包含 messages 属性，并且 messages 属性是否是一个数组
                if (data && Array.isArray(data.messages)) {
                    const parsedMessages: DSMessage[] = data.messages.map((message: MessageResponse) => ({
                        ...message,
                        time: new Date(message.time),
                        related_pages: parseRelatedPages(message.related_pages || []),
                    }))
                    // console.log("[fetchMessages] Fetch the latest messages for file: %d", fileID)
                    setMessages(parsedMessages)
                }
            } catch (error) {
                console.error("Failed to fetch messages:", error)
            }
        }
    }, [fileID, userToken, fetchWithChecks])

    // 关闭 WebSocket 连接
    const closeWebSocket = useCallback(
        (wsFileID: number, fetch: boolean = true) => {
            const ws = wsConnectionsRef.current.get(wsFileID)
            if (ws) {
                ws.close()
                wsConnectionsRef.current.delete(wsFileID)
                // console.log("[closeWebSocket] Close the websocket for file %d.", wsFileID)
            }

            if (fetch) {
                fetchMessages()
            }
        },
        [fetchMessages]
    )

    // 滚动消息区到底部
    useEffect(() => {
        if (messagesEndRef.current) {
            setTimeout(() => {
                if (messagesEndRef.current) {
                    messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
                }
            }, 0)
        }
    }, [messages, unfinishedMessages])

    useEffect(() => {
        if (fileID > 0) {
            // console.log("[useEffect(fileID, fetchMessages)] File changed to fileID: %d.", fileID)

            fetchMessages()
            setCurConversationID(null)
        } else {
            setMessages([])
        }
    }, [fileID, fetchMessages])

    // 监听 WebSocket 任务队列的变化，处理队列中的下一个任务
    useEffect(() => {
        if (wsTasks.length > 0) {
            let wsUrl = wsTasks[0]

            // 从 wsUrl 中解析出对应的 fileID
            const fileIDMatch = wsUrl.match(/\/(\d+)\/\d+(\.\d+)?/)
            const wsFileID = fileIDMatch ? Number(fileIDMatch[1]) : -1

            // 如果已经有一个WebSocket连接，复用它
            let ws = wsConnectionsRef.current.get(wsFileID)

            if (!ws) {
                // 创建新的 WebSocket 连接
                if (userToken) {
                    wsUrl += `?token=${userToken}`
                }
                ws = new WebSocket(wsUrl)
                wsConnectionsRef.current.set(wsFileID, ws)
                // console.log(
                //     "[useEffect(wsTasks, userToken, closeWebSocket] This file %d has no active stream, so we will create a new websocket and wsUrl is %s.",
                //     wsFileID,
                //     wsUrl
                // )
            } else {
                // console.log(
                //     "[useEffect(wsTasks, userToken, closeWebSocket] This file %d has an active stream, so we will re-use it.",
                //     wsFileID
                // )
            }

            let timeoutId: NodeJS.Timeout | null = null

            // 重新设置超时器
            const resetTimeout = () => {
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }

                timeoutId = setTimeout(() => {
                    closeWebSocket(wsFileID)
                }, 15000)
            }

            ws.onmessage = (event) => {
                if (typeof event.data !== "string") {
                    console.error("Received non-string data:", event.data)
                    return
                }

                const newContent = event.data
                // console.log(
                //     "[useEffect(wsTasks, userToken)(ws.onmessage)] Received new data from websocket: %s",
                //     newContent
                // )

                if (newContent === "[DONE]") {
                    // console.log(
                    //     "[useEffect(wsTasks, userToken)(ws.onmessage)] Received [DONE] data from websocket for file %d",
                    //     wsFileID
                    // )

                    // 清除超时器
                    if (timeoutId) {
                        clearTimeout(timeoutId)
                        timeoutId = null
                    }

                    // 如果接收到了 "[DONE]" 消息，需要清除未完结消息缓存，并关闭 WebSocket 连接。
                    setUnfinishedMessages((prevUnfinishedMessages) => {
                        const newUnfinishedMessages = new Map(prevUnfinishedMessages)
                        newUnfinishedMessages.set(wsFileID, "")
                        return newUnfinishedMessages
                    })
                    closeWebSocket(wsFileID)
                } else if (newContent === "[MORE]") {
                    // 清除超时器
                    if (timeoutId) {
                        clearTimeout(timeoutId)
                        timeoutId = null
                    }

                    // 如果接收到了 "[MORE]" 消息，仅关闭 WebSocket 连接。
                    closeWebSocket(wsFileID, false)
                } else if (newContent.startsWith("[ERROR]")) {
                    // console.log(
                    //     "[useEffect(wsTasks, userToken)(ws.onmessage)] Received [ERROR] data from websocket for file %d, the error message is: %s",
                    //     wsFileID,
                    //     newContent
                    // )
                    // 清除超时器
                    if (timeoutId) {
                        clearTimeout(timeoutId)
                        timeoutId = null
                    }

                    // 关闭 WebSocket 连接。
                    closeWebSocket(wsFileID, false)
                } else {
                    // console.log(
                    //     "[useEffect(wsTasks, userToken)(ws.onmessage)] Received data from websocket for file %d, the newContent is: %s",
                    //     wsFileID,
                    //     newContent
                    // )

                    setUnfinishedMessages((prevUnfinishedMessages) => {
                        const newUnfinishedMessages = new Map(prevUnfinishedMessages)
                        const previousContent = newUnfinishedMessages.get(wsFileID) || ""
                        newUnfinishedMessages.set(wsFileID, previousContent + newContent)
                        return newUnfinishedMessages
                    })

                    // 每次接收到新的数据，都重置超时器
                    resetTimeout()
                }
            }

            ws.onopen = () => {
                resetTimeout()
            }

            ws.onclose = () => {
                // console.log(
                //     "[useEffect(wsTasks, userToken, closeWebSocket)(ws.onclose)] Websocket connection closed, and tasks count is %d.",
                //     wsTasks.length
                // )

                // 移除已完成的任务，并处理下一个任务
                setWsTasks((prevWsTasks) => {
                    // 如果任务队列中没有剩余任务，设置按钮为可用
                    if (prevWsTasks.length <= 1) {
                        setSendButtonDisabled(false)
                    }
                    return prevWsTasks.slice(1)
                })
            }

            ws.onerror = (error) => {
                console.log("WebSocket error: ", error)
                closeWebSocket(wsFileID)
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
            }

            // 在组件卸载时关闭 WebSocket 连接
            return () => {
                // closeWebSocket(wsFileID)
                if (timeoutId) {
                    clearTimeout(timeoutId)
                }
            }
        }
    }, [wsTasks, userToken, closeWebSocket])

    useEffect(() => {
        // 提示语数组
        const placeholders = [
            t("chatUserHelpPrompt1"),
            t("chatUserHelpPrompt2"),
            t("chatUserHelpPrompt3"),
            t("chatUserHelpPrompt4"),
            t("chatUserHelpPrompt5"),
            t("chatUserHelpPrompt6"),
            t("chatUserHelpPrompt7"),
            t("chatUserHelpPrompt8"),
        ]
        if (inputValue.trim() === "") {
            const randomPlaceholder = placeholders[Math.floor(Math.random() * placeholders.length)]
            setPlaceholder(randomPlaceholder)
        }
    }, [inputValue, t])

    // 启动问答动作
    const onQuestionStarted = (questionText: string, replyOnly: boolean, conversationID: number | null) => {
        // 如果有 WebSocket 流，暂时不能执行问答动作。
        if (!wsConnectionsRef.current.get(fileID)) {
            startDirectMessage(questionText, replyOnly, conversationID)
        }
    }

    // 处理文件类别变化
    const handleFileCategoryChanged = (category: DSFileCategory) => {
        setCurFileCategory(category)
        fetchMessages()
    }

    useEffect(() => {
        setCurFileCategory(fileCategory)
    }, [fileCategory])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    // 直接发起会话，比如点击问题列表、重新回答动作等。
    const startDirectMessage = (question: string, replyOnly: boolean, conversationID: number | null) => {
        const formData = new URLSearchParams()
        formData.append("content", question)
        formData.append("reply_only", replyOnly.toString())
        if (conversationID) {
            formData.append("conversation_id", conversationID.toString())
        }

        // 先添加用户消息和助手的临时消息
        const tempUserMessage: DSMessage = {
            id: InternalMessageID.User,
            user_id: userID!,
            file_id: fileID,
            sender: DSMessageSender.User,
            category: DSMessageCategory.Normal,
            content: question,
            time: new Date(),
            related_pages: [],
            conversation_id: conversationID,
            next_actions: [],
        }
        const tempAssistantMessage: DSMessage = {
            id: InternalMessageID.Assistant,
            user_id: userID!,
            file_id: fileID,
            sender: DSMessageSender.Assistant,
            category: DSMessageCategory.Normal,
            content: "",
            time: new Date(),
            related_pages: [],
            conversation_id: conversationID,
            next_actions: [],
        }
        if (replyOnly) {
            setMessages((prevMessages) => [...prevMessages, tempAssistantMessage])
        } else {
            setMessages((prevMessages) => [...prevMessages, tempUserMessage, tempAssistantMessage])
        }

        // 设置发送按钮为不可用状态
        setSendButtonDisabled(true)

        let url = getAPIUrl(`/api/messages/file/${fileID}`)
        const headers: HeadersInit = userToken
            ? {
                  Authorization: userToken,
                  "Content-Type": "application/x-www-form-urlencoded",
              }
            : {}
        fetchWithChecks(url, true, true, {
            method: "POST",
            body: formData.toString(),
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.conversation_id) {
                    setCurConversationID(data.conversation_id)
                } else {
                    setCurConversationID(null)
                }

                // 如果服务器返回的 data.stream 为 true，那么开启 WebSocket 连接
                if (data.stream && data.stream_indexes && data.stream_indexes.length > 0) {
                    // 需要创建 WebSocket 连接，将任务添加到队列中。
                    const newTasks = data.stream_indexes.map((index: string) => {
                        return getWebSocketUrl(`/api/messages/ws/${fileID}/${index}`)
                    })
                    setWsTasks((prevTasks) => [...prevTasks, ...newTasks])
                    // setStreamActive(true)
                } else {
                    // 服务器返回消息，直接替换现有消息
                    setMessages(data.messages)
                    // 恢复发送按钮的状态
                    setSendButtonDisabled(false)
                }
            })
            .catch((error) => {
                console.error("Failed to send message:", error)
                // 如果出错，移除临时的助手消息，但不替换现有消息
                setMessages((prevMessages) =>
                    prevMessages.filter((message) => message.id !== InternalMessageID.Assistant)
                )
                // 恢复发送按钮的状态
                setSendButtonDisabled(false)
            })
    }

    // 处理发送按钮点击事件
    const handleSend = useCallback(() => {
        if (fileID <= 0 || inputValue.trim() === "") {
            return
        }

        const formData = new URLSearchParams()
        formData.append("content", inputValue)
        formData.append("reply_only", "false")
        if (curConversationID) {
            formData.append("conversation_id", curConversationID.toString())
        }

        // 先添加用户消息和助手的临时消息
        const tempUserMessage: DSMessage = {
            id: InternalMessageID.User,
            user_id: userID!,
            file_id: fileID,
            sender: DSMessageSender.User,
            category: DSMessageCategory.Normal,
            content: inputValue,
            time: new Date(),
            related_pages: [],
            conversation_id: null,
            next_actions: [],
        }
        const tempAssistantMessage: DSMessage = {
            id: InternalMessageID.Assistant,
            user_id: userID!,
            file_id: fileID,
            sender: DSMessageSender.Assistant,
            category: DSMessageCategory.Normal,
            content: "",
            time: new Date(),
            related_pages: [],
            conversation_id: null,
            next_actions: [],
        }
        setMessages((prevMessages) => [...prevMessages, tempUserMessage, tempAssistantMessage])

        // 设置发送按钮为不可用状态
        setSendButtonDisabled(true)
        setInputValue("")

        let url = getAPIUrl(`/api/messages/file/${fileID}`)
        const headers: HeadersInit = userToken
            ? {
                  Authorization: userToken,
                  "Content-Type": "application/x-www-form-urlencoded",
              }
            : {}
        fetchWithChecks(url, true, true, {
            method: "POST",
            body: formData.toString(),
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                // 移除临时的助手消息，并重置会话 ID。
                // setMessages((prevMessages) =>
                //     prevMessages.filter((message) => message.id !== InternalMessageID.Assistant)
                // )
                if (data.conversation_id) {
                    setCurConversationID(data.conversation_id)
                } else {
                    setCurConversationID(null)
                }

                // 如果服务器返回的 data.stream 为 true，那么开启 WebSocket 连接
                if (data.stream && data.stream_indexes && data.stream_indexes.length > 0) {
                    // 需要创建 WebSocket 连接，将任务添加到队列中。
                    const newTasks = data.stream_indexes.map((index: string) => {
                        return getWebSocketUrl(`/api/messages/ws/${fileID}/${index}`)
                    })
                    setWsTasks((prevTasks) => [...prevTasks, ...newTasks])
                    // setStreamActive(true)
                } else {
                    // 服务器返回消息，直接替换现有消息
                    setMessages(data.messages)
                    // 恢复发送按钮的状态
                    setSendButtonDisabled(false)
                }
            })
            .catch((error) => {
                console.error("Failed to send message:", error)
                // 如果出错，移除临时的助手消息，但不替换现有消息
                setMessages((prevMessages) =>
                    prevMessages.filter((message) => message.id !== InternalMessageID.Assistant)
                )
                // 恢复发送按钮的状态
                setSendButtonDisabled(false)
            })
    }, [fileID, inputValue, userID, userToken, curConversationID, fetchWithChecks])

    // 是否允许发送消息
    function isSendButtonDisabled() {
        return sendButtonDisabled || fileID <= 0 || inputValue.trim() === ""
    }

    return (
        <DSChatArea>
            <DSChatContent ref={messagesEndRef}>
                {messages &&
                    (wsConnectionsRef.current.get(fileID)
                        ? messages
                              // 有活动的 WebSocket 连接，需要渲染临时消息。
                              .sort((a, b) => {
                                  if (a.id === InternalMessageID.Assistant) return 1
                                  if (b.id === InternalMessageID.Assistant) return -1
                                  return 0
                              })
                              // 渲染排序后的消息列表
                              .map((message, index) => {
                                  return message.id !== InternalMessageID.Assistant ? (
                                      <MessageCell
                                          key={index}
                                          message={message}
                                          streamActive={false}
                                          unfinishedMessage=""
                                          fileCategory={curFileCategory}
                                          onRelatedPageClicked={onRelatedPageClicked}
                                          onActionCompleted={fetchMessages}
                                          onQuestionStarted={onQuestionStarted}
                                          onFileCategoryChanged={handleFileCategoryChanged}
                                          onMessageChanged={fetchMessages}
                                      />
                                  ) : (
                                      <MessageCell
                                          key={index}
                                          message={message}
                                          streamActive={true}
                                          unfinishedMessage={unfinishedMessages.get(fileID) || ""}
                                          fileCategory={curFileCategory}
                                          onRelatedPageClicked={onRelatedPageClicked}
                                          onActionCompleted={() => {}}
                                          onQuestionStarted={onQuestionStarted}
                                          onFileCategoryChanged={handleFileCategoryChanged}
                                          onMessageChanged={() => {}}
                                      />
                                  )
                              })
                        : messages.map((message, index) => (
                              // 无活动的 WebSocket 连接，直接渲染所有 MessageCell，无需进行其他判断
                              <MessageCell
                                  key={index}
                                  message={message}
                                  streamActive={false}
                                  unfinishedMessage=""
                                  fileCategory={curFileCategory}
                                  onRelatedPageClicked={onRelatedPageClicked}
                                  onActionCompleted={fetchMessages}
                                  onQuestionStarted={onQuestionStarted}
                                  onFileCategoryChanged={handleFileCategoryChanged}
                                  onMessageChanged={fetchMessages}
                              />
                          )))}
            </DSChatContent>
            <DSChatInputContainer>
                <DSChatInput
                    type="text"
                    className={`${i18n.language === "cn" ? "chinese" : ""}`}
                    value={inputValue}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === "Enter" && !isSendButtonDisabled()) {
                            handleSend()
                        }
                    }}
                />
                <DSSendButton onClick={handleSend} disabled={isSendButtonDisabled()}>
                    {t("send")}
                </DSSendButton>
            </DSChatInputContainer>
        </DSChatArea>
    )
}

export default ChatArea

const DSChatArea = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: rgb(68, 70, 84);
    display: flex;
    flex-direction: column;
`

// 减去聊天输入框和发送按钮的高度
const DSChatContent = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100% - 50px);
`

const DSChatInputContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: rgb(68, 70, 84);
`

const DSChatInput = styled.input`
    flex-grow: 1;
    height: 44px;
    padding: 12px 16px;
    border: none;
    border-radius: 3px;
    background-color: #fff;
    font-size: 16px;
    box-sizing: border-box;

    &::placeholder {
        font-style: italic;
    }

    &.chinese::placeholder {
        font-style: normal;
    }
`

const DSSendButton = styled.button`
    margin-left: 16px;
    width: 80px;
    height: 44px;
    padding: 0 10px;
    border: none;
    border-radius: 3px;
    background-color: #4a90e2;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    &:disabled {
        background-color: grey;
    }
`
