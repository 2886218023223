import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { ActionMeta, StylesConfig } from "react-select"
import AsyncSelect from "react-select/async"
import { DSUserFileOutline } from "../../shared/file"
import { sprintf } from "sprintf-js"
import { getAPIUrl } from "../../shared/function"
import { useUser } from "../../shared/user-context"
import { DSBaseModal, DSBaseModalProps } from "./base-modal"

interface SummarizeChaptersModalProps {
    // 对话框显示/隐藏开关
    isOpen: boolean
    // 当前文件 ID
    fileID: number
    // 深度使用页面数剩余配额
    remainingQuota: number
    // 总结动作确认
    onActionConfirmed: (oID: number, pageCount: number) => void
    // 对话框关闭请求
    onRequestClose: () => void
}

const SummarizeChaptersModal: React.FC<SummarizeChaptersModalProps> = ({
    isOpen,
    fileID,
    remainingQuota,
    onActionConfirmed,
    onRequestClose,
}) => {
    const { userToken, fetchWithChecks } = useUser()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [outlines, setOutlines] = useState<DSUserFileOutline[]>([])
    const [selectedOutline, setSelectedOutline] = useState<DSUserFileOutline | null>(null)
    const [costPrompt, setCostPrompt] = useState<string>("")
    const [needBuyMore, setNeedBuyMore] = useState<boolean>(false)

    // 用于存储 option 数据
    const [optionData, setOptionData] = useState<DSNumberSelectOption[]>([])

    // 监听 fileID 变化
    useEffect(() => {
        const fetchOutlinesData = async (): Promise<void> => {
            // 调用 API 获取章节数据
            const urlChangeCategory = getAPIUrl(`/api/files/outline/${fileID}`)
            const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
            try {
                const response = await fetchWithChecks(urlChangeCategory, true, false, {
                    method: "GET",
                    headers: headers,
                })
                if (response.ok) {
                    const data = await response.json()
                    if (data && Array.isArray(data.outlines)) {
                        setOutlines(data.outlines)

                        const options: DSNumberSelectOption[] = data.outlines.map((outline: DSUserFileOutline) => ({
                            value: outline.id,
                            label: outline.title,
                        }))
                        setOptionData(options)

                        if (data.outlines.length > 0) {
                            setSelectedOutline(data.outlines[0])
                        }
                    }
                }
            } catch (err) {
                console.error("Failed to fetch the outlines data:", err)
            }
        }

        fetchOutlinesData()
    }, [fileID, userToken, fetchWithChecks])

    useEffect(() => {
        if (selectedOutline) {
            let prompt = ""
            if (selectedOutline.page_count > 1) {
                prompt = sprintf(
                    t("promptForSummarizeChaptersCost"),
                    t("fullUsePage"),
                    selectedOutline.page_count,
                    remainingQuota,
                    selectedOutline.start_page_num,
                    selectedOutline.start_page_num + selectedOutline.page_count - 1
                )
            } else {
                prompt = sprintf(
                    t("promptForSummarizeOnePageChaptersCost"),
                    t("fullUsePage"),
                    selectedOutline.page_count,
                    remainingQuota,
                    selectedOutline.start_page_num
                )
            }

            setCostPrompt(prompt)
            setNeedBuyMore(remainingQuota < selectedOutline.page_count)
        }
    }, [selectedOutline, t, remainingQuota])

    // 处理 Select 控件的值改变事件
    const handleSelectChange = (option: DSNumberSelectOption | null, actionMeta: ActionMeta<DSNumberSelectOption>) => {
        const selectedID = option ? option.value : 0
        const selected = outlines.find((outline) => outline.id === selectedID)
        setSelectedOutline(selected || null)
    }

    // 处理点击“确认”按钮的事件
    const onConfirmButtonClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onRequestClose()

        if (selectedOutline) {
            onActionConfirmed(selectedOutline.id, selectedOutline.page_count)
        }
    }

    const customStyles: StylesConfig<DSNumberSelectOption, false> = {
        option: (provided, state) => ({
            ...provided,
            fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif",
            color: state.isSelected ? "blue" : "black",
            backgroundColor: state.isFocused ? "lightgrey" : "white",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }

    return (
        <DSSummarizeChaptersModal isOpen={isOpen} title={t("bookSummarizeChapters")} onRequestClose={onRequestClose}>
            <DSBaseModal.Body>
                <DSSummarizeChapterBody>
                    <DSSummarizeChaptersPrompt>
                        <p>
                            <span>{t("promptForSelectChapters")}</span>
                        </p>
                    </DSSummarizeChaptersPrompt>
                    <DSOutlineSelect
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation()
                        }}
                    >
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            value={selectedOutline ? { value: selectedOutline.id, label: selectedOutline.title } : null}
                            loadOptions={() => Promise.resolve(optionData)}
                            onChange={handleSelectChange}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                        />
                    </DSOutlineSelect>
                    <DSSummarizeChaptersPrompt>
                        <p>
                            <span>{costPrompt}</span>
                        </p>
                    </DSSummarizeChaptersPrompt>
                </DSSummarizeChapterBody>
            </DSBaseModal.Body>
            <DSBaseModal.Footer>
                <button
                    className="modal-button"
                    onClick={(event) => {
                        event.stopPropagation()
                        onRequestClose()
                    }}
                >
                    {t("cancel")}
                </button>
                {needBuyMore ? (
                    <button className="modal-button yes-button" onClick={() => navigate("/buy")}>
                        {t("buy")}
                    </button>
                ) : (
                    <button className="modal-button yes-button" onClick={onConfirmButtonClicked}>
                        {t("confirm")}
                    </button>
                )}
            </DSBaseModal.Footer>
        </DSSummarizeChaptersModal>
    )
}

export default SummarizeChaptersModal

const DSSummarizeChaptersModal = styled(DSBaseModal)<DSBaseModalProps>`
    position: fixed;
    width: 40vw;
    height: 30vh;
    max-width: 800px;
    min-width: 500px;
    max-height: 450px;
    min-height: 400px;
`

const DSSummarizeChaptersPrompt = styled.div`
    line-height: 1.6;
    padding-right: 50px;
`

const DSOutlineSelect = styled.div`
    margin: 1em 0;

    & select {
        height: 40px;
        padding: 0 15px;
        border-radius: 5px;
        color: black;
    }
`

const DSSummarizeChapterBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
