import React, { useState, useEffect } from "react"
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useUser } from "../shared/user-context"
import i18n from "../shared/i18n"
import { useTranslation } from "react-i18next"
import { hostProvider } from "../shared/config"
import { dsToastError, getAPIUrl } from "../shared/function"
import DSPageFooter from "../components/ds-page-footer"
import { DSCssSize } from "../shared/css-constants"
import { DSUserProfileResponse } from "../shared/basic-type"

interface SignInPageProps {}

const SignInPage: React.FC<SignInPageProps> = () => {
    const { userID, setUserID, setUserEmail, setUserToken, setLanguage, setUserAvatar, fetchWithChecks } = useUser()
    const { t } = useTranslation()
    const navigate = useNavigate()
    let location = useLocation()

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [emailError, setEmailError] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    const handleResetPasswordClick = () => {
        navigate("/reset-password", { state: { email } })
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        try {
            const url = getAPIUrl(`/api/auth/login`)
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            })

            if (response.ok) {
                const data = await response.json()
                setUserEmail(data.user.email)
                setUserToken(data.token)
                setLanguage(data.language)
                setErrorMessage(null)
                setUserID(data.user.id)
            } else {
                const errorData = await response.json() // Attempt to get error message from response
                const errorText = errorData.error || response.statusText
                setErrorMessage(errorText)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 处理 Google 帐号登录
    const handleGoogleLoginClick = async () => {
        try {
            const url = getAPIUrl(`/api/auth/google/login`)
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (response.ok) {
                const data = await response.json()
                window.location.href = data.auth_url
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        let initialLanguage = "en" // 默认语言
        const browserLanguage = navigator.language
        if (browserLanguage.startsWith("zh")) {
            initialLanguage = "cn"
        }
        // 然后你可以把 initialLanguage 作为初始语言
        i18n.changeLanguage(initialLanguage)
    }, [])

    // 响应 Google 帐号登录后重定向参数的解析。
    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const userID = query.get("id")
        const token = query.get("token")

        if (userID && token) {
            const parsedUserID = Number(userID)
            if (isNaN(parsedUserID)) {
                console.error("Invalid user ID")
                navigate("/login", { replace: true })
                return
            }

            const url = getAPIUrl(`/api/auth/profile`)
            const headers: HeadersInit = token ? { Authorization: token } : {}
            fetchWithChecks(url, true, false, {
                method: "GET",
                headers,
            })
                .then((response) => response.json())
                .then((data: DSUserProfileResponse) => {
                    setUserToken(token)
                    setUserEmail(data.profile.email)
                    setLanguage(data.profile.language)
                    if (data.profile.avatar_url) {
                        setUserAvatar(data.profile.avatar_url)
                    }
                    setUserID(parsedUserID)
                })
                .catch((error) => {
                    console.error(error)
                    navigate("/login", { replace: true })
                })
        } else {
            const error = query.get("error")
            if (error) {
                dsToastError(error)
                navigate("/login", { replace: true })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return !userID ? (
        <SignInPageConainer>
            <SignInPageWrapper>
                <SignInPageCard>
                    <img src="/logo-landscape.png" alt="DocuSpeed" />
                    <form onSubmit={handleSubmit}>
                        <SignInPageInput
                            className={`${emailError ? "input-error" : ""}`}
                            type="email"
                            placeholder={t("email", "Email")!}
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setEmailError(false)
                                setEmail(e.target.value)
                                setIsButtonDisabled(e.target.value === "")
                            }}
                        />
                        <SignInPageInput
                            className={`${passwordError ? "input-error" : ""}`}
                            type="password"
                            placeholder={t("password", "Password")!}
                            value={password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setPasswordError(false)
                                setPassword(e.target.value)
                            }}
                        />
                        {errorMessage && <SignInPageErrorMessage>{errorMessage}</SignInPageErrorMessage>}
                        {isButtonDisabled ? (
                            <SignInPageButtonDisabled type="submit" disabled>
                                {t("login")}
                            </SignInPageButtonDisabled>
                        ) : (
                            <SignInPageButton type="submit">{t("login")}</SignInPageButton>
                        )}
                    </form>
                    <SignInPageOtherAction>
                        <LinkLikeSpan onClick={handleResetPasswordClick}>{t("resetPasswordLink")}</LinkLikeSpan>
                        &nbsp;&nbsp;
                        <Link to="/signup">{t("signupLink")}</Link>
                    </SignInPageOtherAction>
                    {hostProvider === "aliyun" ? null : <DividerWithText>{t("loginDividerOr")}</DividerWithText>}
                    {hostProvider === "aliyun" ? null : (
                        <GoogleLoginButton onClick={handleGoogleLoginClick}>
                            <GoogleIcon src="/google/google-logo.png" alt="Google Logo" />
                            <GoogleLoginButtonText>{t("signInWithGoogle")}</GoogleLoginButtonText>
                        </GoogleLoginButton>
                    )}
                </SignInPageCard>
            </SignInPageWrapper>
            <DSPageFooter showVersion={true} />
        </SignInPageConainer>
    ) : (
        <Navigate to="/app" replace={true} />
    )
}

export default SignInPage

const SignInPageConainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const SignInPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 500px;
    max-width: 100%;
`

const SignInPageErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SignInPageInput = styled.input`
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    border: 1px solid gray;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 6px 8px;

    &:focus {
        border-color: blue;
    }

    & .input-error {
        border-color: red;
    }

    &:last-of-type {
        margin-bottom: 30px;
    }

    & + ${SignInPageErrorMessage} {
        margin-top: -30px;
    }
`

const SignInPageCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;

    & > img {
        max-width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    & > form {
        width: 70%;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        & > img {
            margin-bottom: 30px;
        }
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        & > img {
            margin-bottom: 10px;
        }
    }

    @media (max-height: ${DSCssSize.LandscapeMaxHeight}px) {
        & > img {
            margin-bottom: 10px;
        }
    }
`

const SignInPageButton = styled.button`
    width: 100%;
    height: 48px;
    color: white;
    background-color: #0052cc;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #0052cc;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
`

const SignInPageButtonDisabled = styled(SignInPageButton)`
    background-color: lightslategray;
    border-color: lightslategray;
    cursor: not-allowed;
`

const SignInPageOtherAction = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.6;
    margin: 10px 0;

    & a:first-child {
        margin-right: 15px;
    }

    & a:visited {
        color: blue;
    }
`

const LinkLikeSpan = styled.div`
    color: blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: blue;
    }
`

const DividerWithText = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    color: gray;
    margin: 20px 0 30px;

    &::before,
    &::after {
        content: "";
        width: 100px;
        flex: 1;
        border-bottom: 1px solid gray;
    }

    &::before {
        margin-right: 0.5em;
    }

    &::after {
        margin-left: 0.5em;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        margin: 10px 0 20px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        margin: 20px 0 30px;
    }
`

const GoogleLoginButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 48px;
    background-color: white;
    border: 1px solid rgb(193, 199, 208);
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.54);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    cursor: pointer;
    outline: none;

    &:hover {
        background-color: rgba(9, 30, 66, 0.02) !important;
    }

    @media (max-width: 600px) {
        width: 280px;
        height: 40px;
    }
`

const GoogleIcon = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 24px;
`

const GoogleLoginButtonText = styled.div`
    text-align: center;
`
