import React, { RefObject } from "react"
import { useNavigate } from "react-router-dom"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import HomePageButton from "./button"
import { hostProvider } from "../../shared/config"
import { DSCssFontSize, DSCssSize } from "../../shared/css-constants"

interface HomePageContentSectionProps {
    featureRef: RefObject<HTMLDivElement>
    faqRef: RefObject<HTMLDivElement>
}

const HomePageContentSection: React.FC<HomePageContentSectionProps> = ({ featureRef, faqRef }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleSignIn = () => {
        navigate("/app")
    }

    return (
        <ContentWrapper>
            {/* Section 1 - 功能列表 */}
            <Section ref={featureRef}>
                <SectionTitle1>{t("sectionTitleFeatures")}</SectionTitle1>
                <SectionList>
                    <SectionListCell>
                        <SectionIcon $size={64}>
                            <img src={"/site/feature-1.webp"} alt="Summarization" />
                        </SectionIcon>
                        <SectionTitle3>{t("sectionTitleSummrization")}</SectionTitle3>
                        <SectionText>{t("sectionDescriptionSummrization")}</SectionText>
                    </SectionListCell>
                    <SectionListCell>
                        <SectionIcon $size={64}>
                            <img src={"/site/feature-2.webp"} alt="Q&A" />
                        </SectionIcon>
                        <SectionTitle3>{t("sectionTitleQandA")}</SectionTitle3>
                        <SectionText>{t("sectionDescriptionQandA")}</SectionText>
                    </SectionListCell>
                    <SectionListCell>
                        <SectionIcon $size={64}>
                            <img src={"/site/feature-3.webp"} alt="Translation" />
                        </SectionIcon>
                        <SectionTitle3>{t("sectionTitleTranslation")}</SectionTitle3>
                        <SectionText>{t("sectionDescriptionTranslation")}</SectionText>
                    </SectionListCell>
                </SectionList>
            </Section>
            {/* Section 2 - 应用场景 */}
            <Section $backgroundColor="#e6f1ff">
                <SectionTitle1>{t("sectionTitleScenarios")}</SectionTitle1>
                <SectionBanner>
                    <SectionBannerImage>
                        <img
                            src={hostProvider === "aliyun" ? "/site/scenario-1-cn.webp" : "/site/scenario-1.webp"}
                            alt="Scenario"
                        />
                    </SectionBannerImage>
                    <SectionBannerText>
                        <SectionTitle3>{t("sectionTitleScenariosPaper")}</SectionTitle3>
                        <SectionText>{t("sectionDescriptionScenariosPaper")}</SectionText>
                        <HomePageButton onClick={handleSignIn}>{t("freeTrial")}</HomePageButton>
                    </SectionBannerText>
                </SectionBanner>
                <SectionBanner>
                    <SectionBannerText>
                        <SectionTitle3>{t("sectionTitleScenariosBook")}</SectionTitle3>
                        <SectionText>{t("sectionDescriptionScenariosBook")}</SectionText>
                        <HomePageButton onClick={handleSignIn}>{t("freeTrial")}</HomePageButton>
                    </SectionBannerText>
                    <SectionBannerImage>
                        <img
                            src={hostProvider === "aliyun" ? "/site/scenario-2-cn.webp" : "/site/scenario-2.webp"}
                            alt="Scenario"
                        />
                    </SectionBannerImage>
                </SectionBanner>
            </Section>
            {/* Section 3 - FAQ */}
            <Section ref={faqRef}>
                <SectionTitle1>{t("sectionTitleHelp")}</SectionTitle1>
                <SectionList>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/pulse-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>
                            {hostProvider === "aliyun" ? t("sectionHelpQuestion2") : t("sectionHelpQuestion1")}
                        </SectionTitle4>
                        <SectionText>
                            {hostProvider === "aliyun" ? t("sectionHelpAnswer2") : t("sectionHelpAnswer1")}
                        </SectionText>
                    </SectionListCell>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/media-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>{t("sectionHelpQuestion3")}</SectionTitle4>
                        <SectionText>{t("sectionHelpAnswer3")}</SectionText>
                    </SectionListCell>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/cloud-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>{t("sectionHelpQuestion4")}</SectionTitle4>
                        <SectionText>{t("sectionHelpAnswer4")}</SectionText>
                    </SectionListCell>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/zap-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>{t("sectionHelpQuestion5")}</SectionTitle4>
                        <SectionText>{t("sectionHelpAnswer5")}</SectionText>
                    </SectionListCell>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/heart-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>{t("sectionHelpQuestion6")}</SectionTitle4>
                        <SectionText>{t("sectionHelpAnswer6")}</SectionText>
                    </SectionListCell>
                    <SectionListCell $align="flex-start">
                        <SectionIcon $size={24}>
                            <img src={"/site/umbrella-outline.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle4>
                            {hostProvider === "aliyun" ? t("sectionHelpQuestion8") : t("sectionHelpQuestion7")}
                        </SectionTitle4>
                        <SectionText>
                            {hostProvider === "aliyun" ? t("sectionHelpAnswer8") : t("sectionHelpAnswer7")}
                        </SectionText>
                    </SectionListCell>
                </SectionList>
            </Section>
            {/* Section 4 - 亮点 */}
            <Section $backgroundColor="#004ca3">
                <SectionBanner>
                    <SectionBannerText $color="#eff6ff">
                        <SectionIcon $size={32}>
                            <img src={"/site/cloud-hexagon-filled.webp"} alt="Summarize" />
                        </SectionIcon>
                        <SectionTitle1>{t("sectionSpotTitle")}</SectionTitle1>
                        <SectionText>{t("sectionSpotDescription")}</SectionText>
                        <SectionList>
                            <SectionListCompactCell $align="flex-start">
                                <SectionTitle2 $marginBottom={0}>{`3 ${t("minutes")}`}</SectionTitle2>
                                <SectionTitle4>{t("readPaper")}</SectionTitle4>
                            </SectionListCompactCell>
                            <SectionListCompactCell $align="flex-start">
                                <SectionTitle2 $marginBottom={0}>{`30 ${t("minutes")}`}</SectionTitle2>
                                <SectionTitle4>{t("readBook")}</SectionTitle4>
                            </SectionListCompactCell>
                        </SectionList>
                        <HomePageButton onClick={handleSignIn}>{t("letStart")}</HomePageButton>
                    </SectionBannerText>
                    <SectionBannerImage>
                        <img src="/site/silly-runner.webp" alt="Scenario" />
                    </SectionBannerImage>
                </SectionBanner>
            </Section>
        </ContentWrapper>
    )
}

export default HomePageContentSection

const ContentWrapper = styled.div`
    flex: 1;
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    margin: 0 auto;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        padding: 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 16px;
    }
`

const Section = styled.div<{ $backgroundColor?: string }>`
    width: ${DSCssSize.DesktopMaxWidth}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    margin: 0 auto;
    background-color: ${(props) => props.$backgroundColor || "transparent"};
    border-radius: 24px;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        width: 100%;
        padding: 32px 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 32px 16px;
    }
`

const SectionTitle1 = styled.div`
    font-size: ${DSCssFontSize.H1};
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 32px;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        font-size: ${DSCssFontSize.H2};
        margin-bottom: 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H3};
        margin-bottom: 16px;
    }
`

const SectionTitle2 = styled.div<{ $marginBottom: number }>`
    font-size: ${DSCssFontSize.H2};
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: ${(props) => `${props.$marginBottom}px`};

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        font-size: ${DSCssFontSize.H3};
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H4};
    }
`

const SectionTitle3 = styled.div`
    font-size: ${DSCssFontSize.H3};
    line-height: 1.2;
    margin-bottom: 16px;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        font-size: ${DSCssFontSize.H4};
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H5};
    }
`

const SectionTitle4 = styled.div`
    font-size: ${DSCssFontSize.H4};
    line-height: 1.2;
    margin-bottom: 16px;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        font-size: ${DSCssFontSize.H5};
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H5};
    }
`

const SectionText = styled.div`
    font-family: "Lato", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
    font-size: ${DSCssFontSize.MainBody};
    line-height: 1.6;
    margin-bottom: 16px;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
    }
`

const SectionList = styled.ul`
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 32px 0;
    padding: 0;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        margin: 16px 0;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        margin: 0;
    }
`

const SectionListCell = styled.li<{ $align?: string }>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.$align || "center"};
    justify-content: center;
    padding: 16px 32px;
    box-sizing: border-box;
    width: 33.3333%;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        padding: 16px 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 16px 0;
    }
`

const SectionListCompactCell = styled.li<{ $align?: string }>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.$align || "center"};
    justify-content: center;
    line-height: 1.2;
    padding-right: 32px;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        padding-right: 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        padding-right: 16px;
    }
`

const SectionIcon = styled.div<{ $size: number }>`
    margin-bottom: 16px;

    & img {
        width: ${(props) => `${props.$size}px`};
        height: ${(props) => `${props.$size}px`};
    }
`

const SectionBanner = styled.div`
    width: 1280px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 64px;
    box-sizing: border-box;
    gap: 32px;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        width: 100%;
        padding: 32px 16px;
        gap: 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        flex-direction: column;
        padding: 32px 0;
        gap: 16px;
    }
`

const SectionBannerImage = styled.div`
    width: 50%;

    & img {
        height: 400px;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        & img {
            height: 250px;
        }
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;

        & img {
            width: 100%;
            height: auto;
        }
    }
`

const SectionBannerText = styled.div<{ $color?: string }>`
    width: 50%;
    font-size: 2.5rem;
    line-height: 1.6;

    ${(props) =>
        props.$color &&
        css`
            color: ${props.$color};
        `}

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
    }
`
