import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { DSFileCategory, FileCategoryRes } from "../../shared/file"
import { sprintf } from "sprintf-js"
import { dsToastSuccess, getAPIUrl } from "../../shared/function"
import { useUser } from "../../shared/user-context"
import { DSBaseModal, DSBaseModalProps } from "./base-modal"
import Select, { ActionMeta, StylesConfig } from "react-select"

interface ChangeCategoryModalProps {
    isOpen: boolean
    fileID: number
    category: DSFileCategory
    onCategoryChanged: (category: DSFileCategory) => void
    onRequestClose: () => void
}

const ChangeCategoryModal: React.FC<ChangeCategoryModalProps> = ({
    isOpen,
    fileID,
    category,
    onCategoryChanged,
    onRequestClose,
}) => {
    const { userToken, fetchWithChecks } = useUser()
    const { t } = useTranslation()

    const [selectedCategory, setSelectedCategory] = useState<DSFileCategory>(category)
    const [prevChangedCategory, setPrevChangedCategory] = useState<DSFileCategory>(category)

    // 监听 category 变化
    useEffect(() => {
        setSelectedCategory(category)
        setPrevChangedCategory(category)
    }, [category])

    const categoryOptions: DSStringSelectOption[] = Object.values(DSFileCategory).map((category) => {
        return {
            value: category,
            label: t(FileCategoryRes[category]),
        }
    })

    const customStyles: StylesConfig<DSStringSelectOption, false> = {
        valueContainer: (base) => ({
            ...base,
            width: 250,
            height: 40,
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif",
            color: state.isSelected ? "blue" : "black",
            backgroundColor: state.isFocused ? "lightgrey" : "white",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }

    // 处理 Select 控件的值改变事件
    const handleCategoryChange = (option: DSStringSelectOption | null, actionMeta: ActionMeta<DSStringSelectOption>) => {
        const newCategory = option ? option.value : DSFileCategory.Other
        setSelectedCategory(newCategory as DSFileCategory)
    }    

    // 处理点击“确认”按钮的事件
    const onConfirmButtonClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        // 调用 API 更改文件分类
        const urlChangeCategory = getAPIUrl(`/api/files/category/${fileID}`)
        const headers: HeadersInit = userToken
            ? { Authorization: userToken, "Content-Type": "application/x-www-form-urlencoded" }
            : {}

        const apiParams = new URLSearchParams()
        apiParams.append("category", selectedCategory)

        try {
            const response = await fetchWithChecks(urlChangeCategory, true, false, {
                method: "POST",
                headers: headers,
                body: apiParams.toString(),
            })
            if (response.ok) {
                onCategoryChanged(selectedCategory)
                setPrevChangedCategory(selectedCategory)

                console.log("We will toggle a toast!")
                dsToastSuccess(t("toastCategoryChanged") + t(FileCategoryRes[selectedCategory]))
            }
        } catch (err) {
            console.error("Failed to execute the action:", err)
        }
    }

    return (
        <DSChangeCategoryModal isOpen={isOpen} title={t("changeDocCategory")} onRequestClose={onRequestClose}>
            <DSBaseModal.Body>
                <DSChangeCategoryPrompt>
                    <p>
                        <span>{sprintf(t("promptChangeCategory"), t(FileCategoryRes[prevChangedCategory]))}</span>
                    </p>
                </DSChangeCategoryPrompt>
                <DSChangeCategorySelect>
                    <Select
                        defaultValue={categoryOptions.find(option => option.value === prevChangedCategory)}
                        options={categoryOptions}
                        onChange={handleCategoryChange}
                        styles={customStyles}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                    />
                </DSChangeCategorySelect>
            </DSBaseModal.Body>
            <DSBaseModal.Footer>
                <button className="modal-button" onClick={onRequestClose}>
                    {t("cancel")}
                </button>
                <button className="modal-button yes-button" onClick={onConfirmButtonClicked}>
                    {t("confirm")}
                </button>
            </DSBaseModal.Footer>
        </DSChangeCategoryModal>
    )
}

export default ChangeCategoryModal

const DSChangeCategoryModal = styled(DSBaseModal)<DSBaseModalProps>`
    position: fixed;
    width: 40vw;
    height: 25vh;
    max-width: 800px;
    min-width: 600px;
    max-height: 450px;
    min-height: 300px;
`

const DSChangeCategoryPrompt = styled.div`
    flex: 1;
    line-height: 1.6;
    padding-right: 50px;
`

const DSChangeCategorySelect = styled.div`
    margin: 1em 0;

    & select {
        width: 250px;
        height: 40px;
        padding: 0 15px;
        border-radius: 5px;
    }
`
