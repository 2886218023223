// 消息关联动作的 Code
export enum DSHttpErrorCode {
    Unauthorized = 401,
    PermissionDenied = 460,
    UploadPagePermissionDenied = 461,
    QuestionPermissionDenied = 462,
    FullUsePagePermissionDenied = 463,
}

// 语言内部代码
export enum DSLanguageCode {
    English = 1,
    SimplifiedChinese = 2,
}

// 语言名字，采用 ISO 639-1 标准，目前可取值：en | zh | ja | fr | de etc.
export const DSLanguageNames = {
    [DSLanguageCode.English]: "en",
    [DSLanguageCode.SimplifiedChinese]: "zh",
}

// 国家代码，采用 ISO 3166-1 alpha-2 标准，比如 CN/US 等
export enum DSCountryCode {
    China = "CN",
    USA = "US",
    Japan = "JP",
}

// Discord 频道邀请链接
export const DiscordChannelInvitations = {
    HelpEnglish: "https://discord.gg/DUc66rtcNk",
    HelpChinese: "https://discord.gg/AbYFDn5vRd",
    ChatEnglish: "https://discord.gg/Wg9eRg5JTH",
    ChatChinese: "https://discord.gg/jMAmXvdtMq",
}

// 支持邮箱地址
export const SupportMail = {
    China: "support@docuspeed.cn",
    USA: "support@docuspeed.ai"
}
