import React, { useEffect } from "react"
import styled from "styled-components"
import i18n from "../shared/i18n"
import { useTranslation } from "react-i18next"
import HomePageHeader from "../components/home/header"
import HomePageFooter from "../components/home/footer"
import { DSCssFontSize, DSCssSize } from "../shared/css-constants"

const AboutPage: React.FC = () => {
    const { t } = useTranslation()

    // 根据浏览器语言设置进行调整。
    useEffect(() => {
        let initialLanguage = "en" // 默认语言
        const browserLanguage = navigator.language
        if (browserLanguage.startsWith("zh")) {
            initialLanguage = "cn"
        }
        // 然后你可以把 initialLanguage 作为初始语言
        i18n.changeLanguage(initialLanguage)
    }, [])

    return (
        <AboutPageWrapper>
            <HomePageHeader isHome={false} />
            <AboutPageBody>
                <AboutPageBanner>
                    <img src={"/site/about-us-hero.webp"} alt="Meledo" />
                </AboutPageBanner>
                <AboutPageContent>
                    <Section>
                        <SectionTitle>{t("aboutUs")}</SectionTitle>
                        <SectionText>
                            <p>{t("sectionAboutUsParagraph1")}</p>
                            <p>{t("sectionAboutUsParagraph2")}</p>
                            <p>{t("sectionAboutUsParagraph3")}</p>
                            <p><span>{t("emailTo")}&nbsp;</span><a href="mailto:support@docuspeed.ai">support@docuspeed.ai</a></p>
                        </SectionText>
                    </Section>
                </AboutPageContent>
            </AboutPageBody>
            <HomePageFooter />
        </AboutPageWrapper>
    )
}

export default AboutPage

const AboutPageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eff6ff;
    color: #004ca3;
    box-sizing: border-box;
`

const AboutPageBody = styled.div`
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        padding: 0 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
    }
`

const AboutPageBanner = styled.div`
    margin: 32px 0;

    & img {
        width: 100%;
        border-radius: 24px;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        margin: 16px 16px;
    }
    
    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        margin: 16px 16px;
    }    
`

const AboutPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 32px 0;
    box-sizing: border-box;
    overflow: hidden;

    & img {
        width: 700px;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        margin: 16px 0;
    }
    
    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        margin: 16px 0;
    }    
`

const Section = styled.div`
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 32px 0;
    margin: 0 auto;
    border-radius: 24px;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        padding: 32px 16px;        
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 32px 16px;
    }    
`

const SectionTitle = styled.div`
    font-size: ${DSCssFontSize.H1};
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 32px;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        font-size: ${DSCssFontSize.H2};
        margin-bottom: 16px;
    }    

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H3};
        margin-bottom: 16px;
    }    
`

const SectionText = styled.div`
    font-family: "Lato", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
    font-size: ${DSCssFontSize.MainBody};
    line-height: 1.6;
    margin-bottom: 16px;
`