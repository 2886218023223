import React from "react"
import styled from "styled-components"
import Modal from "react-modal"

export interface DSBaseModalProps {
    isOpen: boolean
    title: string
    onRequestClose: () => void
    className?: string
    children?: React.ReactNode
}

interface DSModalBodyProps {
    children?: React.ReactNode
}

interface DSModalFooterProps {
    children?: React.ReactNode
}

const DSModalBody: React.FC<DSModalBodyProps> = ({ children }) => <ModalBody>{children}</ModalBody>

const DSModalFooter: React.FC<DSModalFooterProps> = ({ children }) => <ModalFooter>{children}</ModalFooter>

export const DSBaseModal: React.FC<DSBaseModalProps> & {
    Body: React.FC<DSModalBodyProps>
    Footer: React.FC<DSModalFooterProps>
} = ({ isOpen, title, onRequestClose, className, children }) => {
    return (
        <StyledBaseModal
            isOpen={isOpen}
            className={className}
            onRequestClose={onRequestClose}
            overlayClassName="modal-overlay"
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <ModalHeader>
                <h3>{title}</h3>
                <button
                    onClick={(event) => {
                        event.stopPropagation()
                        onRequestClose()
                    }}
                >
                    <i className="fas fa-window-close"></i>
                </button>
            </ModalHeader>
            {children}
        </StyledBaseModal>
    )
}

DSBaseModal.Body = DSModalBody
DSBaseModal.Footer = DSModalFooter

const StyledBaseModal = styled(Modal)<ReactModal.Props>`
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    padding: 0 16px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: rgb(32, 33, 35);
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    height: 60px;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);

    & button {
        background: transparent;
        border: none;
        color: white;
        font-size: 1.2em;
        cursor: pointer;
    }
`

const ModalBody = styled.div`
    display: flex;
    padding: 10px;
    height: calc(100% - 110px);
    overflow: auto;
    font-family: 'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
    line-height: 1.6;
`

const ModalFooter = styled.div`
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: right;

    & button[disabled] {
        cursor: not-allowed;
    }
`
