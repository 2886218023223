import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { DSUserFile } from "../shared/file"
import UploadButton, { UploadState } from "./upload-button"
import { useUser } from "../shared/user-context"
import { useTranslation } from "react-i18next"
import { getAPIUrl, dsToastError, isPermissionDeniedCode } from "../shared/function"
import PopupMenu, { MenuItem } from "./menu/popup-menu"
import { hostProvider } from "../shared/config"
import { DiscordChannelInvitations } from "../shared/constants"
import PromptModal, { ModalButtonType } from "./modals/prompt-modal"
import { sprintf } from "sprintf-js"

interface NavigationProps {
    onFileUploaded: (file: File) => void
    onFileSelected: (file: DSUserFile | null) => void
    onSettingsMenuClicked: () => void
}

const Navigation: React.FC<NavigationProps> = ({ onFileUploaded, onFileSelected, onSettingsMenuClicked }) => {
    // 从 UserContext 中获取用户信息
    const { userEmail, userAvatar, userToken, fetchWithChecks, logout } = useUser()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [popupMenuVisible, setPopupMenuVisible] = useState<boolean>(false)

    const [files, setFiles] = useState<DSUserFile[]>([])
    const [selectedFileID, setSelectedFileID] = useState<number | null>(null)
    const [uploadState, setUploadState] = useState<UploadState>(UploadState.Default)
    const [processingProgress, setProcessingProgress] = useState<number>(0)

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [fileToDelete, setFileToDelete] = useState<DSUserFile | null>(null)

    const menuItems: MenuItem[] = [
        { type: "quotas" },
        { type: "divider" },
        {
            type: "item",
            icon: "faCircleQuestion",
            text: t("optionMenuHelp"),
            onClick: () => {
                if (hostProvider === "aliyun") {
                    window.open(DiscordChannelInvitations.HelpChinese, "_blank")
                } else {
                    window.open(DiscordChannelInvitations.HelpEnglish, "_blank")
                }
            },
        },
        {
            type: "item",
            icon: "faMask",
            text: t("optionMenuJoinDiscord"),
            onClick: () => {
                if (hostProvider === "aliyun") {
                    window.open(DiscordChannelInvitations.ChatChinese, "_blank")
                } else {
                    window.open(DiscordChannelInvitations.ChatEnglish, "_blank")
                }
            },
        },
        {
            type: "item",
            icon: "faCartShopping",
            text: t("optionMenuSubscription"),
            onClick: () => {
                navigate("/buy")
            },
        },
        { type: "item", icon: "faGear", text: t("optionMenuSettings"), onClick: onSettingsMenuClicked },
        { type: "divider" },
        { type: "item", icon: "faRightFromBracket", text: t("optionMenuLogout"), onClick: logout },
    ]

    useEffect(() => {
        if (selectedFileID) {
            const file = files.find((file) => file.id === selectedFileID)
            if (file) {
                onFileSelected(file)
            }
        } else {
            onFileSelected(null)
        }
    }, [selectedFileID, files, onFileSelected])

    useEffect(() => {
        const url = getAPIUrl(`/api/files/`)
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        fetchWithChecks(url, true, false, {
            method: "GET",
            headers: headers,
        })
            .then((response) => response.json())
            .then((data) => {
                setFiles(data)
                if (data.length > 0) {
                    setSelectedFileID(data[0].id)
                }
            })
            .catch((error) => console.error(error))
    }, [userToken, fetchWithChecks])

    const uploadFile = async (file: File) => {
        const fileExtension = file.name.split(".").pop()?.toLowerCase()

        // 检查文件扩展名是否为 .pdf
        if (fileExtension !== "pdf") {
            alert("Only PDF files are supported.")
            return
        }

        setUploadState(UploadState.Uploading)

        const formData = new FormData()
        formData.append("file", file)

        // 文件上传
        const urlUploadFile = getAPIUrl(`/api/files/`)
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        const response = await fetchWithChecks(urlUploadFile, true, true, {
            method: "POST",
            headers: headers,
            body: formData,
        })
        if (response.ok) {
            const data = await response.json()

            // 文件上传完成并开始预处理
            setUploadState(UploadState.Processing)

            const urlPreprocess = getAPIUrl(`/api/files/preprocess/${data.id}`)
            const preprocessResponse = await fetchWithChecks(urlPreprocess, true, true, {
                method: "POST",
                headers: headers,
            })

            const preprocessData = await preprocessResponse.json()
            // console.log("Status of preprocess data is %s.", preprocessData.status)

            if (preprocessData.status === "processing") {
                const poll = async () => {
                    const urlPoll = getAPIUrl(preprocessData.poll_url)
                    const statusResponse = await fetch(urlPoll, {
                        method: "GET",
                        headers: headers,
                    })
                    const statusData = await statusResponse.json()
                    if (statusData.status === "completed") {
                        // 文件预处理完成
                        // console.log("The file %d preprocess finished!", data.id)
                        setProcessingProgress(statusData.progress)

                        setTimeout(() => {
                            setUploadState(UploadState.Default)
                            onFileUploadComplete(data)
                            onFileUploaded(file)
                        }, 500)
                    } else {
                        // 更新进度
                        // console.log("Processing progress: %d", statusData.progress)
                        setProcessingProgress(statusData.progress)
                        // 继续轮询
                        setTimeout(poll, 3000)
                    }
                }

                poll()
            }
        } else {
            setUploadState(UploadState.Default)

            if (!isPermissionDeniedCode(response.status)) {
                dsToastError(t("errorUploadFileFailed"))
            }
        }
    }

    const onFileUploadComplete = async (file: DSUserFile) => {
        const url = getAPIUrl(`/api/files/`)
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        const filesResponse = await fetchWithChecks(url, true, false, {
            method: "GET",
            headers: headers,
        })
        if (filesResponse.ok) {
            const filesData = await filesResponse.json()
            setFiles(filesData)
            setSelectedFileID(file.id)
        }
    }

    const handleFileSelect = (file: DSUserFile) => {
        setSelectedFileID(file.id)
    }

    const handleFileDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>, file: DSUserFile) => {
        e.stopPropagation()
        setIsDeleteModalOpen(true)
        setFileToDelete(file)
    }

    const handleConfirmDelete = async () => {
        if (fileToDelete) {
            // 调用 API 删除文件
            const urlDeleteFile = getAPIUrl(`/api/files/${fileToDelete.id}`)
            const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
            try {
                const response = await fetchWithChecks(urlDeleteFile, true, false, {
                    method: "DELETE",
                    headers: headers,
                })
                if (response.ok) {
                    // 刷新文件列表
                    const result = await response.json()
                    if (Array.isArray(result.files)) {
                        setFiles(result.files)
                        if (result.files.length > 0) {
                            if (selectedFileID === fileToDelete.id) {
                                setSelectedFileID(result.files[0].id)
                            }
                        } else {
                            setSelectedFileID(null)
                        }
                    } else {
                        console.log("Expected 'files' to be an array, but got:", result.files)
                    }
                }
                setIsDeleteModalOpen(false)
            } catch (err) {
                console.error("Failed to delete file:", err)
                dsToastError(t("errorDeleteFileFailed"))
            }
        }
    }

    const handleMenuClick = () => {
        setPopupMenuVisible(!popupMenuVisible)
    }

    return (
        <NavigationWrapper>
            <UploadButton uploadState={uploadState} processingProgress={processingProgress} onFileUpload={uploadFile} />
            <FileList>
                {files &&
                    files.map((file) => (
                        <div
                            key={file.id}
                            className={`file-item${selectedFileID === file.id ? " selected" : ""}`}
                            onClick={() => handleFileSelect(file)}
                        >
                            <i className="fas fa-file-pdf"></i>
                            <div className="file-name">{file.file_name}</div>
                            <i className="fas fa-trash delete-icon" onClick={(e) => handleFileDelete(e, file)}></i>
                        </div>
                    ))}
            </FileList>
            <OptionMenu>
                <BorderLine />
                <HomeButton onClick={handleMenuClick}>
                    <UserAvatar src={userAvatar || "/user.png"} alt="User Avatar" />
                    <UserEmail>{userEmail}</UserEmail>
                </HomeButton>
                <PopupMenu
                    menuItems={menuItems}
                    direction="up"
                    position={{ horizontal: "left", vertical: "bottom" }}
                    visible={popupMenuVisible}
                    onClose={() => setPopupMenuVisible(false)}
                />
            </OptionMenu>
            <PromptModal
                isOpen={isDeleteModalOpen}
                title={t("deleteDocument")}
                prompt={sprintf(t("promptForDeleteDocument"), fileToDelete?.file_name)}
                buttons={[
                    {
                        type: ModalButtonType.Cancel,
                        label: t("cancel"),
                        onButtonClicked: () => {
                            setIsDeleteModalOpen(false)
                        },
                    },
                    {
                        type: ModalButtonType.Danger,
                        label: t("delete"),
                        onButtonClicked: handleConfirmDelete,
                    },
                ]}
                onRequestClose={() => setIsDeleteModalOpen(false)}
            />
        </NavigationWrapper>
    )
}

export default Navigation

const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: rgb(32, 33, 35);
    border-right: 1px solid #ccc;
`

const FileList = styled.div`
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 62px);
    overflow-y: auto;
    font-family: 'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`

const OptionMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const BorderLine = styled.div`
    width: calc(100% - 16px);
    height: 1px;
    background-color: hsla(0, 0%, 100%, .2);
    margin-bottom: 5px;
`

const HomeButton = styled.button`
    display: flex;
    align-items: center;
    width: calc(100% - 16px);
    height: 50px;
    padding: 12px 12px;
    margin-bottom: 6px;
    background-color: transparent;
    border: none;    
    cursor: pointer;

    &:hover {
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.1);
    }    
`

const UserAvatar = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 14px;
    border-radius: 50%;
`

const UserEmail = styled.span`
    color: white;
    font-size: 1.1rem;
    line-height: 1.2;
`
