import { TabList, Tab } from "react-tabs"
import type { ReactTabsFunctionComponent, TabListProps, TabProps } from "react-tabs"
import styled, { keyframes } from "styled-components"

// 自定义 TabList 样式
const StyledTabList = styled(TabList)<TabListProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 30px;
    padding: 0;
`

const StyledTabListWrapper = styled.div`
    display: flex;
    width: fit-content;
    background-color: lightgray;
    border-radius: 20px;
    padding: 2px 0 2px 4px;
`

// 定义动画
const slideToRight = keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
`

const slideToLeft = keyframes`
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
`

interface StyledTabProps {
    $animationIndex: number
}

// 自定义Tab样式
const StyledTab = styled(Tab)<TabProps & StyledTabProps>`
    list-style: none;
    cursor: pointer;
    padding: 10px 20px;
    background: lightgray;
    margin-right: 5px;
    border-radius: 20px;

    &.react-tabs__tab--selected {
        font-weight: 600;
        background: white;
        border-radius: 20px;
        outline: none;

        animation: ${props => {
                return props.$animationIndex === 0 ? slideToRight : slideToLeft
            }}
            0.5s forwards;
    }
`

export const ProductTabList: ReactTabsFunctionComponent<TabListProps> = ({ children, ...otherProps }) => (
    <StyledTabList {...otherProps}>
        <StyledTabListWrapper>{children}</StyledTabListWrapper>
    </StyledTabList>
)

ProductTabList.tabsRole = "TabList" // Required field to use your custom Tab

export const ProductTab: ReactTabsFunctionComponent<TabProps & { animationIndex: number }> = ({
    children,
    animationIndex,
    ...otherProps
}) => (
    <StyledTab $animationIndex={animationIndex} {...otherProps}>
        {children}
    </StyledTab>
)

ProductTab.tabsRole = "Tab" // Required field to use your custom Tab
