import React from "react"
import styled from "styled-components"
import { DSBaseModal, DSBaseModalProps } from "./base-modal"

// 按钮类型
export enum ModalButtonType {
    Yes = "yes",
    Danger = "danger",
    Cancel = "cancel",
}

interface PromptModalButton {
    type: ModalButtonType
    label: string
    onButtonClicked: () => void
}

interface PromptModalProps {
    isOpen: boolean
    title: string
    prompt: string
    buttons: PromptModalButton[]
    onRequestClose: () => void
}

const PromptModal: React.FC<PromptModalProps> = ({ isOpen, title, prompt, buttons, onRequestClose }) => {
    return (
        <StyledPromptModal isOpen={isOpen} title={title} onRequestClose={onRequestClose}>
            <DSBaseModal.Body>
                <p>{prompt}</p>
            </DSBaseModal.Body>
            <DSBaseModal.Footer>
                {buttons.map((button, index) => {
                    switch (button.type) {
                        case "yes":
                            return (
                                <button
                                    key={index}
                                    className="modal-button yes-button"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        button.onButtonClicked()
                                    }}
                                >
                                    {button.label}
                                </button>
                            )
                        case "danger":
                            return (
                                <button
                                    key={index}
                                    className="modal-button danger-button"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        button.onButtonClicked()
                                    }}
                                >
                                    {button.label}
                                </button>
                            )
                        case "cancel":
                            return (
                                <button
                                    key={index}
                                    className="modal-button"
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        button.onButtonClicked()
                                    }}
                                >
                                    {button.label}
                                </button>
                            )
                        default:
                            return null
                    }
                })}
            </DSBaseModal.Footer>
        </StyledPromptModal>
    )
}

export default PromptModal

const StyledPromptModal = styled(DSBaseModal)<DSBaseModalProps>`
    position: fixed;
    width: 40vw;
    height: 20vh;
    max-width: 800px;
    min-width: 500px;
    max-height: 300px;
    min-height: 250px;
`
