// 通用尺寸定义
export const DSCssSize = {
    MobileMaxWidth: 768,
    DesktopMaxWidth: 1280,
    LandscapeMaxHeight: 500,
}

// 字体大小定义
export const DSCssFontSize = {
    H1: "48px",
    H2: "40px",
    H3: "32px",
    H4: "24px",
    H5: "20px",
    MainBody: "16px",
    MobileMainBody: "14px",
}
