import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

export enum UploadState {
    Default,
    Uploading,
    Processing,
}

interface UploadButtonProps {
    uploadState: UploadState
    processingProgress: number
    onFileUpload: (file: File) => void
}

const UploadButton: React.FC<UploadButtonProps> = ({
    uploadState,
    processingProgress,
    onFileUpload,
}) => {
    const { t } = useTranslation()

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault()
    }

    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault()

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0]
            onFileUpload(file)
        }
    }

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files.length > 0) {
            // 获取文件扩展名并转换为小写
            const file = event.target.files[0]
            onFileUpload(file)
        }
        // 清除文件输入的值，确保每次上传文件都会触发 onChange 事件。
        event.target.value = ''
    }

    let buttonText: JSX.Element
    switch (uploadState) {
        case UploadState.Uploading:
            buttonText = (
                <label htmlFor="file-upload" className="custom-file-upload">
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <span className="leading-by-spinner">{t("uploading")}</span>
                </label>
            )
            break
        case UploadState.Processing:
            buttonText = (
                <label htmlFor="file-upload" className="custom-file-upload">
                    <FontAwesomeIcon icon={faSpinner} spin />
                    <span className="leading-by-spinner">{`${t(
                        "analyzing"
                    )} ${processingProgress}%`}</span>
                </label>
            )
            break
        default:
            buttonText = (
                <label htmlFor="file-upload" className="custom-file-upload">
                    <span className="button-label">{t("openFileLabel")}</span>
                    <br />
                    <span className="button-desc">{t("openFileDesc")}</span>
                </label>
            )
    }

    return (
        <div
            className="upload-button"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {buttonText}
            <input id="file-upload" type="file" onChange={handleFileUpload} />
        </div>
    )
}

export default UploadButton
