import React, { createContext, useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { DSHttpErrorCode } from "./constants"
import { sprintf } from "sprintf-js"
import { dsToastError, isPermissionDeniedCode } from "./function"

interface UserContextValue {
    userID: number | null
    userEmail: string | null
    userAvatar: string | null
    userToken: string | null
    language: string | null
    setUserID: React.Dispatch<React.SetStateAction<number | null>>
    setUserEmail: React.Dispatch<React.SetStateAction<string | null>>
    setUserAvatar: React.Dispatch<React.SetStateAction<string | null>>
    setUserToken: React.Dispatch<React.SetStateAction<string | null>>
    setLanguage: React.Dispatch<React.SetStateAction<string | null>>
    fetchWithChecks: (
        url: string,
        checkAuth: boolean,
        checkPermission: boolean,
        init?: RequestInit
    ) => Promise<Response>
    logout: () => void
}

const UserContext = createContext<UserContextValue | undefined>(undefined)

export function UserProvider({ children }: { children: React.ReactNode }) {
    const { t } = useTranslation()

    const [userID, setUserID] = useState<number | null>(() => Number(localStorage.getItem("user_id")))
    const [userEmail, setUserEmail] = useState<string | null>(() => localStorage.getItem("user_email"))
    const [userAvatar, setUserAvatar] = useState<string | null>(() => localStorage.getItem("user_avatar"))
    const [userToken, setUserToken] = useState<string | null>(() => localStorage.getItem("user_token"))
    const [language, setLanguage] = useState<string | null>(() => localStorage.getItem("language"))

    const fetchWithChecks = async (url: string, checkAuth = true, checkPermission = false, init?: RequestInit) => {
        const response = await fetch(url, init)
        if (checkAuth && response.status === DSHttpErrorCode.Unauthorized) {
            // JWT is expired. Logout and navigate to login page
            logout()
        }

        if (checkPermission && isPermissionDeniedCode(response.status)) {
            let prompt: string = t("toastNoEnoughQuota")
            switch (response.status) {
                case DSHttpErrorCode.UploadPagePermissionDenied:
                    prompt = sprintf(t("toastNoEnoughQuotaFor"), t("uploadPage"))
                    break
                case DSHttpErrorCode.QuestionPermissionDenied:
                    prompt = sprintf(t("toastNoEnoughQuotaFor"), t("question"))
                    break
                case DSHttpErrorCode.FullUsePagePermissionDenied:
                    prompt = sprintf(t("toastNoEnoughQuotaFor"), t("fullUsePage"))
                    break
                default:
                    break
            }
            
            // 权限配额不足，需要跳转到购买页面。
            dsToastError(prompt)

            setTimeout(() => {
                window.location.href = "/buy"
            }, 2000)
        }

        return response
    }

    const logout = () => {
        setUserID(null)
        setUserEmail(null)
        setUserAvatar(null)
        setUserToken(null)
        setLanguage(null)
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_email")
        localStorage.removeItem("user_avatar")
        localStorage.removeItem("user_token")
        localStorage.removeItem("language")
    }

    useEffect(() => {
        if (userID) {
            localStorage.setItem("user_id", userID.toString())
        } else {
            localStorage.removeItem("user_id")
        }
        if (userEmail) {
            localStorage.setItem("user_email", userEmail)
        } else {
            localStorage.removeItem("user_email")
        }
        if (userAvatar) {
            localStorage.setItem("user_avatar", userAvatar)
        } else {
            localStorage.removeItem("user_avatar")
        }
        if (userToken) {
            localStorage.setItem("user_token", userToken)
        } else {
            localStorage.removeItem("user_token")
        }
        if (language) {
            localStorage.setItem("language", language)
        } else {
            localStorage.removeItem("language")
        }
    }, [userID, userEmail, userAvatar, userToken, language])

    return (
        <UserContext.Provider
            value={{
                userID,
                userEmail,
                userAvatar,
                userToken,
                language,
                setUserID,
                setUserEmail,
                setUserAvatar,
                setUserToken,
                setLanguage,
                fetchWithChecks,
                logout,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export function useUser() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider!")
    }
    return context
}
