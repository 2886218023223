import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import styled, { css } from "styled-components"
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"

export type ProductPermissionDetail = {
    content: string
    description: string
}

export type ProductCardProps = {
    title: string
    price: string
    originalPrice: string
    details: ProductPermissionDetail[]
    isCurrentPlan: boolean
    onBuyNow: () => void
}

// Component
const ProductCard: React.FC<ProductCardProps> = ({ title, price, originalPrice, details, isCurrentPlan, onBuyNow }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [detailOpenStatus, setDetailOpenStatus] = useState<boolean[]>(new Array(details.length).fill(false))

    const handleDetailClick = (index: number) => {
        setDetailOpenStatus((prevStatus) => {
            const newStatus = [...prevStatus]
            newStatus[index] = !newStatus[index]
            return newStatus
        })
    }

    const numericalPrice = parseFloat(price.slice(1))

    return (
        <Card $isCurrentPlan={isCurrentPlan}>
            <Title>{title}</Title>
            <Price>
                <DiscountPrice>{price}</DiscountPrice>
                <OriginalPrice>{originalPrice}</OriginalPrice>
            </Price>
            {numericalPrice === 0 ? (
                <TryNowButton
                    onClick={() => {
                        navigate("/app")
                    }}
                >
                    {t("try")}
                </TryNowButton>
            ) : (
                <BuyNowButton onClick={onBuyNow}>{t("buy")}</BuyNowButton>
            )}
            <DetailList>
                {details.map((detail, index) => (
                    <DetailItem key={index} onClick={() => handleDetailClick(index)}>
                        <FontAwesomeIcon icon={detailOpenStatus[index] ? faChevronDown : faChevronRight} />
                        <DetailContent>
                            {detail.content}
                            {detailOpenStatus[index] && <DetailDescription>{detail.description}</DetailDescription>}
                        </DetailContent>
                    </DetailItem>
                ))}
            </DetailList>
        </Card>
    )
}

export default ProductCard

// Styled components
const Card = styled.div<{ $isCurrentPlan: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    border-radius: 10px;
    margin: 0 10px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    ${(props) =>
        props.$isCurrentPlan &&
        css`
            border: 2px solid orange;
        `}
`

const Title = styled.h3`
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 15px;
`

const Price = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
`

const DiscountPrice = styled.span`
    font-size: 1.6rem;
    font-weight: 700;
`

const OriginalPrice = styled.span`
    color: #aaa;
    text-decoration: line-through;
    font-size: 1rem;
    margin-left: 5px;
`

const TryNowButton = styled.button`
    width: 100%;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    color: black;
    border: 1px solid #0052cc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
`

const BuyNowButton = styled.button`
    width: 100%;
    background-color: #0052cc;
    font-size: 1rem;
    font-weight: 700;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
`

const DetailList = styled.ul`
    list-style-type: none;
    padding-inline-start: 0;
`

const DetailItem = styled.li`
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    margin-bottom: 20px;
    color: rgb(101, 109, 118);
    cursor: pointer;

    & svg {
        width: 10px;
        height: 10px;
        min-width: 10px;
        margin: 4px 10px 0 0;
        box-sizing: border-box;
    }
`

const DetailContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
    font-family: 'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
    font-weight: 600;
`

const DetailDescription = styled.div`
    font-weight: normal;
    line-height: 1.4em;
    margin-top: 5px;
    transition: max-height 0.3s ease-out;
`
