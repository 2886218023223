import React, { useEffect, useRef } from "react"
import styled, { css, keyframes } from "styled-components"
import * as Icons from "@fortawesome/free-solid-svg-icons"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import QuotasCard from "./quotas-card"

export type MenuItem =
    | { type: "item"; icon: string; text: string; onClick: () => void }
    | { type: "divider" }
    | { type: "quotas" }

interface PopupMenuProps {
    // 子菜单项数组
    menuItems: MenuItem[]
    // 动画滑出方向
    direction?: "up" | "down"
    // 锚定区域
    position?: { horizontal: "left" | "right"; vertical: "top" | "bottom" }
    // 宽度，默认为 100%
    width?: number
    // Y 方向上的偏移值
    verticalOffset?: number
    // 背景色，默认为黑色
    backgroundColor?: string
    // 是否可见
    visible: boolean
    // 关闭方法
    onClose: () => void
}

function getIcon(iconName: string): Icons.IconLookup {
    // 注意: 我们需要让 TypeScript 知道我们要访问的对象键可能是任何字符串，
    // 因此我们使用 `as keyof typeof Icons` 来暂时关闭 TypeScript 的类型检查。
    const icon = Icons[iconName as keyof typeof Icons] as Icons.IconLookup
    if (!icon) {
        console.warn(`Icon "${iconName}" does not exist.`)
        // 返回一个默认的图标或 null。
        return faCircle
    }
    return icon
}

const PopupMenu: React.FC<PopupMenuProps> = ({
    menuItems,
    direction = "up",
    position = { horizontal: "left", vertical: "bottom" },
    width,
    verticalOffset,
    backgroundColor,
    visible,
    onClose,
}) => {
    const popupMenuRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (popupMenuRef.current && !popupMenuRef.current.contains(event.target as Node)) {
                onClose()
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [onClose])

    return visible ? (
        <Menu
            $direction={direction}
            $position={position}
            $width={width}
            $offset={verticalOffset}
            $backgroundColor={backgroundColor}
            ref={popupMenuRef}
        >
            {menuItems.map((menuItem, index) => {
                switch (menuItem.type) {
                    case "item":
                        return (
                            <Submenu
                                key={index}
                                onClick={() => {
                                    menuItem.onClick()
                                    onClose()
                                }}
                            >
                                <FontAwesomeIcon icon={getIcon(menuItem.icon)} />
                                <div>{menuItem.text}</div>
                            </Submenu>
                        )
                    case "divider":
                        return <Divider key={index} />
                    case "quotas":
                        return <QuotasCard key={index} />
                    default:
                        return null
                }
            })}
        </Menu>
    ) : null
}

export default PopupMenu

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
    pointer-events: none;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`

const Menu = styled.div<{
    $direction: string
    $position: PopupMenuProps["position"]
    $width?: number
    $offset?: number
    $backgroundColor?: string
}>`
    z-index: 9980;
    position: absolute;
    width: ${(props) => (props.$width ? `${props.$width}px` : `calc(100% - 16px)`)};
    padding: 4px 0;
    margin: 0 8px;
    background-color: ${(props) => props.$backgroundColor || "black"};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    opacity: 0;

    ${(props) =>
        props.$direction === "up" &&
        css`
            animation: ${slideUp} 0.3s forwards;
        `}
    ${(props) =>
        props.$direction !== "up" &&
        css`
            animation: ${slideDown} 0.3s forwards;
        `}
    ${(props) =>
        props.$position &&
        css`
            ${props.$position.vertical}: ${props.$offset ? `${props.$offset}px` : `66px`};
            ${props.$position.horizontal}: 0;
        `}
`

const Submenu = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    height: 44px;
    padding: 10px 15px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    & svg {
        width: 16px;
        margin-right: 16px;
    }
`

const Divider = styled.div`
    height: 1px;
    background-color: hsla(0, 0%, 100%, 0.2);
    margin: 6px 0;
`
