import React, { useEffect, useState, useRef, useCallback } from "react"
import { useUser } from "../shared/user-context"
import { useTranslation } from "react-i18next"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import styled from "styled-components"
import { Rect, isNearbyRect, unionRect, pointsToPixels, applyTransform, getAPIUrl } from "../shared/function"
import { CustomTextRenderer } from "react-pdf/dist/cjs/shared/types"
import { TextContent, TextItem } from "react-pdf/node_modules/pdfjs-dist/types/src/display/api"
import CryptoJS from "crypto-js"
import { debounce } from "lodash"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

// 默认页面尺寸，按 A4 纸张大小计算，单位为 point
enum DefaultPageSize {
    Width = 595,
    Height = 842,
}

interface PdfPreviewProps {
    // 文件 ID
    fileID: number
    // 页码，从 1 开始
    pageNum: number
    // 窗口宽度，单位为像素
    containerWidth: number
    // 窗口高度，单位为像素
    containerHeight: number
    // 需要高亮的文本分段
    highlightText: string[]
}

interface HighlightLine {
    itemStartIndex: number
    itemEndIndex: number
    rect: Rect
}

const PdfPreview: React.FC<PdfPreviewProps> = ({ fileID, pageNum, containerWidth, containerHeight, highlightText }) => {
    const { userToken } = useUser() // 从 UserContext 中获取用户信息
    const { t } = useTranslation()
    const containerRef = useRef<HTMLDivElement>(null)

    // 文件 URL
    const [fileURL, setFileURL] = useState<string>("")
    // 页码总数
    const [totalPageCount, setTotalPageCount] = useState<number | null>(null)
    // 页面缩放比例
    const [pageScale, setPageScale] = useState<number>(1.0)
    // 重新计算页面缩放比例的触发器
    const [triggerRecalcPageScale, setTriggerRecalcPageScale] = useState(0)
    // 用于存储每个页面的基准尺寸，单位为 point
    const pageSizesRef = useRef<{ width: number; height: number }[]>([])
    // 原始页面最大宽度，单位为 point
    const maxPageWidthRef = useRef<number>(0)
    // 页面窗口宽度，单位为像素
    // const [pageWindowWidth, setPageWindowWidth] = useState<number>(containerWidth)
    // 页面窗口高度，单位为像素
    const [pageWindowHeight, setPageWindowHeight] = useState<number>(containerHeight)
    // 滚动目标页码，也用于显示，从 1 开始
    const [scrollPageNum, setScrollPageNum] = useState<number>(pageNum)

    // 当前页面中需要高亮显示的文本行
    const [highlightLines, setHighlightLines] = useState<HighlightLine[]>([])
    // 高亮文本的 MD5 哈希值，用于触发页面刷新。
    const [highlightTextHash, setHighlightTextHash] = useState<string>("")

    // 页面块引用
    const pageRefs = useRef<(HTMLDivElement | null)[]>([])
    // 页面加载状态，以便于正确跳转到有效页面
    const pageLoadStatusRefs = useRef<boolean[]>([])
    // 页面尺寸计算状态，确保页面尺寸计算的准确性
    const pageSizeStatusRefs = useRef<boolean[]>([])
    // 专用于标记 scroll 是由指令触发还是鼠标键盘等消息触发
    const isScrollingToPageStartRef = useRef(false)

    // 文档加载成功
    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        // console.log("[onDocumentLoadSuccess] PDF file loaded successfully and its total page count is %d.", numPages)
        pageLoadStatusRefs.current = new Array(numPages).fill(false)
        pageSizeStatusRefs.current = new Array(numPages).fill(false)
        setTotalPageCount(numPages)
    }

    // 返回指定页面的尺寸，会根据 pageSizeStatusRefs 的值来判断目标页面是否是已经成功加载过，
    // 这样可以尽可能的确保采用实际页面尺寸。
    const getPageSize = useCallback(
        (index: number): [number, number] => {
            let pageWidth = DefaultPageSize.Width
            let pageHeight = DefaultPageSize.Height
            if (pageSizesRef.current[index] && pageSizeStatusRefs.current[index]) {
                pageWidth = pageSizesRef.current[index].width
                pageHeight = pageSizesRef.current[index].height
            } else {
                if (index > 0) {
                    if (pageSizesRef.current[index - 1] && pageSizeStatusRefs.current[index - 1]) {
                        pageWidth = pageSizesRef.current[index - 1].width
                        pageHeight = pageSizesRef.current[index - 1].height
                    } else {
                        if (pageSizesRef.current[pageNum - 1] && pageSizeStatusRefs.current[pageNum - 1]) {
                            pageWidth = pageSizesRef.current[pageNum - 1].width
                            pageHeight = pageSizesRef.current[pageNum - 1].height
                        }
                    }
                }
            }

            return [pageWidth, pageHeight]
        },
        [pageNum]
    )

    // 计算每个 textItem 的外接矩形坐标，坐标系方向为 PDF 页面坐标系。
    const getTextItemRect = (textItem: TextItem): Rect => {
        const [x1, y1] = applyTransform(textItem.transform, [0, 0])
        const [x2, y2] = [x1 + textItem.width, y1 + textItem.height]
        return { x1, y1, x2, y2 }
    }

    // 计算文本层中和 highlightText 匹配的文本行
    const onGetTextSuccess = (textContent: TextContent) => {
        let lines: HighlightLine[] = []
        let currentLine: HighlightLine | null = null
        let cleanedItems: string[] = []
        let cleanedTextStartIndices: number[] = []
        let cleanedText = ""
        let itemIndices: number[] = [] // 映射到 textContent.items 的索引

        // 生成新的字符串和起始位置数组
        textContent.items.forEach((item, index) => {
            if (item.hasOwnProperty("transform")) {
                // eslint-disable-next-line no-control-regex
                let cleanedItemText = (item as TextItem).str.replace(/[\s\u0001]+/g, "")
                if (cleanedItemText !== "") {
                    cleanedItems.push(cleanedItemText)
                    cleanedTextStartIndices.push(cleanedText.length)
                    cleanedText += cleanedItemText
                    itemIndices.push(index) // 保存对应的 index
                }
            }
        })
        // console.log("Cleaned text segments's start indexes is:", cleanedTextStartIndices)

        highlightText.forEach((segmentHighlightText) => {
            // 查找待匹配的字符串
            // eslint-disable-next-line no-control-regex
            let cleanedHighlightText = segmentHighlightText.replace(/[\s\u0001]+/g, "")
            let startMatchIndex = cleanedText.indexOf(cleanedHighlightText)
            let endMatchIndex = startMatchIndex + cleanedHighlightText.length

            if (startMatchIndex === -1) {
                // 没有找到待匹配的字符串，不做处理
                return
            }

            // 找到对应的起始和结束的文本项目
            let itemStartIndex = cleanedTextStartIndices.findIndex((startIndex, index) => {
                let endIndex =
                    index < cleanedTextStartIndices.length - 1 ? cleanedTextStartIndices[index + 1] : cleanedText.length
                return startIndex <= startMatchIndex && endIndex > startMatchIndex
            })
            itemStartIndex = itemIndices[itemStartIndex] // 映射到正确的 index

            let itemEndIndex = cleanedTextStartIndices.findIndex((startIndex, index) => {
                let endIndex =
                    index < cleanedTextStartIndices.length - 1 ? cleanedTextStartIndices[index + 1] : cleanedText.length
                return startIndex < endMatchIndex && endIndex >= endMatchIndex
            })
            itemEndIndex = itemIndices[itemEndIndex] // 映射到正确的 index

            // 计算应该高亮显示的区域
            for (let i = itemStartIndex; i <= itemEndIndex; i++) {
                if (textContent.items[i].hasOwnProperty("transform")) {
                    let textItem = textContent.items[i] as TextItem
                    if (textItem.width > 0.1) {
                        let rect = getTextItemRect(textItem)
                        if (currentLine && isNearbyRect(currentLine.rect, rect)) {
                            currentLine.rect = unionRect(currentLine.rect, rect)
                            currentLine.itemEndIndex = i
                        } else {
                            if (currentLine) {
                                lines.push(currentLine)
                            }
                            currentLine = {
                                rect,
                                itemStartIndex: i,
                                itemEndIndex: i,
                            }
                        }
                    }
                }
            }

            if (currentLine) {
                lines.push(currentLine)
                currentLine = null // 为下一次循环重置 currentLine
            }
        })

        // console.log("The highlight area of page %d is", pageNum, lines)
        setHighlightLines(lines)
    }

    // 高亮匹配好的文本行以提示
    const highlightMatchedTextRenderer: CustomTextRenderer = (props) => {
        const { str, itemIndex } = props
        let highlightLine: HighlightLine | null = null
        for (let line of highlightLines) {
            if (itemIndex === line.itemStartIndex) {
                highlightLine = line
                break
            }
        }

        if (highlightLine) {
            const highlight = `<span class="highlight-block" style="width: ${
                pageScale * pointsToPixels(highlightLine.rect.x2 - highlightLine.rect.x1)
            }px; border-radius: ${
                pageScale * pointsToPixels((highlightLine.rect.y2 - highlightLine.rect.y1) / 2)
            }px"></span>`
            return str + highlight
        } else {
            return str
        }
    }

    // 由于目前 react-pdf 会给高亮块的父元素加上 transform: scaleX 属性，
    // 这会影响高亮块宽度计算的不一致，因此我们需要设置一个逆转换，
    // 但这里还遗留一个问题，因为高亮块可能会覆盖多个文本块，我们现在只对齐了第一个，后续的并未对齐。
    const handleRenderTextLayerSuccess = () => {
        // 用 querySelectorAll 获取所有的 .highlight-block 元素
        const highlightBlocks = document.querySelectorAll(".highlight-block")

        highlightBlocks.forEach((highlightBlock) => {
            // 获取高亮块的父元素
            const parent = highlightBlock.parentElement

            if (parent) {
                // 获取父元素的 transform 属性
                const transform = parent.style.transform

                // 用正则表达式从 transform 属性中提取 scaleX 值
                const scaleXMatch = transform.match(/scaleX\((.*?)\)/)

                if (scaleXMatch) {
                    // 提取 scaleX 值
                    const scaleX = parseFloat(scaleXMatch[1])

                    // 如果 scaleX 是有效的数字
                    if (!isNaN(scaleX) && scaleX !== 0) {
                        // 将 scaleX 应用到高亮块上，以达到逆转换的效果
                        ;(highlightBlock as HTMLElement).style.transform = `scaleX(${1 / scaleX})`
                    }
                }
            }
        })
    }

    useEffect(() => {
        setHighlightTextHash(CryptoJS.MD5(highlightText.join()).toString())
    }, [highlightText])

    // 响应 scroll 页面滚动事件
    useEffect(() => {
        const currentContainerRef = containerRef.current
        let lastScrollTop = currentContainerRef ? currentContainerRef.scrollTop : 0

        const handleScroll = debounce(() => {
            // DO NOT REMOVE NEXT LOG!
            // console.log("[useEffect(handleScroll)] Begin handle scrolling page.")
            if (currentContainerRef) {
                const scrollTop = currentContainerRef.scrollTop
                // DO NOT REMOVE NEXT LOG!
                // console.log(
                //     "[useEffect(handleScroll)] Page scrolled for scrollTop is %d, lastScrollTop is %d.",
                //     scrollTop,
                //     lastScrollTop
                // )
                if (scrollTop !== lastScrollTop) {
                    // 找到滚动位置所处的页面
                    let accumulatedHeight = 0
                    const halfPageWindowHeight = pageWindowHeight * 0.5

                    // DO NOT REMOVE NEXT LOG!
                    // console.log(
                    //     "[useEffect(handleScroll)] Current page sizes array's length is %d, and halfPageWindowHeight is %d.",
                    //     pageSizesRef.current.length,
                    //     halfPageWindowHeight
                    // )

                    let newPageNum = -1
                    for (let i = 0; i < pageSizesRef.current.length; i++) {
                        const pageHeight = pointsToPixels(getPageSize(i)[1]) * pageScale + 3
                        accumulatedHeight += pageHeight

                        // DO NOT REMOVE NEXT LOG!
                        // console.log(
                        //     "[useEffect(handleScroll)] Current pageHeight: %f, scrollTop: %f, accumulatedHeight: %f, pageScale: %f",
                        //     pageHeight,
                        //     scrollTop,
                        //     accumulatedHeight,
                        //     pageScale
                        // )
                        if (scrollTop + halfPageWindowHeight < accumulatedHeight) {
                            newPageNum = i + 1
                            // DO NOT REMOVE NEXT LOG!
                            // console.log(
                            //     "[useEffect(handleScroll)] We found that the page had scrolled in %d.",
                            //     newPageNum
                            // )
                            setScrollPageNum(newPageNum)
                            break
                        }
                    }

                    lastScrollTop = scrollTop
                }
            }
        }, 500)

        if (currentContainerRef) {
            currentContainerRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentContainerRef) {
                currentContainerRef.removeEventListener("scroll", handleScroll)
                handleScroll.cancel()
            }
        }
    }, [pageWindowHeight, pageScale, getPageSize])

    // 滚动页面到指定页
    useEffect(() => {
        const MAX_ATTEMPTS = 5 // 设置最大尝试次数
        const scrollToPage = (pageNumber: number, attempts: number = 0) => {
            if (pageRefs.current[pageNumber - 1] && pageLoadStatusRefs.current[pageNumber - 1]) {
                if (isScrollingToPageStartRef.current) {
                    setTimeout(() => {
                        // DO NOT REMOVE NEXT LOG!
                        // console.log(
                        //     "[useEffect(scrollPageNum)] Begin scrollIntoView action to page %d after %d attemps.",
                        //     pageNumber,
                        //     attempts + 1
                        // )

                        pageRefs.current[pageNumber - 1]?.scrollIntoView()

                        setTimeout(() => {
                            // DO NOT REMOVE NEXT LOG!
                            // console.log(
                            //     "[useEffect(scrollPageNum)] Clean isScrollingToPageStartRef tag after scrollIntoView."
                            // )
                            isScrollingToPageStartRef.current = false
                        }, 300)
                    }, 200)
                }
            } else {
                if (attempts < MAX_ATTEMPTS) {
                    // DO NOT REMOVE NEXT LOG!
                    // 如果引用不存在或页面未加载，并且未达到最大尝试次数，尝试延迟滚动
                    // console.log(
                    //     "[useEffect(scrollPageNum)] Try to scroll page to %d again for pageRefs not ready, and pageLoadStatus[%d] is %s.",
                    //     pageNumber,
                    //     pageNumber - 1,
                    //     pageLoadStatusRefs.current[pageNumber - 1]
                    // )
                    setTimeout(() => scrollToPage(pageNumber, attempts + 1), 200)
                } else {
                    // DO NOT REMOVE NEXT LOG!
                    // 达到最大尝试次数，可以选择放弃或者执行其他操作
                    // console.log(
                    //     "[useEffect(scrollPageNum)] Give up scrolling to page %d after %d attempts.",
                    //     pageNumber,
                    //     MAX_ATTEMPTS
                    // )
                }
            }
        }

        if (scrollPageNum > 0) {
            // DO NOT REMOVE NEXT LOG!
            // console.log("[useEffect(scrollPageNum)] Current scroll page number changed to %d.", scrollPageNum)
            scrollToPage(scrollPageNum)
        }
    }, [scrollPageNum])

    // 监听 pageNum 属性的变化
    useEffect(() => {
        if (totalPageCount && pageNum > 0) {
            // DO NOT REMOVE NEXT LOG!
            // console.log("[useEffect(pageNum, totalPageCount)] Current page number changed to %d.", pageNum)
            isScrollingToPageStartRef.current = true
            setScrollPageNum(pageNum)

            setTimeout(() => {
                // DO NOT REMOVE NEXT LOG!
                // console.log("[useEffect(pageNum, totalPageCount)] Clean isScrollingToPageStartRef tag.")
                isScrollingToPageStartRef.current = false
            }, 1000)
        }
    }, [pageNum, totalPageCount])

    // 处理 resize 事件影响页面缩放比例的计算
    useEffect(() => {
        const handleResize = debounce(() => {
            if (containerRef.current && maxPageWidthRef.current > 0) {
                let limitedPageWidth = pointsToPixels(maxPageWidthRef.current) * window.devicePixelRatio * 1.4
                const newPageWindowWidth = Math.min(containerWidth - 20, limitedPageWidth)
                // setPageWindowWidth(newPageWindowWidth)
                setPageWindowHeight(containerRef.current.clientHeight)

                // DO NOT REMOVE NEXT LOG!
                // console.log(
                //     "[useEffect(containerWidth, triggerRecalcPageScale)] pageWindowWidth will change to %d, and pageWindowHeight will change to %d.",
                //     newPageWindowWidth,
                //     containerRef.current.clientHeight
                // )

                let scale = newPageWindowWidth / pointsToPixels(maxPageWidthRef.current)
                setPageScale(scale)

                // DO NOT REMOVE NEXT LOG!
                // console.log(
                //     "[useEffect(containerWidth, triggerRecalcPageScale)] Current page scale changed to %f and maxPageWidth is %d.",
                //     scale,
                //     maxPageWidthRef.current
                // )
            }
        }, 200)
        window.addEventListener("resize", handleResize)

        handleResize()

        // DO NOT REMOVE NEXT LOG!
        // console.log(
        //     "[useEffect(containerWidth, triggerRecalcPageScale)] maxPageWidth changed to %d.",
        //     maxPageWidthRef.current
        // )

        return () => {
            window.removeEventListener("resize", handleResize)
            handleResize.cancel()
        }
    }, [containerWidth, triggerRecalcPageScale])

    useEffect(() => {
        if (fileID > 0) {
            const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
            // 调用 API 获取 PDF 文件地址L
            const url = getAPIUrl(`/api/files/${fileID}`)
            fetch(url, {
                method: "GET",
                headers: headers,
            })
                .then((response) => response.json())
                .then((data) => {
                    setFileURL(data.file_url)
                    setTotalPageCount(null)

                    maxPageWidthRef.current = 0
                    pageSizesRef.current = []
                })
                .catch((error) => console.error("Error:", error))
        } else {
            setFileURL("")
        }
    }, [fileID, userToken])

    return (
        <PDFDocumentContainer
            ref={containerRef}
            onMouseEnter={() => containerRef.current && containerRef.current.focus()}
            tabIndex={0}
        >
            {fileURL ? (
                <Document
                    file={fileURL}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<PDFReaderPromptLoading>{t("loadingPDF")}</PDFReaderPromptLoading>}
                    error={<PDFReaderPromptError>{t("loadPDFFailed")}</PDFReaderPromptError>}
                    noData={<PDFReaderPromptNoData>{t("noPDFData")}</PDFReaderPromptNoData>}
                >
                    {Array.from(new Array(totalPageCount), (el, index) => {
                        if (index >= scrollPageNum - 4 && index <= scrollPageNum + 2) {
                            // 返回实际的 Page 组件
                            return (
                                <div
                                    key={`page_${index + 1}`}
                                    ref={(el: HTMLDivElement | null) => (pageRefs.current[index] = el)}
                                >
                                    <Page
                                        key={`page_${index + 1}_${highlightTextHash}`}
                                        pageNumber={index + 1}
                                        width={pointsToPixels(getPageSize(index)[0]) * pageScale}
                                        renderMode="canvas"
                                        className="pdf-page"
                                        customTextRenderer={
                                            index + 1 === pageNum
                                                ? highlightMatchedTextRenderer
                                                : ({ str, itemIndex }) => str
                                        }
                                        onGetTextSuccess={index + 1 === pageNum ? onGetTextSuccess : () => {}}
                                        onRenderTextLayerSuccess={handleRenderTextLayerSuccess}
                                        onLoadSuccess={(page) => {
                                            // 确保这个页面不是被销毁的对象
                                            if (!page.destroyed && totalPageCount) {
                                                // DO NOT REMOVE NEXT LOG!
                                                // console.log("[DOM] Mount the page index %d and total page count is %d.", index, totalPageCount)
                                                pageLoadStatusRefs.current[index] = true

                                                const originalWidth = page.getViewport({
                                                    scale: 1,
                                                }).width
                                                const originalHeight = page.getViewport({
                                                    scale: 1,
                                                }).height

                                                // DO NOT REMOVE NEXT LOG!
                                                // console.log(
                                                //     "[DOM] Update PDF page sizes data for page %d, and width: %d, height: %d.",
                                                //     index + 1,
                                                //     originalWidth,
                                                //     originalHeight
                                                // )
                                                pageSizesRef.current[index] = {
                                                    width: originalWidth,
                                                    height: originalHeight,
                                                }
                                                pageSizeStatusRefs.current[index] = true

                                                if (originalWidth > maxPageWidthRef.current) {
                                                    // DO NOT REMOVE NEXT LOG!
                                                    // console.log(
                                                    //     "[DOM] Page max width changed, now the value is: %d",
                                                    //     originalWidth
                                                    // )
                                                    maxPageWidthRef.current = originalWidth
                                                    setTriggerRecalcPageScale((prev) => prev + 1)
                                                }
                                            }
                                        }}
                                        loading={
                                            <PDFReaderPromptLoading $inPage={true}>
                                                {t("loadingPDFPage")}
                                            </PDFReaderPromptLoading>
                                        }
                                        error={
                                            <PDFReaderPromptError $inPage={true}>
                                                {t("loadPDFPageFailed")}
                                            </PDFReaderPromptError>
                                        }
                                        noData={
                                            <PDFReaderPromptNoData $inPage={true}>
                                                {t("noPDFPageData")}
                                            </PDFReaderPromptNoData>
                                        }
                                    />
                                </div>
                            )
                        } else {
                            // 返回一个空白的占位 div，同时也需要设置尺寸
                            const [holderPageWidth, holderPageHeight] = getPageSize(index)
                            pageLoadStatusRefs.current[index] = false
                            pageSizesRef.current[index] = { width: holderPageWidth, height: holderPageHeight }

                            // DO NOT REMOVE NEXT LOG!
                            // console.log(
                            //     "[DOM] Update holder page sizes data for page %d, and width: %d, height: %d.",
                            //     index + 1,
                            //     holderPageWidth,
                            //     holderPageHeight
                            // )

                            return (
                                <div
                                    key={`page_${index + 1}`}
                                    // 确保 div 有正确的宽度和高度
                                    style={{
                                        display: "inline-block",
                                        width: pointsToPixels(holderPageWidth) * pageScale,
                                        height: pointsToPixels(holderPageHeight) * pageScale,
                                        backgroundColor: "white",
                                        marginBottom: "3px",
                                    }}
                                    ref={(el: HTMLDivElement | null) => (pageRefs.current[index] = el)}
                                >
                                    {
                                        <PDFReaderPromptLoading $inPage={true}>
                                            {t("loadingPDFPage")}
                                        </PDFReaderPromptLoading>
                                    }
                                </div>
                            )
                        }
                    })}
                    {totalPageCount && (
                        <PDFReaderToolBar>
                            <span>{`${scrollPageNum}/${totalPageCount}`}</span>
                        </PDFReaderToolBar>
                    )}
                </Document>
            ) : (
                <PDFReaderPromptNoData>{t("noPDFSelected")}</PDFReaderPromptNoData>
            )}
        </PDFDocumentContainer>
    )
}

export default PdfPreview

const PDFDocumentContainer = styled.div`
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;
    background-color: #999999;

    & .pdf-page {
        display: inline-block;
    }

    & .highlight-block {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: rgba(180, 0, 170, 0.2) !important;
    }
`

const PDFReaderPromptLoading = styled.div<{ $inPage?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.$inPage ? "100%" : "100vh")};
    color: #ccc;
    font-style: italic;
    background-color: transparent;
`

const PDFReaderPromptError = styled.div<{ $inPage?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.$inPage ? "100%" : "100vh")};
    color: firebrick;
    background-color: transparent;
`

const PDFReaderPromptNoData = styled.div<{ $inPage?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.$inPage ? "100%" : "100vh")};
    color: #ccc;
    background-color: transparent;
`

const PDFReaderToolBar = styled.div`
    position: absolute;
    bottom: 15px;
    right: 20px;
    width: 100px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(33, 33, 33);

    & span {
        color: white;
    }
`
