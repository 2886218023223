import React, { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { sprintf } from "sprintf-js"
import { DSBaseModal, DSBaseModalProps } from "./base-modal"
import Select, { ActionMeta, StylesConfig } from "react-select"

interface ExtractToCModalProps {
    // 对话框显示/隐藏开关
    isOpen: boolean
    // 深度使用页面数剩余配额
    remainingQuota: number
    // 提取章节目录动作确认
    onActionConfirmed: (startPage: number, endPage: number, correction: number) => void
    // 对话框关闭请求
    onRequestClose: () => void
}

const ExtractToCModal: React.FC<ExtractToCModalProps> = ({
    isOpen,
    remainingQuota,
    onActionConfirmed,
    onRequestClose,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [inputValue, setInputValue] = useState<string>("")
    const [costPrompt, setCostPrompt] = useState<string>("")
    const [needBuyMore, setNeedBuyMore] = useState<boolean>(false)

    const [correction, setCorrection] = useState<number>(0)
    const correctionOptions: DSNumberSelectOption[] = [
        { value: 0, label: "0" },
        { value: 1, label: "+1" },
        { value: 2, label: "+2" },
        { value: 3, label: "+3" },
        { value: 4, label: "+4" },
        { value: 5, label: "+5" },
    ]

    // 解析输入字符串
    function parsePageRange(range: string): [number, number] {
        // 去掉所有空格
        const cleanedRange = range.replace(/\s/g, "")

        // 检查是否为单个数字
        if (/^\d+$/.test(cleanedRange)) {
            const page = parseInt(cleanedRange, 10)
            return [page, page]
        }

        // 检查是否为一个页码范围，比如 "5-7"
        const match = cleanedRange.match(/^(\d+)-(\d+)$/)
        if (match) {
            const startPage = parseInt(match[1], 10)
            const endPage = parseInt(match[2], 10)
            if (startPage <= endPage) {
                return [startPage, endPage]
            }
        }

        // 如果输入的字符串不满足上述格式，返回 [-1, -1]
        return [-1, -1]
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setInputValue(newValue)

        const [startPage, endPage] = parsePageRange(newValue)
        if (endPage >= startPage && startPage > 0) {
            const pageCount = endPage - startPage + 1
            const prompt = sprintf(t("promptForExtractToCCost"), t("fullUsePage"), pageCount, remainingQuota)
            setCostPrompt(prompt)

            if (remainingQuota >= pageCount) {
                setNeedBuyMore(false)
            } else {
                setNeedBuyMore(true)
            }
        } else {
            setCostPrompt("")
        }
    }

    // 处理 Select 控件的值改变事件
    const handleSelectChange = (option: DSNumberSelectOption | null, actionMeta: ActionMeta<DSNumberSelectOption>) => {
        const selectedCorrection = option ? option.value : 0
        setCorrection(selectedCorrection)
    }

    const customStyles: StylesConfig<DSNumberSelectOption, false> = {
        valueContainer: (base) => ({
            ...base,
            width: 150,
            height: 40,
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "blue" : "black",
            backgroundColor: state.isFocused ? "lightgrey" : "white",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }

    // 处理点击“确认”按钮的事件
    const onConfirmButtonClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onRequestClose()

        const [startPage, endPage] = parsePageRange(inputValue)
        if (endPage >= startPage && startPage > 0) {
            onActionConfirmed(startPage, endPage, correction)
        }
    }

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                inputRef.current?.focus()
            }, 100)
        }
    }, [isOpen])

    return (
        <DSExtractToCModal isOpen={isOpen} title={t("bookExtractToC")} onRequestClose={onRequestClose}>
            <DSBaseModal.Body>
                <DSExtractToCBody>
                    <DSExtractToCPrompt>
                        <p>
                            <span>{t("promptForInputPageRange")}</span>
                            <br />
                            <span>{t("promptForCorrection")}</span>
                        </p>
                    </DSExtractToCPrompt>
                    <DSInputWrapper>
                        <DSPageRangeInput
                            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                event.stopPropagation()
                            }}
                        >
                            <input ref={inputRef} type="text" value={inputValue} onChange={handleInputChange} />
                        </DSPageRangeInput>
                        <Select
                            defaultValue={correctionOptions[0]}
                            options={correctionOptions}
                            onChange={handleSelectChange}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                        />
                    </DSInputWrapper>
                    <DSExtractToCPrompt>
                        <p>
                            <span>{costPrompt}</span>
                        </p>
                    </DSExtractToCPrompt>
                </DSExtractToCBody>
            </DSBaseModal.Body>
            <DSBaseModal.Footer>
                <button
                    className="modal-button"
                    onClick={(event) => {
                        event.stopPropagation()
                        onRequestClose()
                    }}
                >
                    {t("cancel")}
                </button>
                {needBuyMore ? (
                    <button className="modal-button yes-button" onClick={() => navigate("/buy")}>
                        {t("buy")}
                    </button>
                ) : (
                    <button className="modal-button yes-button" disabled={!costPrompt} onClick={onConfirmButtonClicked}>
                        {t("confirm")}
                    </button>
                )}
            </DSBaseModal.Footer>
        </DSExtractToCModal>
    )
}

export default ExtractToCModal

const DSExtractToCModal = styled(DSBaseModal)<DSBaseModalProps>`
    position: fixed;
    width: 40vw;
    height: 30vh;
    max-width: 800px;
    min-width: 600px;
    max-height: 500px;
    min-height: 450px;
`

const DSExtractToCBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const DSExtractToCPrompt = styled.div`
    line-height: 1.6;
    padding-right: 50px;
`

const DSInputWrapper = styled.div`
    display: flex;
    align-items: center;
`

const DSPageRangeInput = styled.div`
    margin: 1em 0;

    & input {
        width: 300px;
        height: 44px;
        padding: 0 15px;
        margin-right: 20px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 1rem;
        color: black;
    }
`
