import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import "./App.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { UserProvider } from "./shared/user-context"
import HomePage from "./views/home-page"
import SignInPage from "./views/sign-in-page"
import SignUpPage from "./views/sign-up-page"
import AppPage from "./views/app-page"
import BuyPage from "./views/buy-page"
import ResetPasswordPage from "./views/reset-password-page"
import AboutPage from "./views/about-page"
import PricePage from "./views/price-page"

const App: React.FC = () => {
    return (
        <UserProvider>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                style={{ width: "400px", zIndex: 10000 }}
            />
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<SignInPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="/app" element={<AppPage />} />
                    <Route path="/buy" element={<BuyPage />} />
                    <Route path="/price" element={<PricePage />} />
                    <Route path="/about" element={<AboutPage />} />
                </Routes>
            </Router>
        </UserProvider>
    )
}

export default App
