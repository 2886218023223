import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { DSBaseModal, DSBaseModalProps } from "./base-modal"
import { DSLanguageCode, DSLanguageNames } from "../../shared/constants"
import Select, { ActionMeta, StylesConfig } from "react-select"

interface SettingsModalProps {
    isOpen: boolean
    title: string
    language: string | null
    onRequestClose: () => void
    onLanguageChanged: (language: string) => void
}

const SettingsModal: React.FC<SettingsModalProps> = ({
    isOpen,
    title,
    language,
    onRequestClose,
    onLanguageChanged,
}) => {
    const { t } = useTranslation()

    const languageOptions: DSNumberSelectOption[] = [
        { value: DSLanguageCode.English, label: "English" },
        { value: DSLanguageCode.SimplifiedChinese, label: "简体中文" },
    ]

    // 返回默认语言选项
    function getDefaultLanguageOption() {
        let option: DSNumberSelectOption | undefined = undefined

        if (language) {
            const code = Object.keys(DSLanguageNames).find(
                (key) => DSLanguageNames[Number(key) as DSLanguageCode] === language
            )
            if (code) {
                option = languageOptions.find((option) => option.value === Number(code))
            }            
        }

        if (option) {
            return option
        } else {
            return languageOptions[0]
        }
    }

    const customStyles: StylesConfig<DSNumberSelectOption, false> = {
        valueContainer: (base) => ({
            ...base,
            width: 150,
            height: 40,
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif",
            color: state.isSelected ? "blue" : "black",
            backgroundColor: state.isFocused ? "lightgrey" : "white",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    }

    // 处理 Select 控件的值改变事件
    const handleLanguageChange = (option: DSNumberSelectOption | null, actionMeta: ActionMeta<DSNumberSelectOption>) => {
        const newLanguageCode: DSLanguageCode = option ? option.value : DSLanguageCode.English
        onLanguageChanged(DSLanguageNames[newLanguageCode])
    }

    return (
        <StyledSettingsModal isOpen={isOpen} title={title} onRequestClose={onRequestClose}>
            <DSBaseModal.Body>
                <SettingsSideBar>
                    <div className="settings-category">{t("general")}</div>
                </SettingsSideBar>
                <SettingsContent>
                    <SettingsItem>
                        <div>{t("language")}</div>
                        <Select
                            defaultValue={getDefaultLanguageOption()}
                            options={languageOptions}
                            onChange={handleLanguageChange}
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition={"fixed"}
                        />
                    </SettingsItem>
                </SettingsContent>
            </DSBaseModal.Body>
        </StyledSettingsModal>
    )
}

export default SettingsModal

const StyledSettingsModal = styled(DSBaseModal)<DSBaseModalProps>`
    width: 40vw;
    height: 50vh;
    max-width: 800px;
    min-width: 500px;
    max-height: 500px;
    min-height: 300px;
`

const SettingsSideBar = styled.div`
    width: 150px;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    font-family: "Roboto Condensed", 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif;
    line-height: 44px;
    border-right: 1px solid hsla(0, 0%, 100%, 0.2);
`

const SettingsContent = styled.div`
    flex-grow: 1;
    padding-left: 40px;
`

const SettingsItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
`
