import { hostProvider, serverIP, serverPort } from "./config"
import { UserContextType } from "./basic-type"
import { DSHttpErrorCode } from "./constants"
import { toast, Slide } from "react-toastify"

export interface Rect {
    x1: number
    y1: number
    x2: number
    y2: number
}

// 判断两个矩形是否相邻
export const isNearbyRect = (rect1: Rect, rect2: Rect) => {
    // 计算 rect2 在 y 轴方向上的中心线
    const centerY2 = (rect2.y1 + rect2.y2) / 2

    // 判断 rect2 的中心线是否和 rect1 相交
    if (centerY2 < rect1.y1 || centerY2 > rect1.y2) {
        return false
    }

    // 计算两个矩形在水平方向上的间距
    const horizontalDistance = rect1.x1 > rect2.x2 ? rect1.x1 - rect2.x2 : rect2.x1 - rect1.x2

    // 计算两个矩形的高度
    const height1 = rect1.y2 - rect1.y1
    const height2 = rect2.y2 - rect2.y1

    // 判断两个矩形在水平方向上是否足够近
    if (horizontalDistance < Math.min(height1, height2)) {
        return true
    }

    return false
}

export const unionRect = (rect1: Rect, rect2: Rect) => {
    return {
        x1: Math.min(rect1.x1, rect2.x1),
        y1: Math.min(rect1.y1, rect2.y1),
        x2: Math.max(rect1.x2, rect2.x2),
        y2: Math.max(rect1.y2, rect2.y2),
    }
}

const POINTS_PER_INCH = 72
const PIXELS_PER_INCH = window.devicePixelRatio * 96

export function pointsToPixels(points: number): number {
    return (points / POINTS_PER_INCH) * PIXELS_PER_INCH
}

export const applyTransform = (transform: Array<any>, point: [number, number]): [number, number] => {
    const [a, b, c, d, e, f] = transform
    const [x, y] = [pointsToPixels(point[0]), pointsToPixels(point[1])]
    return [a * x + c * y + e, b * x + d * y + f]
}

// 根据环境配置获得 API 的真实 URL。
export function getAPIUrl(endpoint: string) {
    let baseUrl

    switch (serverIP.toLowerCase()) {
        case "backend":
            baseUrl = ""
            break
        case "docuspeed.ai":
            baseUrl = `https://docuspeed.ai:${serverPort}`
            break
        default:
            baseUrl = `http://${serverIP}:${serverPort}`
            break
    }

    return `${baseUrl}${endpoint}`
}

// 根据环境配置获得 WebSocket 连接的真实 URL。
export function getWebSocketUrl(endpoint: string) {
    let baseUrl

    switch (serverIP.toLowerCase()) {
        case "backend":
            if (hostProvider === "aws.staging") {
                baseUrl = `wss://staging.docuspeed.ai`
            } else {
                baseUrl = `wss://docuspeed.ai`
            }
            break
        case "docuspeed.ai":
            baseUrl = `wss://docuspeed.ai`
            break
        default:
            baseUrl = `ws://${serverIP}:${serverPort}`
            break
    }

    return `${baseUrl}${endpoint}`
}

// 带 token/permission 检查的 fetch 请求
export const fetchWithChecks = async (
    url: string,
    userContext: UserContextType,
    init?: RequestInit,
    checkToken = true,
    checkPermission = false
) => {
    const response = await fetch(url, init)
    if (checkToken && response.status === DSHttpErrorCode.Unauthorized) {
        // JWT is expired. Logout and navigate to login page
        userContext.logout()
    }

    if (
        checkPermission &&
        response.status >= DSHttpErrorCode.PermissionDenied &&
        response.status <= DSHttpErrorCode.FullUsePagePermissionDenied
    ) {
        // User exceeded their limit. Navigate to payment page
        window.location.href = "/buy"
    }

    return response
}

// 弹出 Toast 成功提示信息
export function dsToastSuccess(prompt: string) {
    toast.success(prompt, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
        bodyStyle: { fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif" },
    })
}

// 弹出 Toast 失败提示信息
export function dsToastError(prompt: string) {
    toast.error(prompt, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
        bodyStyle: { fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif" },
    })
}

// 弹出 Toast 提示信息
export function dsToastInfo(prompt: string): ToastID {
    return toast.info(prompt, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
        bodyStyle: { fontFamily: "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif" },
    })
}

// 更新 Toast 的进度信息
export function dsToastUpdateProgress(id: ToastID, progress: number) {
    toast.update(id, {
        progress: progress,
    })
}

// 关闭 Toast 提示信息
export function dsToastDismiss(id: ToastID) {
    toast.dismiss(id)
}

// 检查错误码是否是用户权限配额不足
export function isPermissionDeniedCode(status: number) {
    if (status >= DSHttpErrorCode.PermissionDenied && status <= DSHttpErrorCode.FullUsePagePermissionDenied) {
        return true
    } else {
        return false
    }
}
