import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useUser } from "../../shared/user-context"
import { t } from "i18next"
import { sprintf } from "sprintf-js"
import { getAPIUrl } from "../../shared/function"
import { QuotaApiResponse } from "../../shared/business"
import { DSCountryCode } from "../../shared/constants"
import { hostProvider } from "../../shared/config"

const QuotaLabel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 15px 10px;
`

const QuotaProductName = styled.span`
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
`

const QuotaPlanRemaining = styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: white;
`

const QuotaRow = styled.div`
    display: block;
    color: white;
    padding: 10px 15px;
`

const MessageRow = styled.div`
    display: block;
    color: white;
    padding: 10px 15px;
    font-size: 1rem;
    text-align: left;
`

const QuotaData = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0 10px;
    font-size: 1rem;
`
const QuotaType = styled.div``

const QuotaUsage = styled.div``

const QuotaCycleReset = styled.div`
    display: block;
    padding: 8px 15px;
    font-size: 0.875rem;
    text-align: right;
    color: white;
`

const ProgressBar = styled.div`
    width: 100%;
    height: 10px;
    background-color: #f3f3f3;
`

const Progress = styled.div`
    height: 10px;
    background-color: #0084ff;
`

const QuotaDivider = styled.div`
    height: 1px;
    background-color: hsla(0, 0%, 100%, 0.2);
    margin: 6px 0;
`

const getPermissionTypeLabel = (permissionType: string): string => {
    switch (permissionType) {
        case "upload_page":
            return t("uploadPage")
        case "question":
            return t("question")
        case "full_use_page":
            return t("fullUsePage")
        default:
            break
    }
    return t("unknown")
}

const getProgressColor = (usage: number, amount: number): string => {
    const percentage = usage / amount
    let progressColor = "#33cc33"
    if (percentage >= 1) {
        progressColor = "#ff3333"
    } else if (percentage >= 0.8) {
        progressColor = "#ffcc00"
    }
    return progressColor
}

const useFetchQuotas = () => {
    const { userToken, language, fetchWithChecks } = useUser()
    const [data, setData] = useState<QuotaApiResponse | null>(null)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        let countryCode = DSCountryCode.USA
        if (hostProvider === "aliyun") {
            countryCode = DSCountryCode.China
        }
        const url = getAPIUrl(`/api/business/quota?country=${countryCode}`)
        const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
        fetchWithChecks(url, true, false, { method: "GET", headers: headers })
            .then((response) => response.json())
            .then((data: QuotaApiResponse) => setData(data))
            .catch((error) => setError(error))
    }, [language, userToken, fetchWithChecks])

    return { data, error }
}

const QuotasCard: React.FC = () => {
    const { data, error } = useFetchQuotas()

    if (error) {
        return (
            <div>
                <MessageRow>Error: {error.message}</MessageRow>
            </div>
        )
    }

    if (!data) {
        return (
            <div>
                <MessageRow>Loading...</MessageRow>
            </div>
        )
    }

    return (
        <div style={{ paddingBottom: "5px" }}>
            {data.subscription_plan && (
                <QuotaLabel>
                    <QuotaProductName>{data.subscription_plan}</QuotaProductName>
                    {data.subscription_remaining > 0 && (
                        <QuotaPlanRemaining>
                            {sprintf(t("planRemaining"), data.subscription_remaining)}
                        </QuotaPlanRemaining>
                    )}
                </QuotaLabel>
            )}
            {data.subscription_quotas &&
                data.subscription_quotas.length > 0 &&
                data.subscription_quotas.map((quota, index) => {
                    const percentage = quota.usage > quota.amount ? 100 : (quota.usage / quota.amount) * 100
                    return (
                        <QuotaRow key={`subscription-${index}`}>
                            <QuotaData>
                                <QuotaType>{getPermissionTypeLabel(quota.type)}</QuotaType>
                                <QuotaUsage>{`${quota.usage}/${quota.amount}`}</QuotaUsage>
                            </QuotaData>
                            <ProgressBar>
                                <Progress
                                    style={{
                                        width: `${percentage}%`,
                                        backgroundColor: getProgressColor(quota.usage, quota.amount),
                                    }}
                                />
                            </ProgressBar>
                        </QuotaRow>
                    )
                })}
            {data.subscription_quotas && data.subscription_quotas.length > 0 && (
                <QuotaCycleReset>
                    {data.subscription_reset > 0
                        ? sprintf(t("cycleReset"), data.subscription_reset)
                        : t("cycleResetToday")}
                </QuotaCycleReset>
            )}
            {data.extra_quotas && data.extra_quotas.length > 0 && (
                <div>
                    <QuotaDivider></QuotaDivider>
                    <QuotaLabel>
                        <QuotaProductName>{t("extraPackages")}</QuotaProductName>
                    </QuotaLabel>
                    {data.extra_quotas.map((quota, index) => {
                        const percentage = quota.usage > quota.amount ? 100 : (quota.usage / quota.amount) * 100
                        return (
                            <QuotaRow key={`extra-${index}`}>
                                <QuotaData>
                                    <QuotaType>{getPermissionTypeLabel(quota.type)}</QuotaType>
                                    <QuotaUsage>{`${quota.usage}/${quota.amount}`}</QuotaUsage>
                                </QuotaData>
                                <ProgressBar>
                                    <Progress
                                        style={{
                                            width: `${percentage}%`,
                                            backgroundColor: getProgressColor(quota.usage, quota.amount),
                                        }}
                                    />
                                </ProgressBar>
                            </QuotaRow>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default QuotasCard
