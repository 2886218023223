import React, { useState, RefObject } from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import HomePageButton from "./button"
import { hostProvider } from "../../shared/config"
import PopupMenu, { MenuItem } from "../menu/popup-menu"
import { DSCssSize } from "../../shared/css-constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

interface HomePageHeaderProps {
    // 是否是主页
    isHome: boolean
    // 功能锚点
    featureRef?: RefObject<HTMLDivElement>
    // FAQ 锚点
    faqRef?: RefObject<HTMLDivElement>
}

const HomePageHeader: React.FC<HomePageHeaderProps> = ({ isHome, featureRef, faqRef }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [popupMenuVisible, setPopupMenuVisible] = useState<boolean>(false)

    const handleScrollToFeature = () => {
        if (isHome) {
            featureRef?.current?.scrollIntoView({ behavior: "smooth" })
        } else {
            navigate("/", { state: { scrollTo: "features" } })
        }
    }

    const handleScrollToFaq = () => {
        if (isHome) {
            faqRef?.current?.scrollIntoView({ behavior: "smooth" })
        } else {
            navigate("/", { state: { scrollTo: "faq" } })
        }
    }

    const handleToAboutPage = () => {
        navigate("/about")
    }

    const handlePricing = () => {
        navigate("/price")
    }

    const handleBackToHome = () => {
        navigate("/")
    }

    const handleSignIn = () => {
        navigate("/app")
    }

    const handleMenuClick = () => {
        setPopupMenuVisible(!popupMenuVisible)
    }

    let menuItems: MenuItem[] = []
    if (isHome) {
        menuItems.push(
            {
                type: "item",
                icon: "faRocket",
                text: t("features"),
                onClick: handleScrollToFeature,
            },
            {
                type: "item",
                icon: "faCircleQuestion",
                text: t("faq"),
                onClick: handleScrollToFaq,
            },
            {
                type: "item",
                icon: "faPeopleGroup",
                text: t("about"),
                onClick: handleToAboutPage,
            },
            {
                type: "item",
                icon: "faRightToBracket",
                text: t("login"),
                onClick: handleSignIn,
            }
        )
    } else {
        menuItems.push(
            {
                type: "item",
                icon: "faRocket",
                text: t("features"),
                onClick: handleScrollToFeature,
            },
            {
                type: "item",
                icon: "faCircleQuestion",
                text: t("faq"),
                onClick: handleScrollToFaq,
            },
            {
                type: "item",
                icon: "faReply",
                text: t("optionMenuBack"),
                onClick: handleBackToHome,
            },
            {
                type: "item",
                icon: "faRightToBracket",
                text: t("login"),
                onClick: handleSignIn,
            }
        )
    }

    if (hostProvider !== "aliyun") {
        const pricingItem: MenuItem = {
            type: "item",
            icon: "faCartShopping",
            text: t("pricing"),
            onClick: handlePricing,
        }
        menuItems.splice(1, 0, pricingItem)
    }

    return (
        <HeaderWrapper>
            <Navigation>
                <Logo onClick={handleBackToHome}>
                    <img src={"/logo-whole-transparent.png"} alt="DocuSpeed" />
                    {hostProvider === "aliyun" ? null : (
                        <a
                            href="https://www.producthunt.com/posts/docuspeed?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-docuspeed"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=413624&theme=dark"
                                alt="DocuSpeed - Revolutionize&#0032;reading&#0032;with&#0032;AI | Product Hunt"
                                style={{ width: "250px", height: "54px", marginTop: "8px" }}
                                width="250"
                                height="54"
                            />
                        </a>
                    )}
                </Logo>
                <DesktopNavLinks>
                    <NavLink onClick={handleScrollToFeature}>{t("features")}</NavLink>
                    {hostProvider === "aliyun" ? null : <NavLink onClick={handlePricing}>{t("pricing")}</NavLink>}
                    <NavLink onClick={handleScrollToFaq}>{t("faq")}</NavLink>
                    {isHome && <NavLink onClick={handleToAboutPage}>{t("about")}</NavLink>}
                    <HomePageButton onClick={handleSignIn}>{t("login")}</HomePageButton>
                </DesktopNavLinks>
                <MobileMenuButton onClick={handleMenuClick}>
                    <FontAwesomeIcon icon={faBars} />
                </MobileMenuButton>
                <PopupMenu
                    menuItems={menuItems}
                    direction="down"
                    position={{ horizontal: "right", vertical: "top" }}
                    backgroundColor="#004ca3"
                    visible={popupMenuVisible}
                    onClose={() => setPopupMenuVisible(false)}
                />
            </Navigation>
        </HeaderWrapper>
    )
}

export default HomePageHeader

const HeaderWrapper = styled.header`
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    background-color: transparent;
    padding: 16px 0;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        width: 100%;
        padding: 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        padding: 16px;
    }
`

const Navigation = styled.nav`
    width: ${DSCssSize.DesktopMaxWidth}px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        width: 100%;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
    }
`

const Logo = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;

    & img {
        height: 50px;
        margin-right: 20px;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        & a {
            display: none;
        }
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        & a {
            display: none;
        }
    }
`

const NavLink = styled.li`
    cursor: pointer;
`

const DesktopNavLinks = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;

    ${NavLink}:last-of-type {
        margin-right: 64px;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth + 1}px) and (max-width: ${DSCssSize.DesktopMaxWidth - 1}px) {
        margin-left: 0;
        padding-left: 0;

        ${NavLink}:last-of-type {
            margin-right: 32px;
        }
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        display: none;
    }
`

const MobileMenuButton = styled.button`
    display: none;
    width: 48px;
    height: 48px;
    background: transparent;
    border: none;

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        display: block;
    }
`
