import React, { useState, useEffect } from "react"
import { Navigate, Link } from "react-router-dom"
import styled from "styled-components"
import { useUser } from "../shared/user-context"
import i18n from "../shared/i18n"
import { useTranslation, Trans } from "react-i18next"
import { getAPIUrl } from "../shared/function"
import DSPageFooter from "../components/ds-page-footer"
import { hostProvider } from "../shared/config"
import { sprintf } from "sprintf-js"
import { DSCountryCode, DSLanguageCode, DSLanguageNames } from "../shared/constants"

enum StepCode {
    InputEmail = 1,
    VerifyEmail = 2,
    InputPassword = 3,
}

interface SignUpPageProps {}

const SignUpPage: React.FC<SignUpPageProps> = () => {
    const { userID, setUserID, setUserEmail, setUserToken, setLanguage } = useUser()
    const { t } = useTranslation()

    // 注册步骤
    const [step, setStep] = useState<StepCode>(StepCode.InputEmail)
    // 浏览器默认语言，需要采用标准定义
    const [browserLanguage, setBrowserLanuage] = useState(DSLanguageNames[DSLanguageCode.English])

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<boolean>(false)
    const [code, setCode] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)

    // 倒计时秒数
    const [countdown, setCountdown] = useState(0)
    // 重新发送验证码按钮状态
    const [isResending, setIsResending] = useState(false)

    // 调用后端 API 发送验证码
    const sendVerificationCode = async () => {
        let countryCode = DSCountryCode.USA
        if (hostProvider === "aliyun") {
            countryCode = DSCountryCode.China
        }

        const url = getAPIUrl(`/api/auth/mail-verifications`)
        const apiParams = new URLSearchParams()
        apiParams.append("email", email)
        apiParams.append("country", countryCode)
        apiParams.append("language", browserLanguage)

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: apiParams.toString(),
            })

            if (response.ok) {
                setStep(StepCode.VerifyEmail)
                setIsButtonDisabled(true)
                setErrorMessage(null)
            } else {
                const errorData = await response.json()
                setErrorMessage(errorData.error)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 调用后端 API 验证邮箱是否有效
    const verifyCode = async () => {
        const url = getAPIUrl(`/api/auth/mail-verifications/verify`)
        const apiParams = new URLSearchParams()
        apiParams.append("email", email)
        apiParams.append("code", code)

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: apiParams.toString(),
            })

            if (response.ok) {
                setStep(StepCode.InputPassword)
                setIsButtonDisabled(true)
                setErrorMessage(null)
            } else {
                const errorData = await response.json()
                setErrorMessage(errorData.error)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 调用后端 API 注册账号并自动登录
    const registerAndLogin = async () => {
        const url = getAPIUrl(`/api/auth/register`)
        const language = browserLanguage

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password, language, code }),
            })

            if (response.ok) {
                setErrorMessage(null)
                const url = getAPIUrl(`/api/auth/login`)

                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ email, password }),
                    })

                    if (response.ok) {
                        const data = await response.json()
                        setUserID(data.user.id)
                        setUserEmail(data.user.email)
                        setUserToken(data.token)
                        setLanguage(data.language)
                        setErrorMessage(null)
                    } else {
                        const errorData = await response.json()
                        setErrorMessage(errorData.error)
                    }
                } catch (error) {
                    console.error(error)
                }
            } else {
                const errorData = await response.json()
                setErrorMessage(errorData.error)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 重新发送验证码
    const handleResentVerificationCode = () => {
        // 点击时立即禁用按钮
        setIsResending(true)

        // 调用发送验证码的接口
        sendVerificationCode()
            .then(() => {
                // 开始倒计时
                setCountdown(60)
            })
            .catch((error) => {
                // 处理错误，如需要
                console.error(error)
                setIsResending(false)
            })
    }

    // 倒计时逻辑
    useEffect(() => {
        if (countdown > 0) {
            const timerId = setTimeout(() => {
                setCountdown(countdown - 1)
            }, 1000)

            return () => clearTimeout(timerId)
        } else {
            setIsResending(false)
        }
    }, [countdown])

    // 根据浏览器语言选项设置语言参数
    useEffect(() => {
        const language = navigator.language
        if (language.startsWith("zh")) {
            setBrowserLanuage(DSLanguageNames[DSLanguageCode.SimplifiedChinese])
        }
    }, [])

    useEffect(() => {
        switch (browserLanguage) {
            case DSLanguageNames[DSLanguageCode.SimplifiedChinese]:
                i18n.changeLanguage("cn")
                break
            default:
                i18n.changeLanguage("en")
                break
        }
    }, [browserLanguage])

    // 处理提交按钮事件
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        if (step === StepCode.InputEmail) {
            // 第一步，根据用户输入的邮件地址发送确认码邮件。
            sendVerificationCode()
        } else if (step === StepCode.VerifyEmail) {
            // 第二步，拿到用户输入的确认码，验证邮箱的有效性。
            verifyCode()
        } else {
            // 第三步，用户需要输入两遍相同的符合要求的密码，则可创建帐户。
            if (password !== confirmPassword) {
                setErrorMessage(t("passwordMismatch"))
            } else if (password.length < 8) {
                setErrorMessage(t("passwordTooShort"))
            } else {
                registerAndLogin()
            }
        }
    }

    if (step === StepCode.InputEmail) {
        return (
            <SignUpPageConainer>
                <SignUpPageWrapper>
                    <SignUpPageCard>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <SignUpPageInput
                                className={`${emailError ? "input-error" : ""}`}
                                type="email"
                                placeholder={t("email", "Email")!}
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmailError(false)
                                    setErrorMessage(null)
                                    setEmail(e.target.value)
                                    setIsButtonDisabled(e.target.value === "")
                                }}
                            />
                            <SignUpPageMessageWrapper $height={50}>
                                <SignUpPageNoteMessage>
                                    <Trans
                                        i18nKey="acceptMessage"
                                        components={{
                                            /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                                            termsLink: <a href="/legal/terms.html" />,
                                            /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                                            privacyLink: <a href="/legal/privacy.html" />,
                                        }}
                                    />
                                </SignUpPageNoteMessage>
                                {errorMessage && <SignUpPageErrorMessage>{errorMessage}</SignUpPageErrorMessage>}
                            </SignUpPageMessageWrapper>
                            {isButtonDisabled ? (
                                <SignUpPageButtonDisabled type="submit" disabled>
                                    {t("begin")}
                                </SignUpPageButtonDisabled>
                            ) : (
                                <SignUpPageButton type="submit">{t("begin")}</SignUpPageButton>
                            )}
                        </form>
                        <SignUpPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </SignUpPageOtherAction>
                    </SignUpPageCard>
                </SignUpPageWrapper>
                <DSPageFooter showVersion={true} />
            </SignUpPageConainer>
        )
    } else if (step === StepCode.VerifyEmail) {
        return (
            <SignUpPageConainer>
                <SignUpPageWrapper>
                    <SignUpPageCard $language={browserLanguage}>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <p>{sprintf(t("verificationCodePrompt"), email)}</p>
                            <SignUpPageInput
                                type="text"
                                placeholder={t("verificationCode", "Verification Code")!}
                                value={code}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setErrorMessage(null)
                                    setCode(e.target.value)
                                    setIsButtonDisabled(e.target.value === "")
                                }}
                            />
                            <SignUpPageMessageWrapper $height={50}>
                                <SignUpPageResendCodeButton
                                    $isSending={isResending}
                                    onClick={!isResending ? handleResentVerificationCode : undefined}
                                >
                                    {isResending
                                        ? sprintf(t("countingDownResend"), countdown)
                                        : t("resendVerificationCode")}
                                </SignUpPageResendCodeButton>
                                {errorMessage && <SignUpPageErrorMessage>{errorMessage}</SignUpPageErrorMessage>}
                            </SignUpPageMessageWrapper>
                            {isButtonDisabled ? (
                                <SignUpPageButtonDisabled type="submit" disabled>
                                    {t("continue")}
                                </SignUpPageButtonDisabled>
                            ) : (
                                <SignUpPageButton type="submit">{t("continue")}</SignUpPageButton>
                            )}
                        </form>
                        <SignUpPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </SignUpPageOtherAction>
                    </SignUpPageCard>
                </SignUpPageWrapper>
                <DSPageFooter showVersion={true} />
            </SignUpPageConainer>
        )
    } else {
        return !userID ? (
            <SignUpPageConainer>
                <SignUpPageWrapper>
                    <SignUpPageCard>
                        <img src="/logo-landscape.png" alt="DocuSpeed" />
                        <form onSubmit={handleSubmit}>
                            <SignUpPageInput
                                className={`${passwordError ? "input-error" : ""}`}
                                type="password"
                                placeholder={t("signupPassword", "Enter password")!}
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPasswordError(false)
                                    setErrorMessage(null)
                                    setPassword(e.target.value)
                                    setIsButtonDisabled(e.target.value === "" || confirmPassword === "")
                                }}
                            />
                            <SignUpPageInput
                                className={`${passwordError ? "input-error" : ""}`}
                                type="password"
                                placeholder={t("signupConfirmPassword", "Confirm your password")!}
                                value={confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPasswordError(false)
                                    setErrorMessage(null)
                                    setConfirmPassword(e.target.value)
                                    setIsButtonDisabled(e.target.value === "" || password === "")
                                }}
                            />
                            <SignUpPageMessageWrapper>
                                {errorMessage && <SignUpPageErrorMessage>{errorMessage}</SignUpPageErrorMessage>}
                            </SignUpPageMessageWrapper>
                            {isButtonDisabled ? (
                                <SignUpPageButtonDisabled type="submit" disabled>
                                    {t("create")}
                                </SignUpPageButtonDisabled>
                            ) : (
                                <SignUpPageButton type="submit">{t("create")}</SignUpPageButton>
                            )}
                        </form>
                        <SignUpPageOtherAction>
                            <span>{t("loginLinkPrompt")}</span>&nbsp;
                            <Link to="/login">{t("loginLink")}</Link>
                        </SignUpPageOtherAction>
                    </SignUpPageCard>
                </SignUpPageWrapper>
                <DSPageFooter showVersion={true} />
            </SignUpPageConainer>
        ) : (
            <Navigate to="/app" replace={true} />
        )
    }
}

export default SignUpPage

const SignUpPageConainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const SignUpPageWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 500px;
    max-width: 100%;
`

const SignUpPageMessageWrapper = styled.div<{ $height?: number }>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: ${(props) => (props.$height ? `${props.$height}px` : "30px")};
`

const SignUpPageResendCodeButton = styled.div<{ $isSending: boolean }>`
    font-size: 14px;
    color: ${(props) => (props.$isSending ? "gray" : "blue")};
    cursor: ${(props) => (props.$isSending ? "not-allowed" : "pointer")};
`

const SignUpPageErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SignUpPageNoteMessage = styled.div`
    padding: 0 1px;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & a {
        text-decoration: none;
    }
`

const SignUpPageInput = styled.input`
    width: 100%;
    height: 44px;
    margin-bottom: 20px;
    border: 1px solid gray;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 6px 8px;

    &:focus {
        border-color: blue;
    }

    & .input-error {
        border-color: red;
    }

    &:last-of-type {
        margin-bottom: 5px;
    }
`

const SignUpPageCard = styled.div<{ $language?: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & img {
        max-width: 100%;
        height: auto;
        margin-bottom: 50px;
    }

    & form {
        width: 70%;
    }

    & form p {
        font-family: ${(props) =>
            props.$language === DSLanguageNames[DSLanguageCode.English] ? "'Lato', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', sans-serif" : "inherit"};
    }

    @media (max-height: 500px) {
        & img {
            margin-bottom: 10px;
        }
    }
`

const SignUpPageButton = styled.button`
    width: 100%;
    height: 48px;
    color: white;
    background-color: #0052cc;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #0052cc;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
`

const SignUpPageButtonDisabled = styled(SignUpPageButton)`
    background-color: lightslategray;
    border-color: lightslategray;
    cursor: not-allowed;
`

const SignUpPageOtherAction = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.6;
    margin: 10px 0;

    & a:first-child {
        margin-right: 15px;
    }

    & a:visited {
        color: blue;
    }
`
