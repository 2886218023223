import React, { useCallback, useState, useEffect } from "react"
import { Navigate } from "react-router-dom"
import styled from "styled-components"
import { DSUserFile } from "../shared/file"
import Navigation from "../components/navigation"
import PdfPreview from "../components/pdf-preview"
import ChatArea from "../components/message/chat-area"
import { useUser } from "../shared/user-context"
import i18n, { i18nPromise } from "../shared/i18n"
import { useTranslation } from "react-i18next"
import { getAPIUrl } from "../shared/function"
import { Allotment } from "allotment"
import "allotment/dist/style.css"
import { DSRelatedPageContext } from "../shared/message"
import { DSFileCategory } from "../shared/file"
import SettingsModal from "../components/modals/settings-modal"
import { DSLanguageNames, DSLanguageCode } from "../shared/constants"

interface Segment {
    id: number
    text: string
}

const AppPage: React.FC = () => {
    const { userID, userToken, language, setLanguage, fetchWithChecks } = useUser()
    const { t } = useTranslation()

    const [selectedFile, setSelectedFile] = useState<DSUserFile | null>(null)
    const [pageNum, setPageNum] = useState<number>(-1)
    const [hightlightText, setHighlightText] = useState<string[]>([])
    const [pdfPaneWidth, setPdfPaneWidth] = useState<number>((window.innerWidth - 320) * 0.5)

    // 是否显示设置对话框
    const [modalSettingsOpen, setModalSettingsOpen] = useState<boolean>(false)

    const onFileUploaded = (file: File) => {
        // console.log("File uploaded:", file)
    }

    const onFileSelected = useCallback((file: DSUserFile | null) => {
        // console.log("File selected: ", file)
        setSelectedFile(file)
        if (file) {
            setPageNum(file.last_page_num)
        }
    }, [])

    const openSettingsModal = () => {
        setModalSettingsOpen(true)
    }

    const onPageNumChanged = useCallback(
        async (pageContext: DSRelatedPageContext) => {
            setPageNum(pageContext.pageNum)
            if (selectedFile && pageContext.segmentIds.length > 0) {
                try {
                    const url = getAPIUrl(
                        `/api/files/${selectedFile.id}/pages/${
                            pageContext.pageNum
                        }/segments?ids=${pageContext.segmentIds.join(",")}`
                    )
                    const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                    const response = await fetchWithChecks(url, true, false, {
                        method: "GET",
                        headers: headers,
                    })
                    const data = await response.json()
                    if (data && Array.isArray(data.segments)) {
                        setHighlightText(data.segments.map((segment: Segment) => segment.text))
                    }
                } catch (error) {
                    console.error("Failed to fetch segment text:", error)
                }
            } else {
                setHighlightText([])
            }
        },
        [selectedFile, userToken, fetchWithChecks]
    )

    const onLanguageChanged = async (newLanguage: string) => {
        const url = getAPIUrl(`/api/settings/`)
        const headers: HeadersInit = userToken ? { Authorization: userToken, "Content-Type": "application/json" } : {}
        try {
            const response = await fetchWithChecks(url, true, false, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ language: newLanguage }),
            })

            if (response.ok) {
                setLanguage(newLanguage)
                localStorage.setItem("language", newLanguage)

                // 确保 i18n 对象已经准备好
                await i18nPromise
                // 通过 i18n.changeLanguage 更改语言
                switch (newLanguage) {
                    case DSLanguageNames[DSLanguageCode.SimplifiedChinese]:
                        i18n.changeLanguage("cn")
                        break
                    default:
                        i18n.changeLanguage("en")
                        break
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        switch (language) {
            case DSLanguageNames[DSLanguageCode.SimplifiedChinese]:
                i18n.changeLanguage("cn")
                break
            default:
                i18n.changeLanguage("en")
                break
        }
    }, [language])

    useEffect(() => {
        // 定义心跳检测函数
        const heartbeat = async () => {
            try {
                const url = getAPIUrl(`/api/auth/heartbeat`)
                const headers: HeadersInit = userToken ? { Authorization: userToken } : {}
                await fetchWithChecks(url, true, false, { method: "GET", headers: headers })
            } catch (error) {
                // console.log("Heartbeat failed.")
            }
        }

        const intervalId = setInterval(heartbeat, 60 * 1000) // 每分钟检查一次

        // 清理定时器
        return () => {
            clearInterval(intervalId)
        }
    }, [userToken, fetchWithChecks])

    return userID ? (
        <AppPageWrapper>
            <AppPageContainer>
                <Allotment proportionalLayout={false}>
                    <Allotment.Pane minSize={200} preferredSize={320}>
                        <Navigation
                            onFileUploaded={onFileUploaded}
                            onFileSelected={onFileSelected}
                            onSettingsMenuClicked={openSettingsModal}
                        />
                    </Allotment.Pane>
                    <Allotment.Pane>
                        <Allotment onChange={(sizes) => setPdfPaneWidth(sizes[0])}>
                            <Allotment.Pane minSize={400} preferredSize={"50%"}>
                                <PdfPreview
                                    fileID={selectedFile ? selectedFile.id : -1}
                                    pageNum={pageNum}
                                    containerWidth={pdfPaneWidth}
                                    containerHeight={window.innerHeight}
                                    highlightText={hightlightText}
                                />
                            </Allotment.Pane>
                            <Allotment.Pane minSize={400}>
                                <ChatArea
                                    fileID={selectedFile ? selectedFile.id : -1}
                                    fileCategory={selectedFile ? selectedFile.category : DSFileCategory.Other}
                                    onRelatedPageClicked={onPageNumChanged}
                                />
                            </Allotment.Pane>
                        </Allotment>
                    </Allotment.Pane>
                </Allotment>
                <SettingsModal
                    isOpen={modalSettingsOpen}
                    title={t("settings")}
                    language={language}
                    onRequestClose={() => setModalSettingsOpen(false)}
                    onLanguageChanged={onLanguageChanged}
                />
            </AppPageContainer>
        </AppPageWrapper>
    ) : (
        <Navigate to="/login" replace={true} />
    )
}

export default AppPage

const AppPageWrapper = styled.div`
    height: 100%;
    text-align: center;
`

const AppPageContainer = styled.div`
    display: flex;
    height: 100%;
    box-sizing: border-box;
`