import React from "react"
import styled from "styled-components"
import { DSCssSize } from "../../shared/css-constants"

interface HomePageButtonProps {
    onClick?: () => void
    children: React.ReactNode
    disabled?: boolean
}

const HomePageButton: React.FC<HomePageButtonProps> = ({ onClick, children, disabled }) => (
    <StyledButton onClick={onClick} disabled={disabled}>
        {children}
    </StyledButton>
)

export default HomePageButton

const StyledButton = styled.button<HomePageButtonProps>`
    background-color: #4a90e2;
    min-width: 120px;
    color: #fff;
    padding: 16px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;

    &:disabled {
        background-color: grey;
        cursor: not-allowed;
    }

    &:hover {
        background-color: #357ab2;
    }

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        padding: 12px 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        padding: 8px 16px;
    }
`
