import React from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import HomePageButton from "./button"
import { DSCssFontSize, DSCssSize } from "../../shared/css-constants"

const HomePageBanner: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const handleSignIn = () => {
        navigate("/app")
    }

    return (
        <BannerWrapper>
            <Banner>
                <BannerImage></BannerImage>
                <BannerText>
                    <MainTitle>{t("bannerMainTitle")}</MainTitle>
                    <Description>{t("bannerMainDescription")}</Description>
                    <HomePageButton onClick={handleSignIn}>{t("freeTrial")}</HomePageButton>
                </BannerText>
            </Banner>
        </BannerWrapper>
    )
}

export default HomePageBanner

const BannerWrapper = styled.div`
    max-width: ${DSCssSize.DesktopMaxWidth}px;
    margin: 32px auto;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        margin: 16px auto;
        padding: 0 16px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        margin: 0 auto;
        padding: 16px;
    }
`

const Banner = styled.div`
    width: ${DSCssSize.DesktopMaxWidth}px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    box-sizing: border-box;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 100%;
        padding: 0 16px;
        gap: 16px;        
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        flex-direction: column;
        padding: 0 16px;
        gap: 16px;
    }    
`

const BannerImage = styled.div`
    background-image: url("/site/banner.webp");
    background-size: cover;
    width: 50%;
    height: 450px;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        width: 50%;
        height: 250px;
    }

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        height: 250px;
    }
`

const BannerText = styled.div`
    width: 50%;
    line-height: 1.6;
    padding: 32px 16px;
    box-sizing: border-box;

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        width: 100%;
        font-size: 1.8rem;
        padding: 16px 8px;
    }
`

const MainTitle = styled.div`
    font-size: ${DSCssFontSize.H1};
    font-weight: 700;
    margin: 0 0 16px;

    @media (min-width: ${DSCssSize.MobileMaxWidth+1}px) and (max-width: ${DSCssSize.DesktopMaxWidth-1}px) {
        font-size: ${DSCssFontSize.H2};
    }    

    @media (max-width: ${DSCssSize.MobileMaxWidth}px) {
        font-size: ${DSCssFontSize.H3};
    }
`

const Description = styled.div`
    font-family: "Lato", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", sans-serif;
    font-size: ${DSCssFontSize.MainBody};
    margin: 0 0 16px;
`
