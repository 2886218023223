export enum PermissionType {
    UploadPage = "upload_page",
    Question = "question",
    FullUsePage = "full_use_page",
}

// 商品数据表
export interface DSPermission {
    type: PermissionType
    limit_amount: number
    valid_duration: number
    is_recurring: boolean
    is_permanent: boolean
}

export interface DSProduct {
    id: number
    name: string
    category: string
    original_price: number
    discount_price: number
    currency: string
    permissions: DSPermission[]
}

export interface QuotaDetailResponse {
    type: PermissionType
    amount: number
    usage: number
}

export interface QuotaApiResponse {
    subscription_plan: string
    subscription_remaining: number
    subscription_reset: number
    subscription_quotas: QuotaDetailResponse[]
    extra_quotas: QuotaDetailResponse[]
}
