// 文档分类，参见后端服务相关部分的定义
export enum DSFileCategory {
    Paper = "paper",
    TextBook = "textbook",
    Manual = "manual",
    Report = "report",
    Legal = "legal",
    Financial = "financial",
    NewsMagazine = "news_magazine",
    BusinessPlan = "business_plan",
    Slides = "slides",
    Book = "book",
    Resume = "resume",
    Other = "other",
}

// 文档分类对应文本资源的映射表
export const FileCategoryRes = {
    [DSFileCategory.Paper]: "categoryPaper",
    [DSFileCategory.TextBook]: "categoryTextBook",
    [DSFileCategory.Manual]: "categoryManual",
    [DSFileCategory.Report]: "categoryReport",
    [DSFileCategory.Legal]: "categoryLegal",
    [DSFileCategory.Financial]: "categoryFinancial",
    [DSFileCategory.NewsMagazine]: "categoryNewsMagazine",
    [DSFileCategory.BusinessPlan]: "categoryBusinessPlan",
    [DSFileCategory.Slides]: "categorySlides",
    [DSFileCategory.Book]: "categoryBook",
    [DSFileCategory.Resume]: "categoryResume",
    [DSFileCategory.Other]: "categoryOther",
}

// 后端返回的用户文件数据
export interface DSUserFile {
    id: number
    file_name: string
    category: DSFileCategory
    max_page_num: number
    last_page_num: number
}

// 后端返回的用户文件目录项数据
export interface DSUserFileOutline {
    id: number
    title: string
    start_page_num: number
    page_count: number
}