import React, { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import i18n from "../shared/i18n"
import HomePageHeader from "../components/home/header"
import HomePageBanner from "../components/home/banner"
import HomePageContentSection from "../components/home/content-section"
import HomePageFooter from "../components/home/footer"

const HomePage: React.FC = () => {
    const location = useLocation()
    const featureRef = useRef<HTMLDivElement>(null)
    const faqRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (location.state?.scrollTo === "features" && featureRef.current) {
            featureRef.current.scrollIntoView({ behavior: "smooth" })
        } else if (location.state?.scrollTo === "faq" && faqRef.current) {
            faqRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [location])

    // 根据浏览器语言设置进行调整。
    useEffect(() => {
        let initialLanguage = "en" // 默认语言
        const browserLanguage = navigator.language
        if (browserLanguage.startsWith("zh")) {
            initialLanguage = "cn"
        }
        // 然后你可以把 initialLanguage 作为初始语言
        i18n.changeLanguage(initialLanguage)
    }, [])

    return (
        <HomePageWrapper>
            <HomePageHeader isHome={true} featureRef={featureRef} faqRef={faqRef} />
            <HomePageBanner />
            <HomePageContentSection featureRef={featureRef} faqRef={faqRef} />
            <HomePageFooter />
        </HomePageWrapper>
    )
}

export default HomePage

const HomePageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eff6ff;
    color: #004ca3;
`
