import React from "react"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism"
import { CodeComponent } from "react-markdown/lib/ast-to-react"

const CodeBlock: CodeComponent = ({
    node,
    inline,
    className,
    children,
    ...props
}) => {
    const match = /language-(\w+)/.exec(className || "")
    return !inline && match ? (
        <div className="code-block">
            <div className="code-block-title">{match[1]}</div>
            <div className="code-block-body">
                <SyntaxHighlighter
                    {...props}
                    children={String(children).replace(/\n$/, "")}
                    style={vscDarkPlus}
                    customStyle={{
                        fontSize: "1em",
                        margin: "0",
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        overflowX: "auto",
                    }}
                    language={match[1]}
                    PreTag="div"
                    wrapLongLines={true}
                    wrapLines={true}
                />
            </div>
        </div>
    ) : (
        <span className="inline-code">
            <code {...props} className={className}>
                {children}
            </code>
        </span>
    )
}

export default CodeBlock
